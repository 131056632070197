import React, { useState } from 'react'
import {
  Button,
  ButtonGroup,
  DateTime,
  Inline,
  Label,
  Loading,
  Modal,
} from '@therms/atalaya'
import {
  GetEscOccurencesByTimeRangeResponseBody,
  ResolveEsOccurrence,
} from '@src/services/api-client'
import { EscalationName } from '@src/modules/Escalations/shared/EscalationName'
import { UserName } from '@src/modules/Users/shared/UserName'
import { TaskName } from '@src/modules/Tasks/shared/TaskName'
import { FiCheck, FiCheckCircle } from 'react-icons/fi'
import { Alerts } from '@src/components/Alerts'
import { Comments } from '@src/modules/Comments/shared/Comments'
import { EscalationOccurrenceForwardToContactModal } from '@src/modules/Escalations/router/screens/Index/components/EscalationOccurrences/EscalationOccurrenceForwardToContactModal'

interface EscalationOccurrenceModalProps {
  closeHandler: () => void
  escOccurrence: GetEscOccurencesByTimeRangeResponseBody['escOccurrences'][number]
}

function EscalationOccurrenceModal({
  closeHandler,
  escOccurrence,
}: EscalationOccurrenceModalProps) {
  const [loading, setLoading] = useState(false)
  const [showForwardModal, setShowForwardModal] = useState(false)

  const handleResolve = async () => {
    setLoading(true)

    try {
      await ResolveEsOccurrence({
        siteId: escOccurrence.siteId,
        // escOccurrenceId: escOccurrence.id,
        escOccuranceId: escOccurrence.id,
        adminNote: '',
      })
      closeHandler()
      Alerts.success('Escalation issue resolved')
    } catch (err) {
      Alerts.error('Error resolving this issue, please try again', err.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      closeHandler={closeHandler}
      escPressClose
      header={
        escOccurrence.resolvedDate ? (
          <Inline alignX="between" width="full">
            <span>Escalation Issue</span>

            <div className="font-light text-sm">
              <span className="inline-block">
                <FiCheckCircle color="green" size={14} />
              </span>
              Resolved by <UserName id={escOccurrence.resolvingUserId} />
              (<DateTime timestamp={escOccurrence.resolvedDate} />)
            </div>
          </Inline>
        ) : (
          <Inline alignX="between" width="full">
            <span>Escalation Issue</span>

            <ButtonGroup size="sm">
              <Button
                onClick={() => setShowForwardModal(true)}
                variant="main"
                subtle
              >
                Email Issue to Contact
              </Button>
              <Button onClick={handleResolve} variant="positive">
                <FiCheck color="#fff" size={14} />
                Resolve
              </Button>
            </ButtonGroup>
          </Inline>
        )
      }
    >
      {loading && <Loading overlay />}
      <div>
        <Label>Date</Label> <DateTime timestamp={escOccurrence.timestamp} />
      </div>

      <div>
        <div>
          <Label>Escalation</Label>{' '}
          <EscalationName id={escOccurrence.escalationId} />
        </div>

        <div>
          <Label>Escalated By</Label>{' '}
          <span className="font-light">
            <UserName id={escOccurrence.escalatingUserId} />
          </span>
        </div>
      </div>

      <div>
        <div>
          <Label>Trigger</Label>{' '}
          <TaskName
            id={escOccurrence.triggeredBy.triggerId}
            render={(task) => {
              const taskGroup = task.taskGroups[0]

              return (
                <span>
                  Task{' '}
                  {taskGroup?.name && (
                    <span
                      style={
                        taskGroup?.uiData?.color
                          ? { color: taskGroup.uiData.color }
                          : {}
                      }
                    >
                      {taskGroup?.name}{' '}
                    </span>
                  )}
                  <span>{task.title}</span>
                </span>
              )
            }}
          />
        </div>
      </div>

      <div>
        <Label>User Note</Label>
        <Comments
          commentIds={escOccurrence.commentIds}
          siteId={escOccurrence.siteId}
        />
      </div>

      {showForwardModal && (
        <EscalationOccurrenceForwardToContactModal
          escOccurrence={escOccurrence}
          onClose={() => setShowForwardModal(false)}
        />
      )}
    </Modal>
  )
}

EscalationOccurrenceModal.defaultProps = {}

export { EscalationOccurrenceModal }
