import React from 'react'
import PropTypes from 'prop-types'
import { TableGroup } from '@therms/atalaya'

function EscalationsTableRow({ rowData, rowKey }) {
  return (
    <TableGroup.Row
      canSelect
      className="hover:bg-surface hover:cursor-pointer"
      rowKey={rowKey}
    >
      <TableGroup.Cell>
        {rowData.name}
      </TableGroup.Cell>

      <TableGroup.Cell>{rowData.details}</TableGroup.Cell>
    </TableGroup.Row>
  )
}

EscalationsTableRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  rowKey: PropTypes.any.isRequired,
}

export { EscalationsTableRow }
