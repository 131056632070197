import { useEffect, useState } from 'react'
import { OpenWeatherApiData } from '@src/modules/Weather/types/open-weather-api'
import { usePrevious } from '@src/hooks/usePrevious'

// openweatherapi.com - this is someone's api key from codesandbox 😅 https://codesandbox.io/s/o5k3n?file=/src/index.js
const API_KEY = '7ad07aac9b0943040a4abdd2c23dfc4e'

type Props = { lat: number; lng: number } | false | undefined | null | 0

export function useWeather(props: Props): {
  data: OpenWeatherApiData | undefined
  loading: boolean
  errorMessage?: string
} {
  const [loading, setLoading] = useState<boolean>(
    !!(props && props.lat && props.lng),
  )
  const [weatherData, setWeatherData] = useState<
    OpenWeatherApiData | undefined
  >()

  const prevProps = usePrevious(props)

  const fetchWeather = () => {
    if (props) {
      fetch(
        `https://api.openweathermap.org/data/2.5/onecall?lat=${props.lat}&lon=${props.lng}&appid=${API_KEY}&units=imperial`,
      )
        .then((res) => res.json())
        .then((data) => {
          setWeatherData(data)
        })
        .finally(() => setLoading(false))
    }
  }

  useEffect(() => {
    if (
      props &&
      props.lat &&
      props.lng &&
      (!prevProps ||
        (prevProps.lat !== props.lat && prevProps.lng !== props.lng))
    ) {
      fetchWeather()
    }
  }, [props])

  useEffect(() => {
    const i = setInterval(() => {
      fetchWeather()
    }, 60000 * 5)

    return () => clearInterval(i)
  }, [])

  return { data: weatherData, loading }
}
