import React from 'react'
import { Select, SelectProps } from '@therms/atalaya'
import { Field, FieldAttributes, useFormikContext } from 'formik'

function FormikSelect(props: FieldAttributes<SelectProps>) {
  const form = useFormikContext()

  return (
    <Field name={props.name}>
      {({ field, meta }) => (
          <Select
            {...props}
            error={(meta.touched || !!form.submitCount) && meta.error}
            valid={(meta.touched || !!form.submitCount) && meta.value && !meta.error}
            onChangeValue={(value) =>
              props.name && form.setFieldValue(props.name, value)
            }
            {...field}
            // this component relies on `onChangeValue`
            onChange={null}
          />
        )}
    </Field>
  )
}

export { FormikSelect }