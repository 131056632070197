import {useEffect, useRef, useState} from 'react'
import {useDeactivationState} from "@src/modules/Auth/shared/DeactivationWrapper"

// This exists because the useApiWithSiteId hook is being used sometimes outside of the auth wrapper.  This means that auth hook info
// used in the normal useAuth hook causes errors.  Ultimately this should be refactored.
export function useApi<T = any>(
  apiFetcher,
  // 'false' will not trigger a call
  opts?: false | Parameters<typeof apiFetcher>[0],
): {
  call: () => void
  data?: T
  error?: undefined | string
  isCalling: boolean
  reset: () => void
} {
  const local = useRef({ call: () => undefined, prevOpts: null })
  const {setDeactivatedMsg} = useDeactivationState()
  const [data, setData] = useState<ReturnType<typeof apiFetcher>>(undefined)
  const [error, setError] = useState<undefined | string>(undefined)
  const [isCalling, setIsCalling] = useState(false)
  const call = () => {
    setIsCalling(true)
    apiFetcher(opts)
      .then((res) => {
        setData(res.data)
      })
      .catch((err) => {
        console.warn('useApi() error', err)
        if (err?.response?.status === 402) setDeactivatedMsg(err?.response?.data?.message || "Account Deactivated")
        setError(err?.response?.data?.message || 'API error')
      })
      .finally(() => setIsCalling(false))
  }

  local.current.call = call

  useEffect(() => {
    if (opts !== false) {
      const { prevOpts } = local.current

      if (typeof opts !== 'object' && prevOpts !== opts) {
        local.current.prevOpts = opts
        local.current.call()
      } else if (
        typeof opts === 'object' &&
        JSON.stringify(prevOpts) !== JSON.stringify(opts)
      ) {
        local.current.prevOpts = opts
        local.current.call()
      }
    }
  }, [opts])

  const reset = () => {
    setData(undefined)
    setError(undefined)
    setIsCalling(false)
  }

  return {
    call,
    data,
    error,
    isCalling,
    reset,
  }
}
