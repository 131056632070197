import React from 'react'
import PropTypes from 'prop-types'
import { ColorSquare, TableGroup } from '@therms/atalaya'

function GroupsTableRow({ rowData, rowKey }) {
  const taskGroupColor = rowData.uiData?.color

  return (
    <TableGroup.Row
      canSelect
      className="hover:bg-surface hover:cursor-pointer"
      rowKey={rowKey}
    >
      <TableGroup.Cell>
        {taskGroupColor && <ColorSquare color={taskGroupColor} marginRight />}
        {rowData.name}
      </TableGroup.Cell>

      <TableGroup.Cell>{rowData.details}</TableGroup.Cell>
    </TableGroup.Row>
  )
}

GroupsTableRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  rowKey: PropTypes.any.isRequired,
}

export { GroupsTableRow }
