import React, {  } from 'react'
import { useFormikContext } from 'formik'
import { Task } from '../../../../../../../../services/api-client'
import { FormikSelectPositions } from "../../../../../../../Positions/shared/Fields/FormikSelectPositions"




function CompletedActionFormField() {
  const form = useFormikContext<Task>()


  return (
    <div>
      <FormikSelectPositions
        label="Positions to Notify"
        multi
        name="onCompleteEmailPositionIds"
      />
    </div>
  )
}

CompletedActionFormField.defaultProps = {}

export { CompletedActionFormField }
