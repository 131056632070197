import { useEffect, useState } from 'react'
import { debounce } from 'lodash-es'

export function useWindowSize(
  opts: { debounceMs?: number } = { debounceMs: 250 },
) {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }, opts.debounceMs || 50)

    window?.addEventListener('resize', handleResize)

    handleResize()

    return () => window?.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}
