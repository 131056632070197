import React from 'react'
import PropTypes from 'prop-types'
import { CopyText, Image, Inline, TableGroup } from '@therms/atalaya'
import { generatePath, useHistory } from 'react-router-dom'
import { SITES_ROUTES } from '@src/modules/Sites/constants'
import { useAuth } from '@src/modules/Auth/shared/state'

function TableSiteRow({
  rowData,
  rowKey,
}) {
  const { setCurrentSite } = useAuth()
  const { push } = useHistory()

  return (
    <TableGroup.Row
      className="hover:bg-surface hover:cursor-pointer"
      rowKey={rowKey}
      onClick={() => {
        setCurrentSite(rowData)
        push(generatePath(SITES_ROUTES.VIEW_SITE, { siteId: rowData.id }))
      }}
    >
      <TableGroup.Cell>
        <Inline alignY="center" space="xs">
          {rowData.primaryImageUrl && (
            <div>
              <Image src={rowData.primaryImageUrl} thumbnail />
            </div>
          )}

          <div>{rowData.name}</div>
        </Inline>
      </TableGroup.Cell>

      <TableGroup.Cell ellipse>
        <div className="whitespace-pre">
          <CopyText value={rowData.address}>{rowData.address}</CopyText>
        </div>
      </TableGroup.Cell>

      <TableGroup.Cell ellipse>{rowData.timezone}</TableGroup.Cell>
    </TableGroup.Row>
  )
}

TableSiteRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  rowKey: PropTypes.any.isRequired,
}

export { TableSiteRow }
