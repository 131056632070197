import React from 'react'
import {
  ActionModal,
  ActionModalProps,
  Button,
  ButtonProps,
} from '@therms/atalaya'

function ButtonWithActionModal({
  actionText,
  body,
  children,
  className,
  onAction,
  outline,
  size,
  subtle,
  title,
  variant,
}: Pick<
  ActionModalProps,
  'actionText' | 'body' | 'onAction' | 'title' | 'variant'
> &
  Pick<ButtonProps, 'outline' | 'size' | 'subtle'> & {
    className?: string
    children?: any
  }) {
  const [show, setShow] = React.useState(false)

  return (
    <div>
      <Button
        className={className}
        onClick={() => setShow(true)}
        outline={outline}
        size={size}
        subtle={subtle}
        variant={variant}
      >
        {actionText || children}
      </Button>

      {show && (
        <ActionModal
          actionText={actionText}
          body={body}
          escPressClose
          onCancel={() => setShow(false)}
          onAction={() => {
            onAction()
            setShow(false)
          }}
          title={title}
          variant={variant}
        />
      )}
    </div>
  )
}

ButtonWithActionModal.propTypes = {}

export { ButtonWithActionModal }
