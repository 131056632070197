import React from 'react'
import { Button, ButtonProps } from '@therms/atalaya'
import { useToggle } from 'ahooks'

type ButtonWithModalProps = ButtonProps & {
  modalElement:
    | React.ReactElement
    | (({ closeHandler }: { closeHandler: () => void }) => React.ReactNode)
  /** Optional callback when clicked */
  onClick?: () => void
  /** Optional callback when modal closed */
  onClose?: () => void
}

function ButtonWithModal({
  children,
  modalElement,
  onClick,
  onClose,
  ...buttonProps
}: ButtonWithModalProps) {
  const [show, { toggle: toggleShow }] = useToggle()

  const closeHandler = () => {
    onClose?.()
    toggleShow()
  }

  return (
    <div>
      <Button
        {...buttonProps}
        onClick={() => {
          if (onClick) onClick()

          toggleShow()
        }}
      >
        {children}
      </Button>

      {show &&
        modalElement &&
        (typeof modalElement === 'function'
          ? modalElement({ closeHandler })
          : React.cloneElement(modalElement, { closeHandler }))}
    </div>
  )
}

export { ButtonWithModal }
