import React, { useState } from 'react'
import { Loading, TableGroup, Modal } from '@therms/atalaya'
import { useUrlState } from '@src/hooks/useUrlState'
import { GetTaskGroups } from '@src/services/api-client'
import { useApiWithSiteId } from '@src/hooks/useApiWithSiteId'
import { GroupsTableControls } from '@src/modules/Tasks/router/screens/Index/components/Groups/GroupsTableControls'
import { GroupsTableHeadRow } from '@src/modules/Tasks/router/screens/Index/components/Groups/GroupsTableHeadRow'
import { GroupsTableRow } from '@src/modules/Tasks/router/screens/Index/components/Groups/GroupsTableRow'
import { TaskGroupForm } from '@src/modules/Tasks/router/screens/Index/components/Groups/TaskGroupForm'

function GroupsTable() {
  const [showFormModal, setShowFormModal] = useState(false)
  const [searchTextValue, setSearchText] = useUrlState('possrch')

  const [limit, setLimit] = useUrlState('tgtlimit', 25)
  const [skip, setSkip] = useUrlState('tgtskip', 0)

  const { call, data, isCalling } = useApiWithSiteId(GetTaskGroups, {
    offset: skip,
    limit,
  })

  return (
    <div className="relative">
      <Loading overlay visible={isCalling && !data?.taskGroups?.length} />

      <TableGroup
        data={data?.taskGroups || []}
        keyProp="id"
        Controls={
          <GroupsTableControls
            onClickNewTaskGroup={() => setShowFormModal(true)}
            refreshTableData={call}
            setSearchText={setSearchText}
            searchTextValue={searchTextValue}
          />
        }
        HeadRow={GroupsTableHeadRow}
        Row={GroupsTableRow}
        onClickRow={({ rowData }) => setShowFormModal(rowData.id)}
        onSetRecordsPerPage={setLimit}
        onSetSkippedRecords={setSkip}
        recordsPerPage={limit}
        skippedRecords={skip}
        totalRecords={data?.total}
      />

      {showFormModal && (
        <Modal closeHandler={() => setShowFormModal(null)} header="Task Group">
          <TaskGroupForm
            onCancel={() => setShowFormModal(null)}
            onComplete={() => {
              setShowFormModal(null)

              call()
            }}
            taskGroupId={
              typeof showFormModal !== 'boolean' ? showFormModal : undefined
            }
          />
        </Modal>
      )}
    </div>
  )
}

GroupsTable.propTypes = {}

export { GroupsTable }
