import React, {StrictMode, useEffect} from 'react'
import {useWallboardContext, WallboardContextProvider,} from '@src/modules/wallboard/context/WallboardContext'
import {WallboardSetup} from '@src/modules/wallboard/router/screens/WallboardSetup'
import {Wallboard} from '@src/modules/wallboard/router/screens/Wallboard'
import {AtalayaWrapper, DateTimeDisplayContextProvider, Loading,} from '@therms/atalaya'
import {RefreshToken, SiteGetSite} from '@src/services/api-client'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import {LightTheme} from '@src/App'
import {cssVar} from "@src/utils/css-var"
import './index.css'
import DeactivationWrapper from "@src/modules/Auth/shared/DeactivationWrapper"

dayjs.extend(utc)
dayjs.extend(timezone)

function WallboardEntry() {
  const { jwt, setJwt, setSite, site, siteId } = useWallboardContext()

  const loadSite = async () => {
    try {
      await SiteGetSite(siteId).then((res) => {
        setSite(res.data)

        dayjs.tz.setDefault(res.data.timezone)

        // Alerts.success(`Site timezone "${res.data.timezone}"`, {
        //   toastId: `tzalert${siteId}`,
        // })
      })

      await RefreshToken().then(({ data }) => {
        if (data.token) setJwt(data.token)
      })
    } catch (err) {
      if (err?.response?.status === 401) {
        setJwt('')
      }
    }
  }

  useEffect(() => {
    cssVar('--color-background', '#081028')
  }, [])

  useEffect(() => {
    if (jwt) {
      loadSite()
    }
  }, [jwt])

  useEffect(() => {
    // reload the wallboard UI every 2hrs to check for updates and clear any stale/broken connection issues
    const i = setInterval(() => {
      document.location.reload()
    }, 60000 * 60 * 2)

    return () => clearInterval(i)
  }, [])

  if (!jwt) return <WallboardSetup />

  if (!site) return <Loading message="Loading Site Information..." overlay />

  return (
    <DateTimeDisplayContextProvider timezone={site?.timezone}>
      <Wallboard />
    </DateTimeDisplayContextProvider>
  )
}

function WallboardApp() {
  return (
      <DeactivationWrapper>
        <div style={{ height: '100vh', width: '100vw' }}>
          <StrictMode>
              <AtalayaWrapper
                defaultTheme="light"
                theme={{
                  dark: LightTheme,
                  light: LightTheme,
                }}
              >
                  <WallboardContextProvider>
                      <WallboardEntry />
                  </WallboardContextProvider>
              </AtalayaWrapper>
          </StrictMode>
        </div>
      </DeactivationWrapper>
  )
}

export { WallboardApp }
