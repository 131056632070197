import React, { useEffect, useState } from 'react'
import { PositionGetPosition, SitePosition } from '@src/services/api-client'

interface PositionNamesProps {
  sitePositions: string[]
}

function PositionNames({ sitePositions }: PositionNamesProps) {
  const [positionTitles, setPositionTitles] = useState<string[]>([])

  useEffect(() => {
    if (!sitePositions) {
      setPositionTitles([])
      return
    }

    sitePositions.map((sitePosition) => {
      PositionGetPosition(sitePosition, {
        cache: {
          ttl: 1000 * 30,
        },
      }).then((res) => {
        setPositionTitles((prev) =>
          !prev.includes(res.data.title) ? [...prev, res.data.title] : prev,
        )
      })

      return null
    })
  }, [sitePositions])

  if (!positionTitles.length) return null

  return <>{positionTitles.map((title) => title).join(', ')}</>
}

export { PositionNames }
