import React, { useEffect, useState } from 'react'
import { useApiWithSiteId } from '@src/hooks/useApiWithSiteId'
import {
  Task,
  TaskGetTask,
  TasksLoadTaskCompletions,
  TasksLoadTaskCompletionsOkResponseBody,
} from '@src/services/api-client'
import { Inline, Loading, Modal, TableGroup } from '@therms/atalaya'
import { SelectSites } from '@src/modules/Sites/shared/Fields/SelectSites'
import dayjs from 'dayjs'
import { TaskActivityTableHeadRow } from '@src/modules/Tasks/components/TaskActivityTableHeadRow'
import { TaskActivityTableRow } from '@src/modules/Tasks/components/TaskActivityTableRow'

interface TaskCompletionHistoryModalProps {
  closeHandler: () => void
  taskId: string
}

function TaskCompletionHistoryModal({
  closeHandler,
  taskId,
}: TaskCompletionHistoryModalProps) {
  const { data: task, isCalling: taskIsLoading } = useApiWithSiteId<Task>(TaskGetTask, {
    taskId,
  })
  const [pickSite, setPickSite] = useState(false)
  const [siteId, setSiteId] = useState('')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const [data, setData] = useState<TasksLoadTaskCompletionsOkResponseBody>()
  const [limit, setLimit] = useState(100)
  const [skip, setSkip] = useState(0)

  useEffect(() => {
    if (task) {
      setPickSite(false)
      setSiteId(task.siteId)
    }
  }, [task])

  useEffect(() => {
    if (task && siteId) {
      TasksLoadTaskCompletions({
        limit: 500,
        addVerificationFileUrls: true,
        endDate: new Date().toISOString(),
        startDate: dayjs().subtract(72, 'hours').toISOString(),
        showOnsiteUsersForMissed: true,
        siteId,
        taskId: task.id,
      })
        .then((res) => {
          setData(res.data)
        })
        .catch((err: any) => {
          setError('There was a problem loading Task completions')
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [siteId])

  return (
    <Modal closeHandler={closeHandler} escPressClose header="Task History">
      {task?.title && (
        <div>
          <div className="text-lg">{task.title}</div>
          <div>{task.description}</div>
        </div>
      )}

      {loading && <Loading overlay />}

      {pickSite && <SelectSites defaultToFirstSiteSelected multi={false} onChangeValue={setSiteId} />}

      {data && data.completions.length ? (
        <TableGroup
          data={data?.completions || []}
          keyProp="id"
          Controls={() => (
            <Inline
              alignX="between"
              alignY="baseline"
              className="pb-3"
              width="full"
            >
              <div />
            </Inline>
          )}
          HeadRow={TaskActivityTableHeadRow}
          // @ts-ignore
          Row={TaskActivityTableRow}
          onSetRecordsPerPage={setLimit}
          onSetSkippedRecords={setSkip}
          recordsPerPage={limit}
          skippedRecords={skip}
          totalRecords={data?.total}
        />
      ) : (
        <div className="my-base text-center text-color-neutral w-full">
          <em>This Task has no history</em>
        </div>
      )}
    </Modal>
  )
}

TaskCompletionHistoryModal.defaultProps = {}

export { TaskCompletionHistoryModal }
