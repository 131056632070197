import React from 'react'
import { useAuth } from '@src/modules/Auth/shared/state'
import { UserIcon } from '@heroicons/react/24/solid'
import { FiLogOut } from 'react-icons/fi'
import { Button } from '@therms/atalaya'

function AuthMenuProfileLink() {
  const { currentUser, logout } = useAuth()

  return (
    <div className="group flex w-full">
      <div className="mr-sm">
        <div className="h-8 w-8 rounded-full bg-main-dark items-center justify-center flex cursor-pointer">
          <UserIcon width="25" />
        </div>
      </div>

      <div className="flex justify-between items-center w-full">
        <span>{currentUser.name}</span>

        <Button
          className="group-hover:visible lg:invisible"
          onClick={logout}
          subtle
        >
          <FiLogOut className="mr-sm" /> Logout
        </Button>
      </div>
    </div>
  )
}

export { AuthMenuProfileLink }
