import {Button, Modal, Password, Select, Text} from '@therms/atalaya'
import React, {useState} from 'react'
import {Alerts} from '../../../components/Alerts'
import {
    OrganizationGetOrganizationOkResponseBody,
    SuperuserDeactivateOrg,
    SuperuserGetAllOrgs,
    SuperuserGetOrgOwnerJwt,
    SuperuserSetPricing
} from '../../../services/api-client'

interface LoginProps {
    onComplete: (jwt: string) => void
    setLoading: (loading: boolean) => void
  }


function SuperuserLogin({ onComplete, setLoading }: LoginProps) {
    const [allOrganizations, setAllOrganizations] = useState<OrganizationGetOrganizationOkResponseBody[]>([])
    const [superuserUsername, setSuperuserUsername] = useState('')
    const [superuserPassword, setSuperuserPassword] = useState('')
    const noOrganization = {label:'None', value:''}
    const priceList = [
        {label:'', value:''},
        {label:'Standard', value:'Standard'},
        {label:'Discount', value:'Discount'},
        {label:'NonBilled', value:'NonBilled'}
    ]
    const organizationsForList = [noOrganization, ...allOrganizations.map(org => ({label: org.name, value:org.id}))]
    const [targetOrganization, setTargetOrganization] = useState('')
    const [priceScheme, setPriceScheme] = useState('')
    const [showPricing, setShowPricing] = useState(false)

    const handleSuperuserGetJWT = async () => {
        const organizationId = targetOrganization
        if (organizationId === '') return
        setLoading(true)
        try {
          const {
            data: { userId, sessionKey, token },
          } = await SuperuserGetOrgOwnerJwt({ superuserUsername, superuserPassword, organizationId })

          console.log('Login success', { userId, sessionKey, token })
          setSuperuserPassword('')
          setSuperuserUsername('')
          onComplete(token)
        } catch (err) {
          console.log('Login err', err)
          Alerts.error('Unable to authenticate try again')
        } finally {
          setLoading(false)
        }
      }

    const handleOrgDelete = async () => {
        const organizationId = targetOrganization
        if (organizationId === '') return
        if (!window.confirm("ARE YOU ABSOLUTELY SURE YOU WANT TO DELETE THIS ORG?")) return
        if (!window.confirm("This ENTIRE ORG WILL BE GONE.  ARE YOU SURE?")) return
        setLoading(true)
        try {
            await SuperuserDeactivateOrg({ superuserUsername, superuserPassword, organizationId })
            setSuperuserPassword('')
            setSuperuserUsername('')
            window.location.reload()
        } catch (err) {
            console.log('Login err', err)
            Alerts.error('Unable to delete try again')
        } finally {
            setLoading(false)
        }
    }


    const handleOrgSetPricing = async () => {
        const organizationId = targetOrganization
        if (organizationId === '') return
        setLoading(true)
        try {
            await SuperuserSetPricing({ superuserUsername, superuserPassword, organizationId, pricing:priceScheme })
            setSuperuserPassword('')
            setSuperuserUsername('')
            window.location.reload()
        } catch (err) {
            console.log('Login err', err)
            Alerts.error('Unable to set pricing try again')
        } finally {
            setLoading(false)
        }
    }

    const handleSuperuserGetOrgs = async () => {
        setLoading(true)
        try {
          const {
            data
          } = await SuperuserGetAllOrgs({ superuserUsername, superuserPassword })
          setAllOrganizations(data)
        } catch (err) {
          console.log('Login err', err)
          Alerts.error('Unable to authenticate try again')
        } finally {
          setLoading(false)
        }
      }
    return (<>
        {showPricing && (
            <Modal
                closeHandler={() => setShowPricing(false)}
                escPressClose
                header="Choose Pricing"
            >
                <div className="mb-5">
                    <Select
                        label="Select Billing Type"
                        onChangeValue={setPriceScheme}
                        options={priceList}
                        value={priceScheme}
                    />
                </div>
                <Button onClick={handleOrgSetPricing}>Set</Button>
            </Modal>
        )}
        <span />
        {(allOrganizations.length > 0) ? (
            <>
                <Select
                    label="Select Organization"
                    onChangeValue={setTargetOrganization}
                    options={organizationsForList}
                    value={targetOrganization}
                />
                <Button onClick={handleSuperuserGetJWT} fullWidth variant="main">
                        Login As
                </Button>
                <Button onClick={() => {setShowPricing(true)}} fullWidth variant="main">
                    Set Pricing
                </Button>
                <Button onClick={handleOrgDelete} fullWidth variant="main">
                    Delete
                </Button>
            </>
        ) : (
            <>
                <Text placeholder='Username' onChangeValue={setSuperuserUsername} value={superuserUsername} />
                <Password placeholder='Password' onChangeValue={setSuperuserPassword} value={superuserPassword} />
                <Button onClick={handleSuperuserGetOrgs} fullWidth variant="main">
                    Superuser Login
                </Button>
            </>
        )}

      </>)
  }

  SuperuserLogin.defaultProps = {}

  export { SuperuserLogin }
