import React from 'react'
import { TableGroup } from '@therms/atalaya'

interface ContactsTableHeadRowProps {
  siteId?: string
}

function ContactsTableHeadRow({ siteId }: ContactsTableHeadRowProps) {
  return (
    <TableGroup.HeadRow>
      <TableGroup.HeadCell fitContent>Title</TableGroup.HeadCell>

      <TableGroup.HeadCell fitContent>Email</TableGroup.HeadCell>

      <TableGroup.HeadCell fitContent>Phone</TableGroup.HeadCell>

      <TableGroup.HeadCell fitContent>Admin Only</TableGroup.HeadCell>

      {!siteId && (
        <TableGroup.HeadCell fitContent>Organization Wide</TableGroup.HeadCell>
      )}

      <TableGroup.HeadCell fitContent>Description</TableGroup.HeadCell>
    </TableGroup.HeadRow>
  )
}

export { ContactsTableHeadRow }
