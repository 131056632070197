import React, { useCallback } from 'react'
import {
  Button,
  Checkbox,
  DateTime,
  FormLayout,
  Loading,
} from '@therms/atalaya'
import { Formik } from 'formik'
import { Alerts } from '@src/components/Alerts'
import { useApiWithSiteId } from '@src/hooks/useApiWithSiteId'
import {
  TaskGetTask,
  TaskMarkComplete,
  TasksLoadTaskCompletionsOkResponseBody,
} from '@src/services/api-client'
import { FormikTextarea } from '@src/components/FormikFields/FormikTextarea'
import { TaskGroupName } from '@src/modules/Tasks/shared/TaskGroupName'
import { FormikSelect } from '@src/components/FormikFields/FormikSelect'

interface Props {
  completion: TasksLoadTaskCompletionsOkResponseBody['completions'][number]
  onCancel: () => void
  onComplete: () => void
}

function MarkTaskCompletedForm({ completion, onCancel, onComplete }: Props) {
  const { data: task, isCalling } = useApiWithSiteId(TaskGetTask, {
    taskId: completion.taskId,
  })

  const save = useCallback(async (values) => {
    try {
      await TaskMarkComplete({
        completionId: completion.id,
        siteId: completion.siteId,
        skipped: values.skipped,
        // verificationFileKeys?: string[]
        userNote: values.userNote,
        triggerEscalation: values.escalationId,
      })

      Alerts.success(`Task completed`)
      onComplete()
    } catch (err) {
      Alerts.error(
        'There was a problem marking the Task completed',
        err.message,
      )

      console.log('save() err', err)
    }
  }, [])

  return (
    <Formik enableReinitialize initialValues={completion} onSubmit={save}>
      {({ isValid, setFieldValue, submitForm, isSubmitting, values }) => (
        <FormLayout dividers="space" space="xs">
          {(isCalling || isSubmitting) && <Loading overlay />}

          {task && (
            <div className="mb-lg">
              {task.taskGroupIds?.length ? (
                <TaskGroupName id={task.taskGroupIds[0]} />
              ) : null}

              <div className="text-lg">
                {task.title}
                <span className="ml-sm text-color-neutral">
                  <DateTime timestamp={completion.dueDate} />
                </span>
              </div>

              <div className="text-color-neutral">{task.description}</div>
            </div>
          )}

          <FormikTextarea label="Notes" name="userNote" />

          {!!task?.escalationIds?.length && (
            <div>
              <FormLayout.Section heading="" />
              {values.escalationId === undefined ? (
                <Checkbox
                  active={false}
                  label="Escalate an issue"
                  onChangeValue={() =>
                    setFieldValue('escalationId', task.escalationIds[0])
                  }
                />
              ) : (
                <div>
                  <FormikSelect
                    label="Select Escalation"
                    name="escalationId"
                    options={task.escalations.map((esc) => ({
                      label: esc.name,
                      value: esc.id,
                    }))}
                    subText={
                      <Button
                        onClick={() => setFieldValue('escalationId', undefined)}
                        size="sm"
                        subtle
                        variant="caution"
                      >
                        Cancel Escalation
                      </Button>
                    }
                  />
                </div>
              )}
            </div>
          )}

          <FormLayout.Controls
            submitLabel="Mark Complete"
            disabled={!isValid}
            isSaving={isSubmitting}
            onCancel={onCancel}
            onSave={submitForm}
          />
        </FormLayout>
      )}
    </Formik>
  )
}

export { MarkTaskCompletedForm }
