import React, { useState } from 'react'
import { Loading, TableGroup, Modal } from '@therms/atalaya'
import { useUrlState } from '@src/hooks/useUrlState'
import { GetEscalations } from '@src/services/api-client'
import { useApiWithSiteId } from '@src/hooks/useApiWithSiteId'
import { EscalationsTableControls } from '@src/modules/Escalations/router/screens/Index/components/Escalations/EscalationsTableControls'
import { EscalationsTableHeadRow } from '@src/modules/Escalations/router/screens/Index/components/Escalations/EscalationsTableHeadRow'
import { EscalationsTableRow } from '@src/modules/Escalations/router/screens/Index/components/Escalations/EscalationsTableRow'
import { EscalationForm } from '@src/modules/Escalations/router/screens/Index/components/Escalations/EscalationForm'

function EscalationsTable() {
  const [showFormModal, setShowFormModal] = useState(false)
  const [searchTextValue, setSearchText] = useUrlState('escsrch')

  const [limit, setLimit] = useUrlState('tesclimit', 25)
  const [skip, setSkip] = useUrlState('tescskip', 0)

  const { call, data, isCalling } = useApiWithSiteId(GetEscalations, {
    offset: skip,
    limit,
  })

  return (
    <div className="relative">
      <Loading overlay visible={isCalling && !data?.escalations?.length} />

      <TableGroup
        data={data?.escalations || []}
        keyProp="id"
        Controls={
          <EscalationsTableControls
            onClickNewEscalation={() => setShowFormModal(true)}
            refreshTableData={call}
            // setSearchText={setSearchText}
            // searchTextValue={searchTextValue}
          />
        }
        HeadRow={EscalationsTableHeadRow}
        Row={EscalationsTableRow}
        onClickRow={({ rowData }) => setShowFormModal(rowData.id)}
        onSetRecordsPerPage={setLimit}
        onSetSkippedRecords={setSkip}
        recordsPerPage={limit}
        skippedRecords={skip}
        totalRecords={data?.total}
      />

      {showFormModal && (
        <Modal closeHandler={() => setShowFormModal(null)} header="Escalation">
          <EscalationForm
            onCancel={() => setShowFormModal(null)}
            onComplete={() => {
              setShowFormModal(null)

              call()
            }}
            escalationId={
              typeof showFormModal !== 'boolean' ? showFormModal : undefined
            }
          />
        </Modal>
      )}
    </div>
  )
}

EscalationsTable.propTypes = {}

export { EscalationsTable }
