/**
 * Get or set a CSS Variable
 *
 * @example cssVar('--body') cssVar('--body', '#fff')
 */
export function cssVar(
    name: string,
    value?: string,
): string | undefined {
    if (name[0] !== '-') name = `--${  name}` // allow passing with or without --

    if (value) {
        document.documentElement.style.setProperty(name, value)
        return undefined
    }

    return getComputedStyle(document.documentElement).getPropertyValue(name)
}
