import React, { useCallback, useState } from 'react'
import { Button, FormLayout, Inline, Loading } from '@therms/atalaya'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Alerts } from '@src/components/Alerts'
import { FormikText } from '@src/components/FormikFields/FormikText'
import { useApiWithSiteId } from '@src/hooks/useApiWithSiteId'
import {
  CreateContact,
  DeleteContact,
  GetContact,
  ModifyContact,
  SendTestEmailToExternal,
} from '@src/services/api-client'
import { FormikTextarea } from '@src/components/FormikFields/FormikTextarea'
import { useAuth } from '@src/modules/Auth/shared/state'
import { FormikEmail } from '@src/components/FormikFields/FormikEmail'
import { FormikCheckbox } from '@src/components/FormikFields/FormikCheckbox'
import { FormikSelectSites } from '@src/modules/Sites/shared/Fields/FormikSelectSites'

export type ContactFormValues = {
  adminOnly?: boolean
  description?: string
  email?: string
  id: string
  isOrgWide?: boolean
  phone?: string
  siteIds: string[]
  title: string
}

export const ValidationSchema = Yup.object().shape({
  description: Yup.string().optional(),
  email: Yup.string().email().optional(),
  phone: Yup.string().optional(),
  title: Yup.string().required('Title is required'),
})

interface ContactFormProps {
  onCancel: () => void
  onComplete: () => void
  contactId?: string
  siteId?: string
}

function ContactForm({
  onCancel,
  onComplete,
  contactId,
  siteId,
}: ContactFormProps) {
  const [isDeleting, setIsDeleting] = useState(false)
  const { currentSite } = useAuth()
  const { data, isCalling } = useApiWithSiteId(GetContact, contactId || false)

  const handleDelete = async () => {
    setIsDeleting(true)

    DeleteContact(contactId).finally(() => onComplete())
  }

  const save = useCallback(async (values: ContactFormValues) => {
    try {
      if (contactId) {
        await ModifyContact({
          adminOnly: !!values.adminOnly,
          description: values.description || '',
          email: values.email,
          id: values.id,
          isOrgWide: !!values.isOrgWide,
          phone: values.phone,
          siteIds: values.siteIds,
          title: values.title,
        })
      } else {
        await CreateContact({
          adminOnly: !!values.adminOnly,
          description: values.description || '',
          email: values.email,
          isOrgWide: !!values.isOrgWide,
          phone: values.phone,
          siteIds: siteId ? [siteId] : values.siteIds,
          title: values.title,
        })
      }

      Alerts.success(`Contact ${contactId ? 'updated' : 'created'}`)

      onComplete()
    } catch (err) {
      Alerts.error('There was a problem saving the Contact', err.message)

      console.log('save() err', err)
    }
  }, [])

  const handleTestEmail = async (email: string) => {
    try {
      await SendTestEmailToExternal({
        email,
        siteId: currentSite.id,
      })

      Alerts.success('Test email sent')
    } catch (err) {
      Alerts.error('There was a problem sending the test email', err.message)

      console.log('testEmail() err', err)
    }
  }

  const contact = data || { siteIds: [currentSite.id] }

  return (
    <Formik
      enableReinitialize
      initialValues={contact}
      onSubmit={save}
      validationSchema={ValidationSchema}
    >
      {({ isValid, submitForm, isSubmitting, values }) => (
        <FormLayout dividers="space" space="xs">
          {isCalling && <Loading overlay size="xxl" />}

          {(isDeleting || isSubmitting) && <Loading overlay size="xxl" />}

          <FormikText label="Title" name="title" />

          {!siteId && <FormikSelectSites label="Sites" name="siteIds" multi />}

          <FormikEmail label="Email" name="email" />

          <FormikText label="Phone" name="phone" />

          <FormikTextarea label="Description" name="description" />

          <div className="grid grid-cols-2">
            <div className="col-span-1">
              <FormikCheckbox
                label="Admin Only"
                name="adminOnly"
                subText="Unable to be viewed on mobile app"
              />
            </div>

            {!siteId && (
              <div className="col-span-1">
                <FormikCheckbox
                  label="Organization Wide"
                  name="isOrgWide"
                  subText="Able to be viewed by all Sites"
                />
              </div>
            )}
          </div>

          {/* <FormikColorPicker label="Color" name="color" /> */}

          <FormLayout.Controls
            extraEnd={
              (!!data?.email || (!!values?.email && isValid)) && (
                <Button
                  variant="main"
                  subtle
                  onClick={() =>
                    handleTestEmail(data?.email ? data.email : values?.email)
                  }
                >
                  Send Test Email
                </Button>
              )
            }
            disabled={!isValid}
            isSaving={isSubmitting}
            onCancel={onCancel}
            onDelete={contactId && handleDelete}
            onSave={submitForm}
          />
        </FormLayout>
      )}
    </Formik>
  )
}

export { ContactForm }
