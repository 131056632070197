import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Breadcrumbs } from '@therms/atalaya'
import { useBreadcrumbsContext } from './useBreadcrumbsContext.js'

function Breadcrumb({ link, children }) {
  const { isCurrentLinkMatcher, onNavigate } = useBreadcrumbsContext()

  const handleBreadcrumbClick = useCallback(
    (_link) => {
      if (_link && onNavigate) onNavigate(_link)
    },
    [onNavigate],
  )

  const isClickable = useMemo(() => {
    if (!isCurrentLinkMatcher || !link) return false
    return !isCurrentLinkMatcher(link)
  }, [link, isCurrentLinkMatcher])

  return (
    <Breadcrumbs.Crumb
      clickable={isClickable}
      onClick={() => handleBreadcrumbClick(link)}
    >
      {children}
    </Breadcrumbs.Crumb>
  )
}

Breadcrumb.propTypes = {
  link: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Breadcrumb.defaultProps = {
  link: undefined,
}

export { Breadcrumb }
