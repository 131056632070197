import React from 'react'
import { SITES_ROUTES } from '../../constants'
import { useAuth } from '@src/modules/Auth/shared/state'
import { LeftMenuLink } from '@src/modules/Layout/shared/LeftMenuLink'
import { BuildingStorefrontIcon } from '@heroicons/react/24/solid'

function SitesMenuLink() {
  const { allSites } = useAuth()

  return (
    <LeftMenuLink to={SITES_ROUTES.ALL_SITES}>
      <BuildingStorefrontIcon width={20} /> Sites
    </LeftMenuLink>
  )
}
export { SitesMenuLink }
