import React, { useState } from 'react'
import {
  Image,
  Button,
  Email,
  Loading,
  Stack,
  Inline,
  Text,
} from '@therms/atalaya'
import { RegisterOrganization } from '@src/services/api-client'
import logo from '@src/assets/logo.png'
import { Alerts } from '@src/components/Alerts'

interface RegisterProps {
  onComplete: () => void
}

function Register({ onComplete }: RegisterProps) {
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [organizationName, setOrganizationName] = useState('')
  const [phone, setPhone] = useState('')
  const [loading, setLoading] = useState(false)

  const handleRegister = async () => {
    setLoading(true)
    try {
      await RegisterOrganization({ email, name, phone, organizationName })

      console.log('Register success', { email, name, phone, organizationName })

      Alerts.success('New Organization Registered - You will receive an email once you are approved.', {autoClose:false})

      onComplete()
    } catch (err) {
      console.log('Register err', err)
      Alerts.error('Unable to register try again')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="flex justify-center">
      {loading && <Loading overlay />}

      <div className="w-full md:w-72">
        <Inline className="mb-xl" alignX="center" width="full">
          <Image src={logo} width={200} />
        </Inline>

        <Inline className="mb-xl text-lg" alignX="center" width="full">
          Register New Organization
        </Inline>

        <Stack alignX="stretch" space="sm" width="full">
          <Text
            label="Organization Name"
            onChangeValue={setOrganizationName}
            value={organizationName}
          />

          <Text label="Admin Name" onChangeValue={setName} value={name} />

          <Text
            label="Admin Phone"
            onChangeValue={setPhone}
            subText="Use a mobile number that can receive SMS"
            value={phone}
          />
          <Email label="Email" onChangeValue={setEmail} value={email} />

          <Button onClick={handleRegister} fullWidth variant="main">
            Register
          </Button>

          <Button onClick={onComplete} fullWidth subtle variant="neutral">
            Cancel
          </Button>
        </Stack>
      </div>
    </div>
  )
}

Register.defaultProps = {}

export { Register }
