import React from 'react'
import { ORGANIZATION_ROUTES } from '../../constants'
import { BiSolidBusiness } from 'react-icons/bi'
import { LeftMenuLink } from '@src/modules/Layout/shared/LeftMenuLink'
import { useAuth } from '@src/modules/Auth/shared/state'

function OrganizationMenuLink() {
  const { currentUser } = useAuth()

  if (!currentUser.isAdmin) return null

  return (
    <LeftMenuLink to={ORGANIZATION_ROUTES.__ROOT__}>
      <BiSolidBusiness size={20} /> Organization
    </LeftMenuLink>
  )
}

export { OrganizationMenuLink }
