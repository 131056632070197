import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { TaskGroupGetTaskGroup } from '@src/services/api-client'
import { getColorContrast } from '@src/utils/getColorContrast'
import { Loading } from '@therms/atalaya'

function TaskGroupName({ id }) {
  const [data, setData] = useState(undefined)
  const [loading, setLoading] = useState(false)

  const taskGroupColor = data?.uiData?.color

  useEffect(() => {
    if (!id) {
      setData(undefined)
      return
    }
    setLoading(true)

    TaskGroupGetTaskGroup(id, {
      cache: {
        ttl: 1000 * 30,
      },
    })
      .then((res) => {
        setData(res.data)
      })
      .finally(() => setLoading(false))
  }, [id])

  const containerStyle = useMemo(
    () =>
      taskGroupColor
        ? {
            backgroundColor: taskGroupColor,
            borderRadius: '2px',
            color: getColorContrast(taskGroupColor),
            padding: '0 2px',
          }
        : undefined,
    [taskGroupColor],
  )

  if (loading)
    return (
      <span className="inline-block truncate w-full" style={containerStyle}>
        <Loading />
      </span>
    )

  return (
    <span className="inline-block truncate w-full" style={containerStyle}>
      {data?.name}
    </span>
  )
}

TaskGroupName.propTypes = {
  id: PropTypes.string.isRequired,
}

export { TaskGroupName }
