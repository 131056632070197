import { Tabs } from '@therms/atalaya'
import React from 'react'
import { TasksTable } from './components/Tasks/TasksTable'
import { TaskActivityTable } from '@src/modules/Tasks/router/screens/Index/components/TaskActivity/TaskActivityTable'
import { useUrlState } from '@src/hooks/useUrlState'
import { GroupsTable } from '@src/modules/Tasks/router/screens/Index/components/Groups/GroupsTable'

function TasksIndex() {
  const [tab, setTab] = useUrlState('taskstabs', 'activity')

  return (
    <div>
      <div className="text-lg">Site Tasks</div>

      <Tabs currentKey={tab} initialTabKey={tab} onTabClick={setTab}>
        <Tabs.Tab tabKey="activity" title="Activity">
          <TaskActivityTable />
        </Tabs.Tab>

        <Tabs.Tab tabKey="tasks" title="Tasks">
          <TasksTable />
        </Tabs.Tab>

        <Tabs.Tab tabKey="groups" title="Groups">
          <GroupsTable />
        </Tabs.Tab>
      </Tabs>
    </div>
  )
}

export { TasksIndex }
