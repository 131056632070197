import React from 'react'
import { Button, Inline, Text } from '@therms/atalaya'
import PropTypes from 'prop-types'
import { Alerts } from '@src/components/Alerts'
import { ButtonWithActionModal } from '@src/components/ButtonWithActionModal'
import { DeleteEscalation } from '@src/services/api-client'
import { delErrorCheck } from '@src/services/api-resp-checks'

function EscalationsTableControls({
  clearSelectedRows,
  onClickNewEscalation,
  refreshTableData,
  selectedRowsKeys,
}) {
  return (
    <Inline alignX="between" alignY="baseline" className="pb-3" width="full">
      <Inline alignY="center" space="xs">
        {
          selectedRowsKeys?.length > 0 ? (
            <>
              <div className="text-color-subtle text-sm">
                {selectedRowsKeys?.length} Selected
              </div>

              <ButtonWithActionModal
                actionText="Delete"
                body="Are you sure you want to delete these Task Escalations?"
                onAction={async () => {
                  try {
                    await Promise.all(
                      selectedRowsKeys.map((id) => DeleteEscalation(id).catch(err => delErrorCheck(err))),
                    )

                    clearSelectedRows()
                    refreshTableData()

                    Alerts.success(`Escalations deleted`)
                  } catch (err) {
                    Alerts.error(
                      'There was a problem deleting the Task Escalations',
                      err.message,
                    )

                    console.log('save() err', err)
                  }
                }}
                size="sm"
                subtle
                title="Delete Task Escalations"
                variant="critical"
              />
            </>
          ) : null
          // <Text
          //   placeholder="Search..."
          //   onChangeValue={setSearchText}
          //   value={searchTextValue}
          //   search
          // />
        }
      </Inline>

      <div>
        <Button onClick={onClickNewEscalation} variant="main">
          New Type
        </Button>
      </div>
    </Inline>
  )
}
EscalationsTableControls.propTypes = {
  clearSelectedRows: PropTypes.func,
  onClickNewEscalation: PropTypes.func.isRequired,
  refreshTableData: PropTypes.func.isRequired,
  selectedRowsKeys: PropTypes.arrayOf(PropTypes.any),
}

export { EscalationsTableControls }
