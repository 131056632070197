import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

function LeftMenuLink({ children, to }) {
  return (
    <NavLink
      activeClassName="bg-gray-900 group flex gap-x-3 rounded-md p-2 leading-6 font-semibold"
      to={to}
      className=" text-white group flex gap-x-3 p-2"
    >
      {children}
    </NavLink>
  )
}

LeftMenuLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string.isRequired,
}

export { LeftMenuLink }
