import { Dispatch, SetStateAction, useState } from 'react'

export function useSessionStorage<S>(
  key: string | undefined,
  initialState: S | (() => S),
): [S, Dispatch<SetStateAction<S>>]

export function useSessionStorage<S = unknown>(
  key: string | undefined,
): [S | undefined, (value: S) => void]

/**
 * Store values in session storage the same as the useState() hook.
 *
 * If no "key" is passed then no sessionStorage will take place
 */
export function useSessionStorage(key?, initialValue?) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      let _initialValue = initialValue

      if (initialValue instanceof Function) {
        _initialValue = initialValue()
      }

      if (!key) return _initialValue

      const item = window?.sessionStorage?.getItem(key)

      return item ? JSON.parse(item) : _initialValue
    } catch (error) {
      // todo: handle the error better
      console.error('useSessionStorage()', error)

      return undefined as any
    }
  })

  const setValue = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value

      setStoredValue(valueToStore)

      if (key) {
        window.sessionStorage.setItem(key, JSON.stringify(valueToStore))
      }
    } catch (error) {
      // todo: handle the error better
      console.error(error)
    }
  }

  return [storedValue, setValue]
}
