/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useState } from 'react'
import { Select, SelectProps } from '@therms/atalaya'
import {
  GetUsersBySiteId,
  GetUsersOkResponseBody,
} from '@src/services/api-client'
import { PositionName } from '@src/modules/Positions/shared/PositionName'
import { useAuth } from '../../Auth/shared/state'

type SelectUsersProps = Omit<SelectProps, 'options'>

function SelectUser({ label, multi, name, value, ...props }: SelectUsersProps) {
  const [data, setData] = useState<GetUsersOkResponseBody['users']>([])
  const { currentSite } = useAuth()
  const options = useMemo(
    () =>
      data.map((user) => {
        const userCurrentSitePositionId = user.sitePositions.filter(
          (posId) => posId.siteId === currentSite.id,
        )

        return {
          label: (
            <span>
              {user.name} (
              <PositionName id={userCurrentSitePositionId[0].positionId} />)
            </span>
          ),
          value: user.id,
        }
      }) ?? [],
    [data],
  )

  useEffect(() => {
    GetUsersBySiteId({ limit: 500, siteId: currentSite.id }).then((res) => {
      setData(res.data.users)
    })
  }, [])

  return (
    <Select
      label={label}
      multi={!!multi}
      name={name}
      options={options}
      {...props}
      value={value}
    />
  )
}
export { SelectUser }
