import React, {useState} from 'react'
import {CardElement, useElements, useStripe,} from '@stripe/react-stripe-js'
import PropTypes from "prop-types"
import {SetPaymentMethod} from "@src/services/api-client"


const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#495057", // Text color
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "18px",
            "::placeholder": {
                color: "#6c757d" // Placeholder text color
            },
            backgroundColor: "white", // Background color
            border: "1px solid #ced4da", // Border color
            borderRadius: "5px", // Border radius
        },
        invalid: {
            color: "#dc3545", // Text color for invalid input
            iconColor: "#dc3545" // Icon color for invalid input
        }
    }
}



export function StripeCheckoutForm({onComplete}) {
    const stripe = useStripe()
    const elements = useElements()
    const [cardholderName, setCardholderName] = useState('')
    const [errorMessage, setErrorMessage] = useState(null)

    const handleSubmit = async (event) => {
        event.preventDefault()

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            return
        }

        const cardElement = elements.getElement(CardElement)

        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: { name: cardholderName },
        })
        
        if (error) {
            setErrorMessage(error.message)
        } else {
            // You have successfully created a new PaymentMethod
            // Send the paymentMethod.id to your server here
            try {
                await SetPaymentMethod({paymentMethodId: paymentMethod.id})
                onComplete()
            } catch(err) {
                setErrorMessage(`Failed to save payment method. Error: ${  err.message}`)
            }


        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <input
                    type="text"
                    value={cardholderName}
                    onChange={(e) => setCardholderName(e.target.value)}
                    placeholder="Cardholder's name"
                    style={{ margin: '10px 0', padding: '8px', width: '100%', fontSize:"18px" }}
                />
            </div>
            <div className="p-2" style={{ backgroundColor:"white", borderColor:"black", borderWidth: "1px"}}>
                <CardElement options={CARD_ELEMENT_OPTIONS} />
            </div>
            <button type="submit" className="mt-3" style={{ background: '#00a300', color: 'white', padding: '8px 12px', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
                Save Payment Method
            </button>
            {errorMessage && <div className="mt-2" style={{color:"red"}}>{errorMessage}</div>}
        </form>
    )
}

StripeCheckoutForm.propTypes = {
    onComplete: PropTypes.func.isRequired,

}
