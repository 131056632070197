import React from 'react'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { SiteGetSiteOkResponseBody } from '@src/services/api-client'
import { WALLBOARD_JWT_KEY } from '@src/modules/wallboard/constants'
import { getDeviceId } from '@src/modules/wallboard/utils/get-device-id'
import {useParams} from "react-router-dom"

type WallboardContextValue = {
  deviceId: string
  jwt?: string
  setJwt: (newJWT: string) => void
  setSite: (site: SiteGetSiteOkResponseBody) => void
  setSiteId: (siteId: string) => void
  site?: SiteGetSiteOkResponseBody
  siteId?: string
}

const WallboardContext = React.createContext<WallboardContextValue | undefined>(
  undefined,
)

function makeWallboardLSKey(str: string) {
  return `wallboard_${str}`
}

export function WallboardContextProvider({ children }: { children: any }) {
  const [deviceId] = useLocalStorage(
    makeWallboardLSKey('deviceId'),
    getDeviceId(),
  )
  const [jwt, setJwt] = useLocalStorage(WALLBOARD_JWT_KEY, '')
  const [siteId, setSiteId] = useLocalStorage(makeWallboardLSKey('siteId'), '')
  const [site, setSite] = useLocalStorage<
    SiteGetSiteOkResponseBody | undefined
  >(makeWallboardLSKey('site'), undefined)

  const value = React.useMemo(
    () => ({
      deviceId,
      jwt,
      setJwt,
      setSite,
      setSiteId,
      site,
      siteId,
    }),
    [deviceId, jwt, site],
  )

  return (
    <WallboardContext.Provider value={value}>
      {children}
    </WallboardContext.Provider>
  )
}

export function useWallboardContext() {
  const context = React.useContext(WallboardContext)

  return context as WallboardContextValue
}
