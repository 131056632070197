import React from 'react'
import { Textarea } from '@therms/atalaya'
import {Field, FieldAttributes, useFormikContext} from 'formik'

function FormikTextarea(props: FieldAttributes<Parameters<typeof Textarea>[0]>) {
  const form = useFormikContext()

  return (
      <Field name={props.name}>
        {({ field, meta }) => (
            <Textarea
                {...props}
                error={(meta.touched || !!form.submitCount) && meta.error}
                valid={(meta.touched || !!form.submitCount) && meta.value && !meta.error}
                {...field}
            />
        )}
      </Field>
  )
}

export { FormikTextarea }
