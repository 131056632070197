import React, {useMemo} from 'react'
import {Checkbox, DateTimePicker, FormLayout, Text} from '@therms/atalaya'
import {timezones} from '@src/utils/timezones'
import {FormikText} from '@src/components/FormikFields/FormikText'
import {FormikSelect} from '@src/components/FormikFields/FormikSelect'
import {useFormikContext} from 'formik'
import dayjs from 'dayjs'
import {RRule} from 'rrule'
import {SiteCreateSiteRequestBody} from '@src/services/api-client'
import {FormikCheckbox} from '@src/components/FormikFields/FormikCheckbox'
import {useBrands} from "@src/hooks/useBrands"

function SiteInfoFormSection() {
  const form = useFormikContext<SiteCreateSiteRequestBody>()
  const brands = useBrands()
    const brandOptions = brands.map((b) => ({
        label: b.name,
        value: b.id,
    }))
  const storeHoursTime = useMemo(() => {
    try {
      if (!form.values.storeHours) return {}

      const d = new Date()

      const openRrule = form.values.storeHours.storeOpeningRules
      const closeRrule = form.values.storeHours.storeClosingRules

      let open = ''
      let close = ''

      if (openRrule.length) {
        const rr = RRule.fromString(openRrule[0]).options

        if (rr.byminute?.length && rr.byhour?.length) {
          d.setMinutes(rr.byminute[0])
          d.setHours(rr.byhour[0])
        } else {
          d.setMinutes(0)
        }

        open = d.toISOString()
      }

      if (closeRrule.length) {
        const rr = RRule.fromString(closeRrule[0]).options

        if (rr.byminute?.length && rr.byhour?.length) {
          d.setMinutes(rr.byminute[0])
          d.setHours(rr.byhour[0])
        } else {
          d.setMinutes(0)
        }

        close = d.toISOString()
      }

      return open && close
        ? { open, close }
        : {
            close: dayjs().set('hour', 22).set('minute', 0).toISOString(),
            open: dayjs().set('hour', 6).set('minute', 0).toISOString(),
          }
    } catch (err) {
      console.error('RRULE error', err)
      return {}
    }
  }, [form.values.storeHours])

  return (
    <FormLayout dividers="space">
      <FormLayout.Section heading="Info">
        <FormikText label="Name" name="name" />

        <FormikSelect
          label="Timezone"
          name="timezone"
          placeholder="Select Timezone..."
          options={timezones.map(({ label, tzCode }) => ({
            label,
            value: tzCode,
          }))}
        />

        <Text
          label="CC Emails"
          subText="Emails we will cc for escalation emails sent to contacts for this site"
          onChangeValue={(value) => {
            const emails = value.split(', ')
            form.setFieldValue(
              'emailToCCOnContactRequests',
              value ? emails : [],
            )
          }}
          search
          value={form.values.emailToCCOnContactRequests?.join(', ')}
        />

        <FormikCheckbox
          active={!!form.values.wallboardSettings?.qrCodeSignIn}
          label="Allow Login using Wallboard"
          name="wallboardSettings.qrCodeSignIn"
        />
      </FormLayout.Section>

      <FormLayout.Divider withBorder />

      <FormLayout.Section heading="Address">
        <FormikText label=" " name="address" />
      </FormLayout.Section>

      <FormLayout.Divider withBorder />
        <FormLayout.Section heading="Brands">
            <FormikSelect

                name="brandIds"
                placeholder="Select Brands..."
                options={brandOptions}
                multi
            />
        </FormLayout.Section>
      <FormLayout.Divider withBorder />

      <FormLayout.Section heading="Store Hours">
        <Checkbox
          active={!form.values.storeHours}
          label="This Site is open 24hr"
          onChangeValue={(checked) => {
            if (!checked) {
              const dOpen = dayjs().set('hour', 6).set('minute', 0)
              const dClose = dayjs().set('hour', 22).set('minute', 0)

              form.setFieldValue('storeHours', {
                storeOpeningRules: new RRule({
                  freq: RRule.DAILY,
                  byhour: [dOpen.get('hour')],
                  byminute: [dOpen.get('minute')],
                  bysecond: [0],
                  interval: 1,
                }).toString(),
                storeClosingRules: new RRule({
                  freq: RRule.DAILY,
                  byhour: [dClose.get('hour')],
                  byminute: [dClose.get('minute')],
                  bysecond: [0],
                  interval: 1,
                }).toString(),
              })
            } else {
              form.setFieldValue('storeHours', null)
            }
          }}
        />

        {form.values.storeHours && (
          <>
            <DateTimePicker
              mode="time"
              label="Open"
              onChangeValue={(time) => {
                const d = new Date(time)

                const rruleStr = new RRule({
                  freq: RRule.DAILY,
                  byhour: [d.getHours()],
                  byminute: [d.getMinutes()],
                  bysecond: [0],
                  interval: 1,
                }).toString()

                form.setFieldValue('storeHours.storeOpeningRules', rruleStr)
              }}
              value={storeHoursTime.open}
            />

            <DateTimePicker
              mode="time"
              label="Close"
              onChangeValue={(time) => {
                const d = new Date(time)
                const rruleStr = new RRule({
                  freq: RRule.DAILY,
                  byhour: [d.getHours()],
                  byminute: [d.getMinutes()],
                  bysecond: [0],
                  interval: 1,
                }).toString()

                form.setFieldValue('storeHours.storeClosingRules', rruleStr)
              }}
              value={storeHoursTime.close}
            />
          </>
        )}
      </FormLayout.Section>
    </FormLayout>
  )
}

SiteInfoFormSection.propTypes = {}

export { SiteInfoFormSection }
