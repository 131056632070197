import React, { useEffect, useState } from 'react'
import { Login } from '../components/Login'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { Button, Inline, Loading } from '@therms/atalaya'
import { Register } from '@src/modules/Auth/components/Register'
import { isProd } from '@src/utils/is-prod'
import { useSnapshot } from 'valtio/react'
import { authState, fetchAndSetAuthState } from '@src/modules/Auth/shared/state'

function AuthWrapper({ children }: { children: React.ReactNode }) {
  // instantiate for triggering re-rendering
  useSnapshot(authState)

  const [jwt, setJwt] = useLocalStorage('jwt', '')

  const [showRegistration, setShowRegistration] = useState(false)

  useEffect(() => {
    authState.jwt = jwt

    if (!authState.currentUser && jwt) {
      fetchAndSetAuthState()
    }
  }, [jwt])

  useEffect(() => {
    if (authState.currentUser && !authState.currentUser.isAdmin) {
      if (!isProd) {
        alert(`Redirecting - user is not an admin`)
      }

      document.location.href = isProd ? 'https://quicktask.us' : '/'
    }
  }, [authState.currentUser])

  if (showRegistration) {
    return (
      <div
        className="flex flex-col align-items-center justify-center"
        style={{ height: '100vh', width: '100vw' }}
      >
        <Register onComplete={() => setShowRegistration(false)} />
      </div>
    )
  }

  if (!authState.jwt) {
    return (
      <div
        className="flex flex-col align-items-center justify-center"
        style={{ height: '100vh', width: '100vw' }}
      >
        <Login onComplete={(_jwt) => setJwt(_jwt)} />

        <Inline alignX="center" className="mt-base" width="full">
          <Button
            onClick={() => setShowRegistration(true)}
            variant="main"
            subtle
          >
            Register
          </Button>
        </Inline>

        <div className="mt-xl text-sm text-center text-color-neutral-faded">
          build {BUILD_ENV.BUILD_NUMBER || '000'}
        </div>
      </div>
    )
  }

  if (!authState.currentUser) {
    return <Loading overlay />
  }

  return <div>{children}</div>
}

AuthWrapper.defaultProps = {}

export { AuthWrapper }
