import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useSessionStorage } from './useSessionStorage'
import { usePrevious } from './usePrevious'
import { isNull } from 'lodash-es'

const defaultOpts = Object.freeze({
  removeOnUnmount: true,
  sessionStorage: true,
})

interface Opts {
  /* If the urlParams should be removed from the history when the component unmounts - defaults to true. */
  removeOnUnmount?: boolean
  /* If provided then the values will persist in sessionStorage. When the component mounts, sessionStorage values will overwrite `defaultValue` - defaults to true  */
  sessionStorageKey?: string
}

function parseUrlValue(
  value: any,
  type: 'boolean' | 'number' | 'string' | 'array' | 'object',
) {
  switch (type) {
    case 'number':
      if (typeof value === 'undefined') return 0

      return +value
    case 'string':
      if (typeof value === 'undefined') return ''

      return value.toString()
    case 'object':
      if (typeof value === 'undefined') return {}

      return JSON.parse(value)
    case 'array':
      if (typeof value === 'undefined') return []

      return JSON.parse(value)

    case 'boolean':
      if (typeof value === 'undefined') return false

      return JSON.parse(value)
    default:
      return value
  }
}

function makeUrlSafeValue(value: any): string {
  if (typeof value !== 'undefined') {
    if (Array.isArray(value) || typeof value === 'object') {
      return JSON.stringify(value)
    }

    if (value?.toString) {
      return value.toString()
    }
  }

  return ''
}

export function useUrlState<T>(
  urlParamKey: string,
  defaultValue?: T,
  opts?: Opts,
): [T, Dispatch<SetStateAction<T>>]

/**
 * Store and retrieve state from the URL search params.
 *
 * @param urlParamKey {string} The url search param key
 * @param defaultValue {array|object|number|string} This value is used to coerce the data-type that is returned, ie: Array or Number. Default return value will be a string if not provided
 * @param opts {Opts}
 */
export function useUrlState<T = unknown>(
  urlParamKey: string,
  defaultValue?: T,
  opts: Opts = defaultOpts,
) {
  const mergedOpts = { ...defaultOpts, ...opts }

  const [sessionStorageKey] = useState(() => {
    if (mergedOpts.sessionStorageKey) {
      return mergedOpts.sessionStorageKey + urlParamKey
    }

    // No session storage is used when there is no `mergedOpts.sessionStorageKey` value
    return undefined
  })

  const [value, setValue] = useSessionStorage<T>(sessionStorageKey, () => {
    let defaultValueType: 'boolean' | 'number' | 'string' | 'array' | 'object' =
      'string'

    if (Array.isArray(defaultValue)) defaultValueType = 'array'
    if (typeof defaultValue === 'object') defaultValueType = 'object'
    if (typeof defaultValue === 'number') defaultValueType = 'number'
    if (typeof defaultValue === 'boolean') defaultValueType = 'boolean'

    const initialUrlValue = new URLSearchParams(
      window?.location?.search || '',
    ).get(urlParamKey)

    if (typeof initialUrlValue !== 'undefined' && !isNull(initialUrlValue)) {
      return parseUrlValue(initialUrlValue, defaultValueType)
    }

    return defaultValue
  })

  const prevValue = usePrevious(value)

  useEffect(() => {
    if (!urlParamKey || typeof urlParamKey !== 'string')
      throw new Error(
        'useUrlState() must have a "urlParamKey" prop as a string',
      )
  }, [urlParamKey])

  useEffect(() => {
    if (value === prevValue) return

    const params = new URLSearchParams(window?.location?.search || '')

    if (typeof value !== 'undefined' && !isNull(value)) {
      params.set(urlParamKey, makeUrlSafeValue(value))
    } else {
      params.delete(urlParamKey)
    }

    const searchStr = params.toString() ? `?${params.toString()}` : ''

    // Note: We do not mutate the actual history stack in the case of a react-router-dom web-app.
    // We only update the window url so the user can copy the URL and share, all other "persistence" takes place
    // in the useSessionStorage() state if configured to persist.
    window?.history?.replaceState(
      '',
      '',
      `${window.location.pathname}${searchStr}`,
    )
  }, [value])

  useEffect(() => {
    if (mergedOpts.removeOnUnmount) {
      return () => {
        const params = new URLSearchParams(window?.location?.search || '')

        params.delete(urlParamKey)

        const searchStr = params.toString() ? `?${params.toString()}` : ''

        // We don't remove the urlParams from the `history` object because we want that "history" to persist.
        // We only want to remove the urlParams for the next URL/route.
        window?.history?.replaceState(
          '',
          '',
          `${window.location.pathname}${searchStr}`,
        )
      }
    }

    return undefined
  }, [])

  return [value, setValue]
}
