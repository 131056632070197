/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useState } from 'react'
import { Select, SelectProps } from '@therms/atalaya'
import { GetEscalations } from '@src/services/api-client'
import { useAuth } from '../../Auth/shared/state'

type SelGetEscalationsProps = Omit<SelectProps, 'options'>

function SelectEscalation({
  label,
  multi,
  name,
  value,
  ...props
}: SelGetEscalationsProps) {
  const [data, setData] = useState([])
  const { currentSite } = useAuth()
  const options = useMemo(
    () =>
      data.map((taskGroup) => ({
        label: taskGroup.name,
        value: taskGroup.id,
      })) ?? [],
    [data],
  )

  useEffect(() => {
    GetEscalations({ limit: 250, siteId:currentSite.id }).then((res) => {
      setData(res.data.escalations)
    })
  }, [])

  return (
    <Select
      label={label}
      multi={!!multi}
      name={name}
      options={options}
      {...props}
      value={value}
    />
  )
}
export { SelectEscalation }
