/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useState } from 'react'
import { Select, SelectProps } from '@therms/atalaya'
import { GetTasks } from '@src/services/api-client'
import { useAuth } from '../../Auth/shared/state'

type SelectTasksProps = Omit<SelectProps, 'options'> & { taskGroupId?: string }

function SelectTask({
  label,
  multi,
  name,
  taskGroupId,
  value,
  ...props
}: SelectTasksProps) {
  const [data, setData] = useState([])
  const { currentSite } = useAuth()
  const options = useMemo(
    () =>
      data.map((task) => ({
        label: task.taskGroups?.[0] ? (
          <>
            {task.taskGroups[0].name} - {task.title}
          </>
        ) : (
          task.title
        ),
        value: task.id,
      })) ?? [],
    [data],
  )

  useEffect(() => {
    GetTasks({ limit: 500, taskGroupId, siteId:currentSite.id }).then((res) => {
      setData(res.data.tasks)
    })
  }, [taskGroupId, currentSite])

  return (
    <Select
      label={label}
      multi={!!multi}
      name={name}
      options={options}
      {...props}
      value={value}
    />
  )
}
export { SelectTask }
