import React, { useEffect, useMemo, useState } from 'react'
import { GetSiteSnapshotForAdminResponseBody } from '@src/services/api-client'
import { Card, Tag } from '@therms/atalaya'
import { useWallboardContext } from '@src/modules/wallboard/context/WallboardContext'
import dayjs from 'dayjs'
import { TaskGroupName } from '@src/modules/Tasks/shared/TaskGroupName'
import { UserNameNoAuth } from '@src/modules/Users/shared/UserNameNoAuth'
import { humanizeMinutes } from '@therms/web-js'
import { COMPLETION_STATUS } from '@src/modules/wallboard/constants'

interface ActivityCardProps {
  snapshotForAdminRecent: GetSiteSnapshotForAdminResponseBody['recent']
}

function ActivityCard({ snapshotForAdminRecent }: ActivityCardProps) {
  const wallboardCtx = useWallboardContext()
  const [reloadTrigger, setReloadTrigger] = useState(Math.random())

  const [endDate, startDate] = useMemo(
    () => [
      new Date().toISOString(),
      dayjs().subtract(24, 'hours').toISOString(),
    ],
    [reloadTrigger],
  )

  const [groupedRecentTasks, setGroupedRecentTasks] = useState([])

  const recentTasks = () => {
    let _groupedRecentTasks = []

    if (snapshotForAdminRecent?.completionsByTaskGroupId.byGroup) {
      Object.keys(snapshotForAdminRecent?.completionsByTaskGroupId.byGroup).map(
        (key) => {
          _groupedRecentTasks = [
            ...snapshotForAdminRecent.completionsByTaskGroupId.byGroup[key],
            ..._groupedRecentTasks,
          ]

          return null
        },
      )
    }
    setGroupedRecentTasks([
      ..._groupedRecentTasks,
      ...snapshotForAdminRecent.completionsByTaskGroupId.noGroup,
    ])
  }

  useEffect(() => {
    if (snapshotForAdminRecent?.completionsByTaskGroupId.noGroup) {
      recentTasks()
    }
  }, [snapshotForAdminRecent])

  useEffect(() => {
    const i = setInterval(() => {
      setReloadTrigger(Math.random())
    }, 15000)

    return () => clearInterval(i)
  }, [])

  const taskActivityWithInThirtyMinutes = groupedRecentTasks
    .sort((a, b) => (a.completedAtTime > b.completedAtTime ? -1 : 1))
    .filter((completion) => completion.completedAtTime > startDate)

  const activityCompletionStatus = (_completion) => {
    if (_completion?.status === 'skipped') {
      return (
        <Tag value={COMPLETION_STATUS[_completion.status]} variant="caution" />
      )
    }

    if (_completion?.status === 'complete') {
      return (
        <Tag value={COMPLETION_STATUS[_completion.status]} variant="positive" />
      )
    }

    return null
  }

  return (
    <Card className="overflow-hidden" padding="xs" space="xxs">
      <div className="text-lg">Activity</div>

      <div className="mt-base overflow-auto">
        {taskActivityWithInThirtyMinutes.map((completion) => (
          <div
            className="grid gap-x-xxs grid-cols-7 items-center"
            key={completion.id}
          >
            {completion.taskGroupIds.length ? (
              <small className="bg-surface rounded-bl rounded-tr truncate col-span-2">
                <TaskGroupName id={completion.taskGroupIds[0]} />
              </small>
            ) : (
              <span className="bg-surface w-fit rounded-bl rounded-tr truncate col-span-2" />
            )}

            <span className="truncate  col-span-2">{completion.title}</span>

            <span>{activityCompletionStatus(completion)}</span>

            <small className="font-light text-color-subtle truncate">
              {humanizeMinutes(
                dayjs().diff(completion.completedAtTime, 'minute'),
              )}{' '}
              ago
            </small>

            <span className="text-color font-light text-sm">
              <UserNameNoAuth
                id={completion.completedByUserId}
                siteId={wallboardCtx.siteId}
              />
            </span>
          </div>
        ))}

        {!taskActivityWithInThirtyMinutes.length && (
          <div className="text-center text-color-neutral-dark">
            No Recent Activity
          </div>
        )}
      </div>
    </Card>
  )
}

export { ActivityCard }
