import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { PositionsTable } from '@src/modules/Positions/router/screens/Index'
import { Button } from '@therms/atalaya'
import {ErrorBoundary} from "react-error-boundary"
import * as Sentry from "@sentry/react"

function PositionsRoutes() {
  return (
    <Switch>
      <Route
        render={() => (
          <ErrorBoundary
              onError={(error, componentStack) => {
                  Sentry.withScope((scope) => {
                      scope.setExtras(componentStack)
                      Sentry.captureException(error)
                  })
              }}
            fallbackRender={({ error, resetErrorBoundary }) => (
              <div>
                <p>Something went wrong:</p>
                <pre>{error.message}</pre>
                <Button onClick={resetErrorBoundary}>Try again</Button>
              </div>
            )}
          >
            <PositionsTable />
          </ErrorBoundary>
        )}
      />
    </Switch>
  )
}

export { PositionsRoutes }
