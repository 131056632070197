import React from 'react'
import { Field, FieldAttributes, useFormikContext } from 'formik'
import {
  SelectSites,
  SelectSitesProps,
} from '@src/modules/Sites/shared/Fields/SelectSites'

function FormikSelectSites(
  props: FieldAttributes<Omit<SelectSitesProps, 'onChangeValue'>>,
) {
  const form = useFormikContext()

  return (
    <Field name={props.name}>
      {({ field, meta }) => (
        <SelectSites
          {...props}
          error={(meta.touched || !!form.submitCount) && meta.error}
          valid={
            (meta.touched || !!form.submitCount) && meta.value && !meta.error
          }
          onChangeValue={(value) =>
            props.name && form.setFieldValue(props.name, value)
          }
          {...field}
          onBlur={(e) => {
            if (props.onBlur) props.onBlur(e)
            field.onBlur(e)
          }}
          // this component relies on `onChangeValue`
          onChange={null}
        />
      )}
    </Field>
  )
}

export { FormikSelectSites }
