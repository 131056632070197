/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useState } from 'react'
import { Select, SelectProps } from '@therms/atalaya'
import { GetSites } from '@src/services/api-client'

export type SelectSitesProps = Omit<SelectProps, 'options'> & {
  defaultToFirstSiteSelected?: boolean
}

function SelectSites({
  defaultToFirstSiteSelected,
  label,
  multi,
  name,
  value,
  ...props
}: SelectSitesProps) {
  const [isSingleSite, setIsSingleSite] = useState(true)
  const [data, setData] = useState([])

  const options = useMemo(
    () =>
      data.map((site) => ({
        label: site.name,
        value: site.id,
      })) ?? [],
    [data],
  )

  useEffect(() => {
    GetSites({ limit: 100 }).then((res) => {
      const singleResult = res.data.sites.length === 1

      if (singleResult) {
        setIsSingleSite(true)
      } else {
        setIsSingleSite(false)
      }

      setData(res.data.sites)

      if (
        (defaultToFirstSiteSelected || singleResult) &&
        res.data.sites.length
      ) {
        props.onChangeValue?.(
          multi ? [res.data.sites[0].id] : res.data.sites[0].id,
        )
      }
    })
  }, [])

  if (isSingleSite) return null

  return (
    <Select
      label={label}
      multi={multi}
      name={name}
      options={options}
      {...props}
      value={value}
    />
  )
}
export { SelectSites }
