import { Tabs } from '@therms/atalaya'
import React from 'react'
import { EscalationsTable } from './components/Escalations/EscalationsTable'
import { useUrlState } from '@src/hooks/useUrlState'
import { EscalationOccurrencesTable } from '@src/modules/Escalations/router/screens/Index/components/EscalationOccurrences/EscalationOccurrencesTable'
import { useUnresolvedEscOccurrenceCount } from '@src/modules/Escalations/hooks/useUnresolvedEscOccurrenceCount'

function EscalationsIndex() {
  const [tab, setTab] = useUrlState('esclstabs', 'occurrences')
  const count = useUnresolvedEscOccurrenceCount()

  return (
    <div>
      <div className="text-lg">Site Escalations</div>

      <Tabs currentKey={tab} initialTabKey={tab} onTabClick={setTab}>
        <Tabs.Tab
          notifications={count || ''}
          tabKey="occurrences"
          title="Issues"
        >
          <EscalationOccurrencesTable />
        </Tabs.Tab>

        <Tabs.Tab tabKey="types" title="Escalation Types">
          <EscalationsTable />
        </Tabs.Tab>
      </Tabs>
    </div>
  )
}

export { EscalationsIndex }
