import React from 'react'
import { TableGroup } from '@therms/atalaya'
// import PropTypes from 'prop-types'

function TaskActivityTableHeadRow() {
  return (
    <TableGroup.HeadRow>
      <TableGroup.HeadCell>Status</TableGroup.HeadCell>

      <TableGroup.HeadCell>Time Completed</TableGroup.HeadCell>

      <TableGroup.HeadCell canSort>User</TableGroup.HeadCell>

      <TableGroup.HeadCell canSort>Task</TableGroup.HeadCell>
      <TableGroup.HeadCell canSort={false}>Review</TableGroup.HeadCell>
    </TableGroup.HeadRow>
  )
}

TaskActivityTableHeadRow.propTypes = {}

export { TaskActivityTableHeadRow }
