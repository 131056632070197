import React from 'react'
import { ActionModal } from '@therms/atalaya'
import PropTypes from 'prop-types'

function DeactivateUserModal({ closeHandler, onComplete, userId }) {
  return (
    <ActionModal
      actionText="Deactivate"
      body="Are you sure you want to remove this User's access?"
      onCancel={closeHandler}
      onAction={() => {
        // todo call api
        console.log('userId', userId)
        onComplete()
      }}
      title="Deactivate User"
      variant="critical"
    />
  )
}

DeactivateUserModal.propTypes = {
  closeHandler: PropTypes.func,
  onComplete: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
}

export { DeactivateUserModal }
