import React, { useContext, useMemo } from 'react'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TaskSchedulingsContextValue {}

export const TaskSchedulingsContext =
  React.createContext<TaskSchedulingsContextValue | null>(null)

export function TaskSchedulingsContextProvider({
  children,
}: {
  children: any
}) {
  const value = useMemo(() => ({}), [])

  return (
    <TaskSchedulingsContext.Provider value={value}>
      {children}
    </TaskSchedulingsContext.Provider>
  )
}

export const useTaskSchedulingsContext = (): TaskSchedulingsContextValue => {
  const ctx = useContext(TaskSchedulingsContext)

  if (!ctx) {
    throw new Error('context is required for useTaskSchedulingsContext()')
  }

  return ctx as TaskSchedulingsContextValue
}
