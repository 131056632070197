import { useEffect, useState } from 'react'
import {
  GetSiteSubscribedUsersOkResponseBody,
  SiteGetSiteSubscribedUsers,
} from '@src/services/api-client'

export function useSiteSubscribedUsers(siteId: string) {
  const [subscribed, setSubscribed] =
    useState<GetSiteSubscribedUsersOkResponseBody>([])

  useEffect(() => {
    setSubscribed([])

    SiteGetSiteSubscribedUsers(siteId).then((res) => {
      setSubscribed(res.data)
    })
  }, [siteId])

  return {
    subscribed,
  }
}
