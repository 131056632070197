import React from 'react'
import { Button, Inline, Text } from '@therms/atalaya'
import PropTypes from 'prop-types'
import { Alerts } from '@src/components/Alerts'
import { ButtonWithActionModal } from '@src/components/ButtonWithActionModal'

function GroupsTableControls({
  clearSelectedRows,
  onClickNewTaskGroup,
  refreshTableData,
  selectedRowsKeys,
  setSearchText,
  searchTextValue,
}) {
  return (
    <Inline alignX="between" alignY="baseline" className="pb-3" width="full">
      <Inline alignY="center" space="xs">
        {selectedRowsKeys?.length > 0 ? (
          <>
            <div className="text-color-subtle text-sm">
              {selectedRowsKeys?.length} Selected
            </div>

            <ButtonWithActionModal
              actionText="Delete"
              body="Are you sure you want to delete these Task Groups?"
              onAction={async () => {
                try {
                  // todo: call api

                  clearSelectedRows()
                  refreshTableData()

                  Alerts.success(`TaskGroups deleted`)
                } catch (err) {
                  Alerts.error(
                    'There was a problem deleting the Task Groups',
                    err.message,
                  )

                  console.log('save() err', err)
                }
              }}
              size="sm"
              subtle
              title="Delete Task Groups"
              variant="critical"
            />
          </>
        ) : (
          <Text
            placeholder="Search..."
            onChangeValue={setSearchText}
            value={searchTextValue}
            search
          />
        )}
      </Inline>

      <div>
        <Button onClick={onClickNewTaskGroup} variant="main">
          New Group
        </Button>
      </div>
    </Inline>
  )
}
GroupsTableControls.propTypes = {
  clearSelectedRows: PropTypes.func,
  onClickNewTaskGroup: PropTypes.func.isRequired,
  refreshTableData: PropTypes.func.isRequired,
  selectedRowsKeys: PropTypes.arrayOf(PropTypes.any),
  setSearchText: PropTypes.func.isRequired,
  searchTextValue: PropTypes.string,
}

export { GroupsTableControls }
