export const TASKS_ROUTES = {
  __ROOT__: '/tasks',
  ACTIVITY_TABLE_TASK_ID_DATE_RANGE:
    '/tasks/taskstabs=activity&acttbltaskId=:acttbltaskId&dateRange=:dateRange',
  EDIT_TASK: '/tasks/edit/:taskId',
  NEW_TASK: '/tasks/new',
  VIEW_TASK: '/tasks/view/:taskId',
}

export const makeTasksActivityRouteForTaskId = (taskId: string) =>
  `/tasks?taskstabs=activity&acttbltaskId=${taskId}`
