import React from 'react'
import { ColorPicker } from '@therms/atalaya'
import { Field, FieldAttributes } from 'formik'

function FormikColorPicker(props: FieldAttributes<Parameters<typeof ColorPicker>[0]>) {
  return (
    <Field name={props.name}>
      {({ field, meta, form }) => (
        <ColorPicker
          {...props}
          {...field}
          error={(meta.touched || !!form.submitCount) && meta.error}
                valid={(meta.touched || !!form.submitCount) && meta.value && !meta.error}
          onChange={({ target: { value } }) =>
            form.setFieldValue(props.name, value)
          }
        />
      )}
    </Field>
  )
}

export { FormikColorPicker }
