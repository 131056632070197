import React, {useCallback, useState} from 'react'
import {ActionModal, Card, Inline} from "@therms/atalaya"
import {
    ActiveSitesViewer
} from "@src/modules/Organization/router/screens/components/Billing/BillingOverview/components/ActiveSitesViewer"
import {
    InvoiceHistoryViewer
} from "@src/modules/Organization/router/screens/components/Billing/BillingOverview/components/InvoiceHistoryViewer"
import {useCurrentOrganization} from "@src/modules/Auth/shared/useCurrentOrganization"
import {ModifySubscription} from "@src/services/api-client"
import {Alerts} from "@src/components/Alerts"


export function BillingOverview() {
    const {shutdown, refresh} = useCurrentOrganization()
    const modifySubscription = useCallback(() => {
        const activate = !!shutdown
        ModifySubscription({activate}).then(refresh).catch(() => {Alerts.error("We are unable to restart your subscription.  Please ensure your payment information is correct then try again.")})
    }, [shutdown])
    const [showModSubscription, setShowModSubscription] = useState(false)
    return (
    <div>
        <Inline alignX="between" width="full">
            <span className="truncate underline text-lg mb-3">Subscription</span>
            <div />
        </Inline>
        <Card className="mb-4">
            <div >
                <div className="grid grid-cols-8 text-color-neutral mb-1">
                    <div>Type: <span className="font-bold">Standard</span></div>
                    <div>Status: <span className="font-bold"> {shutdown ? 'Inactive' : 'Active'}</span></div>
                    {/* eslint-disable-next-line react/jsx-no-script-url,no-script-url */}
                    <div> <a onClick={() => {setShowModSubscription(true)}} className="text-color-info font-bold" href="javascript:void(0)">Modify Subscription</a></div>
                </div>
            </div>
        </Card>
        <Inline alignX="between" width="full">
            <span className="truncate underline text-lg mb-3">Current</span>
            <div/>
        </Inline>
        <ActiveSitesViewer />

        <Inline alignX="between" width="full">
            <span className="truncate underline text-lg mb-3">History</span>
            <div />
        </Inline>
        <InvoiceHistoryViewer />
        {showModSubscription && (
            <ActionModal
                actionText='Confirm'
                body= {shutdown ? 'Your subscription will be restarted.  Billing will resume.' : 'Your service will be terminated.  Billing will be suspended.'}
                onCancel={() => setShowModSubscription(false)}
                onAction={() => {
                    modifySubscription()
                    setShowModSubscription(false)
                }}
                title={shutdown ? 'Activate?' : 'Deactivate?'}
            />)}


    </div>
    )
}
