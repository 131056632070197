import React, { useEffect, useState } from 'react'
import ReactECharts from 'echarts-for-react'
import { useApi } from '@src/hooks/useApi'
import {
  GetRollingStatsReport,
  GetRollingStatsReportRequestBody,
  GetRollingStatsReportResponseBody,
  GetSites,
} from '@src/services/api-client'
import { MissedTasksModal } from '@src/modules/Tasks/shared/MissedTasksModal'
import { useAuth } from '@src/modules/Auth/shared/state'

function OrgSiteStatsChart() {
  const { setCurrentSite } = useAuth()
  const [siteStatsBySiteId, setSiteStatsBySiteId] = useState<
    Record<
      string,
      GetRollingStatsReportResponseBody['rollingSiteStats'][0] & {
        siteName: string
      }
    >
  >({})
  const [showMissedTasksModal, setShowMissedTasksModal] = useState(undefined)

  const { data } = useApi<GetRollingStatsReportResponseBody>(
    GetRollingStatsReport,
    {
      timeframe: 'Rolling30Day',
    } as GetRollingStatsReportRequestBody,
  )

  useEffect(() => {
    if (!data) return

    const siteNamesById: Record<string, string> = {}

    GetSites()
      .then(({ data: sitesData }) => {
        sitesData.sites.forEach((site) => {
          siteNamesById[site.id] = site.name
        })
      })
      .then(() => {
        const _siteStatsBySiteId = data.rollingSiteStats.reduce(
          (acc, siteStats) => {
            acc[siteStats.siteId] = {
              ...siteStats,
              siteName: siteNamesById[siteStats.siteId] || 'Unknown Site',
            }

            return acc
          },
          {},
        )

        setSiteStatsBySiteId(_siteStatsBySiteId)
      })
  }, [data])

  const site = siteStatsBySiteId[showMissedTasksModal?.siteId]

  const clickEvent = (e) => {
    setCurrentSite(e.data.siteId)

    if (e.seriesName === 'Missed') {
      setShowMissedTasksModal({ siteId: e.data.siteId, limit: e.value })
    }
  }

  const onEvents = {
    click: clickEvent,
  }

  return (
    <div>
      <div className="text-lg">30 Day Task Activity</div>

      <ReactECharts
        onEvents={onEvents}
        option={{
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              // Use axis to trigger tooltip
              type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
            },
          },
          legend: {},
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },
          xAxis: {
            type: 'value',
          },
          yAxis: {
            type: 'category',
            // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            data: Object.values(siteStatsBySiteId).map(
              (_site) => _site.siteName,
            ),
          },
          series: [
            {
              name: 'Completed',
              type: 'bar',
              stack: 'total',
              label: {
                show: true,
              },
              emphasis: {
                focus: 'series',
              },
              // data: [1320, 302],
              data: Object.values(siteStatsBySiteId).map(
                (_site) => _site.taskCompletionStatsWeighted.completedTasks,
              ),
            },
            {
              name: 'Missed',
              type: 'bar',
              stack: 'total',
              label: {
                show: true,
              },
              emphasis: {
                focus: 'series',
              },
              // data: [120, 132],
              data: Object.values(siteStatsBySiteId).map((_site) => ({
                siteId: _site.siteId,
                value: _site.taskCompletionStatsWeighted.missedTasks,
              })),
            },
          ],
        }}
      />

      {showMissedTasksModal && (
        <MissedTasksModal
          onClose={() => {
            setShowMissedTasksModal(undefined)
          }}
          site={site}
          siteTaskCompletionsLimit={showMissedTasksModal.limit}
        />
      )}
    </div>
  )
}

OrgSiteStatsChart.defaultProps = {}

export { OrgSiteStatsChart }
