import React from 'react'
import { Loading, TableGroup } from '@therms/atalaya'
import { TableControls } from './components/TableControls.jsx'
import { TableHeadRow } from './components/TableHeadRow.jsx'
import { TableUserRow } from './components/TableUserRow'
import { useUrlState } from '@src/hooks/useUrlState'
import { GetOrganizationUsers } from '@src/services/api-client'
import { useApi } from '@src/hooks/useApi'

function OrganizationUsersTable() {
  const [limit, setLimit] = useUrlState('orgustlimit', 25)
  const [skip, setSkip] = useUrlState('orgustskip', 0)
  const [active, setActive] = useUrlState('orgustactive', undefined)

  const { call, data, isCalling } = useApi(GetOrganizationUsers, {
    limit,
    offset: skip,
  })

  // todo: need to implement pagination

  const users = data?.users

  return (
    <div className="relative">
      <Loading overlay visible={isCalling} />

      <TableGroup
        Controls={TableControls}
        data={users || []}
        keyProp="id"
        HeadRow={TableHeadRow}
        Row={(d) => TableUserRow({ ...d })}
        onSetRecordsPerPage={setLimit}
        onSetSkippedRecords={setSkip}
        recordsPerPage={limit}
        skippedRecords={skip}
        totalRecords={data?.total}
      />
    </div>
  )
}

OrganizationUsersTable.propTypes = {}

export { OrganizationUsersTable }
