const calculatedMap: { [key: string]: string } = {}

/**
 * Returns the contrasting dark or light color based on the passed hex color
 *
 * http://24ways.org/2010/calculating-color-contrast/
 *
 * @param hex {string}
 * @param [darkColor] {string}
 * @param [lightColor]
 * @returns {string}
 */
export function getColorContrast(
  hex?: string | undefined,
  { darkColor = '#00111abf', lightColor = '#fffffff2' } = {},
) {
  if (!hex) return lightColor

  if (calculatedMap[hex]) return calculatedMap[hex]

  let _hex = hex[0] === '#' ? hex.slice(1) : hex

  if (hex.length < 6) {
    _hex = _hex
      .split('')
      .map((value) => value + value)
      .join('')
  }

  const r = parseInt(_hex.substring(0, 2), 16)
  const g = parseInt(_hex.substring(2, 4), 16)
  const b = parseInt(_hex.substring(4, 5), 16)
  const yiq = (r * 299 + g * 587 + b * 114) / 1000

  calculatedMap[hex] = yiq >= 128 ? darkColor : lightColor

  return calculatedMap[hex]
}
