import React, {useCallback, useState} from 'react'
import {ActionModal, FormLayout, Select} from '@therms/atalaya'
import {useOrgTemplates} from '@src/modules/Sites/hooks/UseOrgTemplates'
import {RemoveOrgTemplate, UseOrgTemplate} from '@src/services/api-client'
import {Alerts} from '@src/components/Alerts'
import {useAuth} from '@src/modules/Auth/shared/state'

function TemplateImport(data:{brandIds?:string[]}) {
  const {brandIds} = data
  const [modId, setModId] = useState('')
  const [isAdd, setIsAdd] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const { currentSite } = useAuth()
  const orgTemplates = useOrgTemplates(brandIds)
  const orgTemplateIds = orgTemplates.map((ot) => ot.id)
  const orgTemplatesMap = Object.fromEntries(
    orgTemplates.map((x) => [x.id, x.name]),
  )
  const startingTemplates = (currentSite.orgTemplateUsages || []).map(
    (x) => x.id,
  )
  const [currentTemplates, setCurrentTemplates] = useState(startingTemplates)
  const allTemplates = [...new Set([...orgTemplateIds, ...startingTemplates])]
  const options = allTemplates.map((t) => ({
    label: orgTemplatesMap[t] || 'Previous Template',
    value: t,
  }))

  const templateModTrigger = useCallback(
    (newVals) => {
      if (newVals.length > currentTemplates.length) {
        setIsAdd(true)
        const diff = newVals.filter((x) => !currentTemplates.includes(x))[0]
        setModId(diff)
      } else {
        setIsAdd(false)
        const diff = currentTemplates.filter((x) => !newVals.includes(x))[0]
        setModId(diff)
      }
      setShowModal(true)
    },
    [isAdd, setIsAdd, modId, setModId, currentTemplates],
  )

  const templateMod = useCallback(async () => {
    if (isAdd) {
      try {
        await UseOrgTemplate({
          siteId: currentSite.id,
          orgTemplateId: modId,
        })
        Alerts.success(
          'Import successful. Items can take a couple of minutes to populate.',
          { autoClose: 10000 },
        )
        setCurrentTemplates(currentTemplates.concat([modId]))
        window.setTimeout(() => {
          window.location.reload()
        }, 4000)
      } catch (err) {
        Alerts.error('Unable to import template.', undefined, {
          autoClose: 10000,
        })
      } finally {
        setShowModal(false)
      }
    } else {
      try {
        await RemoveOrgTemplate({
          siteId: currentSite.id,
          orgTemplateId: modId,
        })
        setCurrentTemplates(currentTemplates.filter((x) => x !== modId))
        Alerts.success(
          'Removal successful. Items can take a couple of minutes to disappear.',
          { autoClose: 10000 },
        )
        window.setTimeout(() => {
          window.location.reload()
        }, 4000)
      } catch (err) {
        Alerts.error('Unable to import template.', undefined, {
          autoClose: 10000,
        })
      } finally {
        setShowModal(false)
      }
    }
  }, [currentSite, modId, isAdd, currentTemplates, setCurrentTemplates])

  return (
    <>
      <FormLayout.Divider withBorder />
      <FormLayout dividers="space">
        <FormLayout.Section heading="Import From An Existing Site Template">
          <Select
            onChangeValue={(v) => templateModTrigger(v)}
            options={options}
            value={currentTemplates}
            multi
          />
        </FormLayout.Section>
      </FormLayout>

      {showModal && (
        <ActionModal
          actionText={`${isAdd ? 'Add' : 'Remove'}`}
          body={`All Associated Tasks, Positions, TaskGroups, and Escalations will be ${
            isAdd ? 'added to' : 'removed from'
          } this site.`}
          onCancel={() => setShowModal(false)}
          onAction={templateMod}
          title={`Are you sure you want to ${
            isAdd ? 'Add' : 'Remove'
          } this template?`}
        />
      )}
    </>
  )
}

export { TemplateImport }
