import React from 'react'
import {
  Button,
  DateTimePicker,
  Inline,
  Loading,
  Select,
  TableGroup,
} from '@therms/atalaya'
import { useHistory, useParams } from 'react-router-dom'
import { TASKS_ROUTES } from '@src/modules/Tasks/constants'
import { TaskActivityTableHeadRow } from '../../../../../components/TaskActivityTableHeadRow'
import { TaskActivityTableRow } from '../../../../../components/TaskActivityTableRow'
import { useUrlState } from '@src/hooks/useUrlState'
import { useApiWithSiteId } from '@src/hooks/useApiWithSiteId'
import { TasksLoadTaskCompletions } from '@src/services/api-client'
import dayjs from 'dayjs'
import { SelectTaskGroup } from '@src/modules/Tasks/shared/SelectTaskGroup'
import { SelectTask } from '@src/modules/Tasks/shared/SelectTask'
import { SelectUser } from '@src/modules/Users/shared/SelectUser'
import { useAuth } from '@src/modules/Auth/shared/state'

// todo: add up/down vote for completions by admin
function TaskActivityTable() {
  const history = useHistory()
  const { acttbltaskId, acttbluserId, dateRange } = useParams<{
    acttbltaskId?: string
    acttbluserId?: string
    dateRange?: string
  }>()

  const [limit, setLimit] = useUrlState('tatlimit', 100)
  const [skip, setSkip] = useUrlState('tatskip', 0)
  const [endDate, setEndDate] = useUrlState(
    'tskactEndDate',
    new Date().toISOString(),
  )
  const [startDate, setStartDate] = useUrlState(
    'tskactStartDate',
    dateRange
      ? dayjs().subtract(30, 'day').toISOString()
      : dayjs().subtract(1, 'day').toISOString(),
  )
  const { currentSite } = useAuth()
  const siteId = currentSite.id
  const [status, setStatus] = useUrlState('acttblstatus', 'all')
  const [taskId, setTaskId] = useUrlState('acttbltaskId', acttbltaskId || '')
  const [userId, setUserId] = useUrlState('acttbluserId', acttbluserId || '')
  const [taskGroupId, setTaskGroupId] = useUrlState('acttbltaskGroupId', '')

  const {
    call: refresh,
    data,
    isCalling,
  } = useApiWithSiteId(TasksLoadTaskCompletions, {
    addVerificationFileUrls: true,
    offset: skip,
    limit,
    endDate,
    startDate,
    status: status === 'all' ? '' : status,
    showOnlyMissed: status === 'missed',
    showOnlySkipped: status === 'skipped',
    showOnsiteUsersForMissed: true,
    siteId,
    taskId,
    taskGroupId,
    userId,
  })

  return (
    <div className="relative">
      <Loading overlay visible={isCalling} />

      <Inline alignX="between" width="full">
        <Inline alignY="center" space="xs">
          <DateTimePicker
            label="From"
            onChangeValue={setStartDate}
            value={startDate}
          />
          <DateTimePicker
            label="To"
            onChangeValue={setEndDate}
            value={endDate}
          />
        </Inline>

        <div>
          <Button
            onClick={() => history.push(TASKS_ROUTES.NEW_TASK)}
            variant="main"
          >
            New Task
          </Button>
        </div>
      </Inline>

      <Inline alignY="center" className="mt-xs" space="xs">
        <div className="min-width-150">
          <Select
            isClearable={false}
            multi={false}
            onChangeValue={setStatus}
            options={[
              {
                label: 'All Statuses',
                value: 'all',
              },
              {
                label: 'Complete',
                value: 'complete',
              },
              {
                label: 'Missed',
                value: 'missed',
              },
              {
                label: 'Skipped',
                value: 'skipped',
              },
              {
                label: 'Upcoming',
                value: 'upcoming',
              },
            ]}
            value={status}
          />
        </div>

        <div className="min-width-150">
          <SelectTaskGroup
            isClearable
            placeholder="Group..."
            onChangeValue={setTaskGroupId}
            value={taskGroupId}
          />
        </div>

        <div className="min-width-150">
          <SelectTask
            isClearable
            multi={false}
            placeholder="Task..."
            onChangeValue={setTaskId}
            taskGroupId={taskGroupId}
            value={taskId}
          />
        </div>

        <div className="min-width-150">
          <SelectUser
            isClearable
            multi={false}
            placeholder="User..."
            onChangeValue={setUserId}
            value={userId}
          />
        </div>
      </Inline>

      <TableGroup
        data={data?.completions || []}
        keyProp="id"
        Controls={() => (
          <Inline
            alignX="between"
            alignY="baseline"
            className="pb-3"
            width="full"
          >
            <div />
          </Inline>
        )}
        HeadRow={TaskActivityTableHeadRow}
        // @ts-ignore
        Row={(props) => <TaskActivityTableRow {...props} refresh={refresh} />}
        onSetRecordsPerPage={setLimit}
        onSetSkippedRecords={setSkip}
        recordsPerPage={limit}
        skippedRecords={skip}
        totalRecords={data?.total}
      />
    </div>
  )
}

TaskActivityTable.propTypes = {}

export { TaskActivityTable }
