import {AppRouter} from '@src/AppRouter'
import {AuthWrapper} from '@src/modules/Auth/shared/AuthWrapper'
import React from 'react'
import {SetPassword} from '@src/modules/Auth/components/SetPassword'
import {EmailPreferences} from '@src/modules/Emails/EmailPreferences'
import {useLocation} from 'react-router-dom'
import DeactivationWrapper from "@src/modules/Auth/shared/DeactivationWrapper"

export function UrlRoutesWrapper() {
  const location = useLocation()

  if (location.pathname.includes('/passwordset')) {
    return <SetPassword token={location.pathname.split('/')[2]} />
  }

  if (location.pathname.includes('/emailPreferenceMod')) {
    return <EmailPreferences />
  }

  return (
    <AuthWrapper>
      <DeactivationWrapper>
        <AppRouter />
      </DeactivationWrapper>
    </AuthWrapper>
  )
}
