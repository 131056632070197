import React, { useCallback, useState } from 'react'
import { Card, FormLayout, Loading } from '@therms/atalaya'
import { Formik } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { Alerts } from '@src/components/Alerts'
import { FormikText } from '@src/components/FormikFields/FormikText'
import { useApiWithSiteId } from '@src/hooks/useApiWithSiteId'
import {
  TaskGroupCreateTaskGroup,
  TaskGroupDeleteTaskGroup,
  TaskGroupGetTaskGroup,
  TaskGroupModifyTaskGroup,
} from '@src/services/api-client'
import { FormikTextarea } from '@src/components/FormikFields/FormikTextarea'
import { FormikColorPicker } from '@src/components/FormikFields/FormikColorPicker'
import { delErrorCheck } from '@src/services/api-resp-checks'
import { useAuth } from '@src/modules/Auth/shared/state'

const ValidationSchema = Yup.object().shape({
  name: Yup.string().min(3).required('A name is required'),
})

function TaskGroupForm({ onCancel, onComplete, taskGroupId }) {
  const [isDeleting, setIsDeleting] = useState(false)
  const { currentSite } = useAuth()

  const { data, isCalling } = useApiWithSiteId(
    TaskGroupGetTaskGroup,
    taskGroupId || false,
  )

  const handleDelete = async () => {
    setIsDeleting(true)

    TaskGroupDeleteTaskGroup(taskGroupId).catch(err => delErrorCheck(err)).finally(() => onComplete())
  }

  const save = useCallback(async (values) => {
    try {
      if (taskGroupId) {
        await TaskGroupModifyTaskGroup({
          details: values.details || '',
          name: values.name,
          id: values.id,
          uiData: values.uiData,
        })
      } else {
        await TaskGroupCreateTaskGroup({
          siteId:currentSite.id,
          details: values.details || '',
          name: values.name,
          uiData: values.uiData,
        })
      }

      Alerts.success(`Task Group ${taskGroupId ? 'updated' : 'created'}`)

      onComplete()
    } catch (err) {
      Alerts.error('There was a problem saving the Task Group', err.message)

      console.log('save() err', err)
    }
  }, [])

  const taskGroup = data || {}
  const isTemplate = false

  return (
    <Formik
      enableReinitialize
      initialValues={taskGroup}
      onSubmit={save}
      validationSchema={ValidationSchema}
    >
      {({ isValid, submitForm, isSubmitting }) => (
        <FormLayout dividers="space" space="xs">
          {isCalling && <Loading overlay size="xxl" />}

          {(isDeleting || isSubmitting) && <Loading overlay size="xxl" />}

          {isTemplate && (
            <Card className="bg-info">
              This Group is from a template and cannot be edited at this time.
            </Card>
          )}

          <FormikText label="Name" name="name" />

          <FormikTextarea hint="optional" label="Description" name="details" />

          <FormikColorPicker
            label="Color"
            name="uiData.color"
            subText="Task Group color for visual identification"
          />

          {!isTemplate && (
            <FormLayout.Controls
              disabled={!isValid}
              isSaving={isSubmitting}
              onCancel={onCancel}
              onDelete={taskGroupId && handleDelete}
              onSave={submitForm}
            />
          )}
        </FormLayout>
      )}
    </Formik>
  )
}

TaskGroupForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  taskGroupId: PropTypes.string,
}

export { TaskGroupForm }
