import React from 'react'
import { Stack } from '@therms/atalaya'

interface NoResultsProps {
  text: string
}

function NoResults({ text }: NoResultsProps) {
  return (
    <Stack alignX="center" className="h-full grow opacity-50" paddingTop="xl">
      <span className="text-center text-lg font-medium capitalize">{text}</span>
    </Stack>
  )
}

export { NoResults }
