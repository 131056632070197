import React, { useState } from 'react'
import { Email, FormLayout, Modal } from '@therms/atalaya'
import { ResetPassword } from '@src/services/api-client'
import { Alerts } from '@src/components/Alerts'

interface ResetAdminPasswordModalProps {
  closeHandler: () => void
  onComplete: () => void
}

function ResetPasswordThroughEmailModal({
  closeHandler,
  onComplete,
}: ResetAdminPasswordModalProps) {
  const [email, setEmail] = useState<string>()

  const handleResetPassword = async () => {
    onComplete()

    ResetPassword({ email }).then(() => {
      Alerts.success('Check your email for instructions')
    })
  }

  return (
    <Modal closeHandler={closeHandler} header="Request Reset Password">
      <FormLayout.Section>
        <div>Admin Users are required to reset password through email</div>

        <Email onChangeValue={setEmail} value={email} placeholder="Email..." />
      </FormLayout.Section>

      <FormLayout.Controls
        submitLabel="Send Reset Email"
        onSubmit={handleResetPassword}
        isHorizontal
      />
    </Modal>
  )
}
export { ResetPasswordThroughEmailModal }
