import React, { useEffect, useState } from 'react'
import { Loading, Modal, Tabs } from '@therms/atalaya'
import {
  GetRollingStatsBreakdown,
  GetRollingStatsBreakdownResponseBody,
  GetRollingStatsReportResponseBody,
} from '@src/services/api-client'
import { NoResults } from '@src/components/NoResults'
import ReactECharts from 'echarts-for-react'
import { generatePath, useHistory } from 'react-router-dom'
import { TASKS_ROUTES } from '@src/modules/Tasks/constants'

interface MissedTasksModalProps {
  onClose: () => void
  setMissedTasksForChart?: (missedTasksById: any) => void
  site: GetRollingStatsReportResponseBody['rollingSiteStats'][0]
  siteTaskCompletionsLimit: number
}

function MissedTasksModal({
  onClose,
  setMissedTasksForChart,
  site,
  siteTaskCompletionsLimit,
}: MissedTasksModalProps) {
  const [missedTasks, setMissedTasks] =
    useState<GetRollingStatsBreakdownResponseBody['breakdownByTopTenTasks']>()
  const [missedTaskGroupIds, setMissedTaskGroupIds] =
    useState<GetRollingStatsBreakdownResponseBody['breakdownByTaskGroup']>()
  const history = useHistory()

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (missedTasks && setMissedTasksForChart) {
      setMissedTasksForChart(missedTasks)
    }
  }, [missedTasks])

  const getSiteTasks = () => {
    try {
      if (site) {
        setLoading(true)
        GetRollingStatsBreakdown({
          siteId: site.siteId,
          timeframe: 'Rolling30Day',
          taskStatus: 'missed',
        })
          .then(({ data }) => {
            setMissedTaskGroupIds(data.breakdownByTaskGroup)
            setMissedTasks(data.breakdownByTopTenTasks)
          })
          .finally(() => {
            setLoading(false)
          })
      }
    } catch (err) {
      console.log('err: ', err)
    }

    return site
  }

  useEffect(() => {
    getSiteTasks()
  }, [siteTaskCompletionsLimit, site])

  const clickEvent = (e) => {
    if (e.seriesName === 'Missed') {
      const missedTask = missedTasks.filter((task) => task.taskName === e.name)
      history.push(
        generatePath(TASKS_ROUTES.ACTIVITY_TABLE_TASK_ID_DATE_RANGE, {
          acttbltaskId: missedTask[0].taskId,
          dateRange: 'Rolling30Day',
        }),
      )
    }
  }

  const onEvents = {
    click: clickEvent,
  }

  return (
    <Modal
      closeHandler={() => {
        onClose()
        setMissedTasks(undefined)
        setMissedTaskGroupIds(undefined)
      }}
      escPressClose
      header="Top 10 Missed Tasks and Groups"
    >
      <Tabs initialTabKey="tasks">
        <Tabs.Tab title="Tasks" tabKey="tasks">
          {loading && (
            <div className="flex w-full justify-center">
              <Loading size="base" />
            </div>
          )}

          {!!missedTasks && !!missedTasks.length ? (
            <ReactECharts
              onEvents={onEvents}
              option={{
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    // Use axis to trigger tooltip
                    type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
                  },
                },
                legend: {},
                grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true,
                },
                xAxis: {
                  type: 'value',
                },
                yAxis: {
                  type: 'category',
                  // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                  data: missedTasks.map((task) => task.taskName),
                },
                series: [
                  {
                    color: '#7db765',
                    name: 'Missed',
                    type: 'bar',
                    stack: 'total',
                    label: {
                      show: true,
                    },
                    emphasis: {
                      focus: 'series',
                    },
                    // data: [120, 132],
                    data: missedTasks.map((task) => task.qtyOfOccurrences),
                  },
                ],
              }}
            />
          ) : (
            <NoResults text="No Missed Tasks" />
          )}
        </Tabs.Tab>

        <Tabs.Tab title="Task Groups" tabKey="taskgroups">
          {loading && (
            <div className="flex w-full justify-center">
              <Loading size="base" />
            </div>
          )}

          {!!missedTaskGroupIds && !!missedTaskGroupIds.length ? (
            <ReactECharts
              option={{
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    // Use axis to trigger tooltip
                    type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
                  },
                },
                legend: {},
                grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true,
                },
                xAxis: {
                  type: 'value',
                },
                yAxis: {
                  type: 'category',
                  // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                  data: missedTaskGroupIds.map(
                    (taskGroup) => taskGroup.taskGroupName,
                  ),
                },
                series: [
                  {
                    name: 'Missed',
                    type: 'bar',
                    stack: 'total',
                    label: {
                      show: true,
                    },
                    emphasis: {
                      focus: 'series',
                    },
                    // data: [120, 132],
                    data: missedTaskGroupIds.map(
                      (taskGroup) => taskGroup.qtyOfOccurrences,
                    ),
                  },
                ],
              }}
            />
          ) : (
            <NoResults text="No Missed Task Groups" />
          )}
        </Tabs.Tab>
      </Tabs>
    </Modal>
  )
}

export { MissedTasksModal }
