import React from 'react'
import { TableGroup } from '@therms/atalaya'
// import PropTypes from 'prop-types'

function TableHeadRow() {
  return (
    <TableGroup.HeadRow>
      <TableGroup.HeadCell canSort>Name</TableGroup.HeadCell>

      <TableGroup.HeadCell>Phone</TableGroup.HeadCell>

      <TableGroup.HeadCell>Email</TableGroup.HeadCell>

      <TableGroup.HeadCell canSort>Position</TableGroup.HeadCell>

      <TableGroup.HeadCell canSort>Status</TableGroup.HeadCell>
    </TableGroup.HeadRow>
  )
}

TableHeadRow.propTypes = {}

export { TableHeadRow }
