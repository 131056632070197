import React from 'react'
import { Card, Inline, Stack } from '@therms/atalaya'
import { PositionName } from '@src/modules/Positions/shared/PositionName'
// import { QRCodeSVG } from 'qrcode.react'
// import { getQRValueUserSubscribeToSite } from '@src/utils/qr-code-values'
import { useWallboardContext } from '@src/modules/wallboard/context/WallboardContext'
import { GetSiteSnapshotForAdminResponseBody } from '@src/services/api-client'

interface OnSiteUsersCardProps {
  snapshotForAdminSubscribers: GetSiteSnapshotForAdminResponseBody['subscribed']
}

function OnSiteUsersCard({
  snapshotForAdminSubscribers,
}: OnSiteUsersCardProps) {
  const wallboardCtx = useWallboardContext()

  return (
    <Card className="h-full" padding="xs" space="xs">
      <Inline width="full">
        <Stack className="grow" space="xxs">
          <div className="text-lg">On-Site</div>
          <Stack>
            {!!snapshotForAdminSubscribers?.length &&
              snapshotForAdminSubscribers?.map((subscribedUser) => (
                <div key={subscribedUser.id} className="grid grid-cols-2">
                  <span>{subscribedUser.name}</span>
                  <strong className="text-right">
                    <PositionName
                      id={
                        subscribedUser.sitePositions.filter(
                          (sp) => sp.siteId === wallboardCtx.siteId,
                        )[0]?.positionId || ''
                      }
                    />
                  </strong>
                </div>
              ))}

            {!snapshotForAdminSubscribers?.length && (
              <div className="text-color-neutral-dark text-center">
                No Staff On-Site
              </div>
            )}
          </Stack>
        </Stack>

        {/* <div className="p-xs bg-white"> */}
        {/*   <QRCodeSVG */}
        {/*     height={100} */}
        {/*     width={100} */}
        {/*     value={getQRValueUserSubscribeToSite(wallboardCtx.siteId)} */}
        {/*   /> */}
        {/* </div> */}
      </Inline>
    </Card>
  )
}

export { OnSiteUsersCard }
