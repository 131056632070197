import React from 'react'
import { Checkbox, FormLayout, Select } from '@therms/atalaya'
import { useApiWithSiteId } from '@src/hooks/useApiWithSiteId'
import { GetSites } from '@src/services/api-client'
import { FieldArray, useFormikContext } from 'formik'
import { flatten } from 'lodash-es'
import { FormikCheckbox } from '@src/components/FormikFields/FormikCheckbox'
// import PropTypes from 'prop-types'

// todo: add features API provides site-specific "admin" users (see User object from API)
function UserConfigFormSection() {
  const form = useFormikContext()

  const { data: sitesData } = useApiWithSiteId(GetSites, {
    withPositions: true,
  })

  const sites = sitesData?.sites || []

  const positions = flatten(
    sites
      .filter((site) => form.values.siteIds?.includes(site.id))
      .map((site) => site.positions),
  ).map((position) => ({
    label: position?.title,
    value: position?.id,
  }))

  return (
    <FormLayout dividers="space">
      <FormikCheckbox
        label="Organization Admin Access"
        name="isAdmin"
        subText="Admin access allows this User to access this Admin site and manage configuration & settings"
      />

      <FormLayout.Divider withBorder />

      <FieldArray
        name="siteIds"
        render={(arrayHelpers) => (
          <FormLayout.Section
            heading="Sites Access"
            horizontal
            subTitle="Select the Sites this User has access to"
          >
            {sites.map((site) => (
              <div>
                <Checkbox
                  active={form.values?.siteIds?.includes?.(site.id)}
                  label={site.name}
                  name="sites"
                  onChangeValue={(checked) => {
                    if (!checked) {
                      arrayHelpers.remove(form.values.siteIds.indexOf(site.id))
                    } else {
                      arrayHelpers.push(site.id)
                    }
                  }}
                />
              </div>
            ))}
          </FormLayout.Section>
        )}
      />

      <FieldArray
        name="siteIdsRecReportsFrom"
        render={(arrayHelpers) => (
          <FormLayout.Section
            heading="Daily Site Task Reports Summary"
            horizontal
            subTitle="Select the Sites that this User should receive a dail email summarizing the missed Tasks for"
          >
            {sites.map((site) => (
              <div>
                <Checkbox
                  active={form.values?.siteIdsRecReportsFrom?.includes?.(
                    site.id,
                  )}
                  label={site.name}
                  name="sitesRecReports"
                  onChangeValue={(checked) => {
                    if (!checked) {
                      arrayHelpers.remove(
                        form.values.siteIdsRecReportsFrom.indexOf(site.id),
                      )
                    } else {
                      arrayHelpers.push(site.id)
                    }
                  }}
                />
              </div>
            ))}
          </FormLayout.Section>
        )}
      />

      <FormLayout.Divider withBorder />

      <Select
        label="Position"
        multi={false}
        onChangeValue={(value) => form.setFieldValue('positionId', value)}
        options={positions}
        name="positionId"
        value={form.values?.positionId}
        subText={
          form.values?.siteIds?.length > 0 &&
          !positions.length && (
            <span className="text-color-caution">
              The selected Site is configured incorrectly. You must add
              Positions to the selected Site before creating a new User.
            </span>
          )
        }
      />
    </FormLayout>
  )
}

UserConfigFormSection.propTypes = {}

export { UserConfigFormSection }
