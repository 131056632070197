import React, { useCallback, useState } from 'react'
import {
  Image,
  Button,
  Email,
  Loading,
  Password,
  Stack,
  Inline,
} from '@therms/atalaya'
import { LoginUser } from '@src/services/api-client'
// @ts-ignore
import logo from '@src/assets/logo.png'
import { Alerts } from '@src/components/Alerts'
import { SuperuserLogin } from './SuperuserLogin'
import { ResetPasswordThroughEmailModal } from '@src/modules/Auth/shared/Users/ResetPasswordThroughEmailModal'

interface LoginProps {
  onComplete: (jwt: string) => void
}

function Login({ onComplete }: LoginProps) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState()
  const [loading, setLoading] = useState(false)
  const [superuserLogin, setSuperuserLogin] = useState(false)
  const [logoFlipCount, setLogoFlipCount] = useState(0)
  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false)

  const handleLogoClick = useCallback(() => {
    setLogoFlipCount(logoFlipCount + 1)
    if (logoFlipCount === 4) setSuperuserLogin(!superuserLogin)
    setTimeout(() => {
      setLogoFlipCount(0)
    }, 3000)
  }, [logoFlipCount, superuserLogin, setSuperuserLogin, setLogoFlipCount])

  const handleLogin = async () => {
    setLoading(true)
    try {
      const {
        data: { userId, sessionKey, token },
      } = await LoginUser({ email, password })

      console.log('Login success', { userId, sessionKey, token })

      onComplete(token)
    } catch (err) {
      console.log('Login err', err)
      Alerts.error('Unable to authenticate try again')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="flex justify-center">
      {loading && <Loading overlay />}

      <div className="w-full md:w-72">
        <Inline className="mb-xl" alignX="center" width="full">
          <Image src={logo} width={200} onClick={handleLogoClick} />
        </Inline>

        <Stack alignX="stretch" space="sm" width="full">
          {superuserLogin ? (
            <SuperuserLogin onComplete={onComplete} setLoading={setLoading} />
          ) : (
            <>
              <Email
                placeholder="Email"
                onChangeValue={setEmail}
                value={email}
              />

              <Password
                placeholder="Password"
                onChangeValue={setPassword}
                value={password}
              />

              <Button onClick={handleLogin} fullWidth variant="main">
                Login
              </Button>
            </>
          )}
        </Stack>

        <Inline alignX="center" className="mt-sm" width="full">
          <Button
            onClick={() => setShowPasswordResetModal(true)}
            variant="neutral"
            subtle
          >
            Reset Password
          </Button>
        </Inline>
      </div>

      {showPasswordResetModal && (
        <ResetPasswordThroughEmailModal
          closeHandler={() => setShowPasswordResetModal(false)}
          onComplete={() => setShowPasswordResetModal(false)}
        />
      )}
    </div>
  )
}

Login.defaultProps = {}

export { Login }
