import { UserGetUserOkResponseBody, SiteAdminPriv, SitePosition } from "../../../services/api-client"




export interface SiteUser {
    active?: boolean
    id: string
    isAdmin?: boolean
    email: string
    name?: string
    phone?: string
    siteAdmin:boolean
    positionId:string
    receivingReport: boolean
    siteAdminPrivs:SiteAdminPriv[]
    sitePositions:SitePosition[]
    siteIdsRecReportsFrom?: string[]
  }



export const genSiteUser = (userData:UserGetUserOkResponseBody, currentSiteId:string):SiteUser =>  ({
        ...userData,
        siteAdmin: ((userData.siteAdminPrivs || []).filter(priv => priv.siteId === currentSiteId).length > 0),
        positionId: (userData.sitePositions.filter(p => p.siteId === currentSiteId)[0]?.positionId || ''),
        receivingReport: (userData.siteIdsRecReportsFrom || []).includes(currentSiteId)
    })
