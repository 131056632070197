import React, { useRef, useState } from 'react'
import { QRCodeSVG } from 'qrcode.react'
import { Button, HTMLSelect, Inline, Stack } from '@therms/atalaya'
import ReactToPrint from 'react-to-print'
import { TaskGroupName } from '@src/modules/Tasks/shared/TaskGroupName'
import { Task } from '@src/services/api-client'
import { getQRValueTaskId } from '@src/utils/qr-code-values'

interface PrintTaskQRCodeProps {
  task: Partial<Task>
}

function PrintTaskQRCode({ task }: PrintTaskQRCodeProps) {
  const [verificationSecretSize, setVerificationSecretSize] = useState('175')
  const qrCodeRef = useRef()

  return (
    <>
      <div style={{ display: 'none' }}>
        <div ref={qrCodeRef}>
          <div className="m-base">
            <div>
              {!!task.taskGroupIds?.length && (
                <>
                  <TaskGroupName id={task.taskGroupIds[0]} />{' '}
                </>
              )}
            </div>

              <div className="p-xs bg-white">
                  <QRCodeSVG
                      height={verificationSecretSize}
                      width={verificationSecretSize}
                      value={getQRValueTaskId(task.id)}
                  />
              </div>

            <div>{task.title || 'Task Verification'}</div>
          </div>
        </div>
      </div>

      <Inline alignX="between" width="full">
          <div className="p-xs bg-white">
        <QRCodeSVG value={getQRValueTaskId(task.id)} />
          </div>

        <Stack className="flex-grow ml-xxl" space="xl">
          <HTMLSelect
            label="Print Size"
            options={[
              { label: 'Small', value: '75' },
              { label: 'Medium', value: '175' },
              { label: 'Large', value: '300' },
              { label: 'X-Large', value: '500' },
            ]}
            onChangeValue={setVerificationSecretSize}
            value={verificationSecretSize}
          />

          {/* @ts-ignore */}
          <ReactToPrint
            trigger={() => <Button variant="main">Print QR Code</Button>}
            content={() => qrCodeRef.current}
          />
        </Stack>
      </Inline>
    </>
  )
}

PrintTaskQRCode.defaultProps = {}

export { PrintTaskQRCode }
