import React, { useState } from 'react'
import { Loading, TableGroup, Modal } from '@therms/atalaya'
import { PositionOptionsTableControls } from './TableControls.jsx'
import { PositionOptionsTableHeadRow } from './TableHeadRow.jsx'
import { PositionOptionsTableRow } from './TableRow.jsx'
import { PositionForm } from './PositionForm.jsx'
import { useUrlState } from '@src/hooks/useUrlState'
import { GetPositions } from '@src/services/api-client'
import { useApiWithSiteId } from '@src/hooks/useApiWithSiteId'

function PositionsTable() {
  const [showFormModal, setShowFormModal] = useState(false)
  const [searchTextValue, setSearchText] = useUrlState('possrch')

  const [limit, setLimit] = useUrlState('ptlimit', 25)
  const [skip, setSkip] = useUrlState('ptskip', 0)

  const { call, data, isCalling } = useApiWithSiteId(GetPositions, {
    offset: skip,
    limit,
  })

  return (
    <div className="relative">
      <Loading overlay visible={isCalling && !data?.positions?.length} />

      <TableGroup
        data={data?.positions || []}
        keyProp="id"
        Controls={
          <PositionOptionsTableControls
            onClickNewPosition={() => setShowFormModal(true)}
          />
        }
        HeadRow={PositionOptionsTableHeadRow}
        Row={PositionOptionsTableRow}
        onClickRow={({ rowData }) => setShowFormModal(rowData.id)}
        onSetRecordsPerPage={setLimit}
        onSetSkippedRecords={setSkip}
        recordsPerPage={limit}
        skippedRecords={skip}
        totalRecords={data?.total}
      />

      {showFormModal && (
        <Modal closeHandler={() => setShowFormModal(null)} header="Position">
          <PositionForm
            onCancel={() => setShowFormModal(null)}
            onComplete={() => {
              setShowFormModal(null)

              call()
            }}
            positionId={
              typeof showFormModal !== 'boolean' ? showFormModal : undefined
            }
          />
        </Modal>
      )}
    </div>
  )
}

PositionsTable.propTypes = {}

export { PositionsTable }
