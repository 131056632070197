import React, { useEffect } from 'react'
import { Card, Image, Inline, Stack } from '@therms/atalaya'
// @ts-ignore
import logo from '@src/assets/logo.png'
import { QRCodeSVG } from 'qrcode.react'
import { useWallboardContext } from '@src/modules/wallboard/context/WallboardContext'
import { CheckForBotToken } from '@src/services/api-client'
import { Alerts } from '@src/components/Alerts'
import { getQRValueSetupDevice } from '@src/utils/qr-code-values'

function WallboardSetup() {
  const { deviceId, setJwt, setSiteId, siteId } = useWallboardContext()

  useEffect(() => {
    const i = setInterval(() => {
      CheckForBotToken(deviceId)
        .then(({ data }) => {
          if (data.token && data.siteId) {
            setSiteId(data.siteId)
            setJwt(data.token)

            Alerts.success('Activating Device...')
          }
        })
        .catch(() => null)
    }, 5000)

    return () => clearInterval(i)
  }, [])

  return (
    <Stack
      alignX="center"
      alignY="center"
      className="p-base"
      height="full"
      space="xl"
      width="full"
    >
      <Card className="text-center" color="main">
        <Inline
          alignX="center"
          alignY="center"
          className="my-base text-xl text-color-main"
          width="full"
        >
          <Image src={logo} width="10vh" />
          <div className="mx-base" />
          Wallboard Setup
        </Inline>

        <Stack alignX="center" width="full">
          <div className="my-base" style={{ maxWidth: '50vw' }}>
            Open the mobile app, go to{' '}
            <strong className="text-color-info-light">Settings</strong>, select{' '}
            <strong className="text-color-info-light">
              Setup Wallboard Device
            </strong>{' '}
            then scan the QR code
          </div>

          <div className="p-xs bg-white">
          <QRCodeSVG
            height="30vh"
            width="30vh"
            value={getQRValueSetupDevice(deviceId, siteId)}
          />
          </div>
        </Stack>

        <Inline alignX="center" alignY="center" width="full">
          <div className="border grow border-neutral ml-xxl mr-base" />
          <em className="text-color-neutral">OR</em>
          <div className="border grow border-neutral mr-xxl ml-base" />
        </Inline>

        <Stack alignX="center" width="full">
          <div className="mb-sm" style={{ maxWidth: '50vw' }}>
            Open the Admin website (www.quicktask.us), go to{' '}
            <strong className="text-color-info-light">Sites</strong>, select
            this Site, select{' '}
            <strong className="text-color-info-light">
              Setup Wallboard Device
            </strong>{' '}
            then enter the code:
          </div>

          <div>
            <div className="text-xl">
              <span className="text-color-neutral">Code</span>{' '}
              <span className="text-color-main">{deviceId}</span>
            </div>
          </div>
        </Stack>
      </Card>
    </Stack>
  )
}

WallboardSetup.defaultProps = {}

export { WallboardSetup }
