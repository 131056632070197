import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  PositionGetPosition,
  PositionGetPositionOkResponseBody,
} from '@src/services/api-client'

function PositionName({ id }) {
  const [data, setData] = useState<PositionGetPositionOkResponseBody>()

  useEffect(() => {
    if (!id) {
      setData(undefined)
      return
    }

    PositionGetPosition(id, {
      cache: {
        ttl: 1000 * 30,
      },
    }).then((res) => {
      setData(res.data)
    })
  }, [id])

  return <span>{data?.title}</span>
}

PositionName.propTypes = {
  id: PropTypes.string.isRequired,
}

export { PositionName }
