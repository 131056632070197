import React from 'react'
import PropTypes from 'prop-types'
import { CopyText, TableGroup } from '@therms/atalaya'
import { PositionNames } from '@src/modules/Positions/shared/PositionNames'
import { SitePosition } from '@src/services/api-client'

function TableUserRow({ rowData, rowKey }) {
  const userPositionIds: string[] = rowData?.sitePositions.map(
    (position: SitePosition) => position.positionId,
  )

  return (
    <TableGroup.Row className="hover:bg-surface" rowKey={rowKey}>
      <TableGroup.Cell>
        <div>{rowData.name}</div>
      </TableGroup.Cell>

      <TableGroup.Cell ellipse>
        <div>{rowData.phone}</div>
      </TableGroup.Cell>

      <TableGroup.Cell ellipse>
        <div className="text-color-info">
          <CopyText displayValue value={rowData.email} />
        </div>
      </TableGroup.Cell>

      <TableGroup.Cell ellipse>
        {!!userPositionIds.length && (
          <PositionNames sitePositions={userPositionIds} />
        )}
      </TableGroup.Cell>

      <TableGroup.Cell>
        {!!rowData.isAdmin || !!rowData?.siteAdminPrivs?.length ? (
          <span className="text-color-positive">Admin</span>
        ) : (
          <span className="text-color-caution">User</span>
        )}
      </TableGroup.Cell>
    </TableGroup.Row>
  )
}

TableUserRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  rowKey: PropTypes.any.isRequired,
}

export { TableUserRow }
