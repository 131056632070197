import React, { useMemo } from 'react'
import { UserName } from '@src/modules/Users/shared/UserName'

function UserNames({ ids }: { ids: string[] }) {
  const names = useMemo(() => {
    if (!ids || !ids.length) {
      return []
    }

    return ids.map((id, i) => (
      <>
        <UserName id={id} />
        {i + 1 !== ids.length ? ', ' : ''}
      </>
    ))
  }, [ids])

  return <span>{names}</span>
}

export { UserNames }
