import React, { useEffect, useState } from 'react'
import { GetComments, GetCommentsResponseBody } from '@src/services/api-client'
import { UserName } from '@src/modules/Users/shared/UserName'
import { Button, Loading } from '@therms/atalaya'
import { CommentImageLightbox } from './CommentImageLightbox'

interface CommentsProps {
  commentIds?: string[]
  siteId: string
}

function Comments({ commentIds, siteId }: CommentsProps) {
  const [comments, setComments] = useState<GetCommentsResponseBody>([])
  const [showImages, setShowImages] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (commentIds.length && siteId) {
      setLoading(true)
      GetComments({ commentIds, siteId })
        .then((data) => setComments(data.data))
        .finally(() => setLoading(false))
    }
  }, [commentIds])

  return (
    <div className="relative">
      {loading && <Loading overlay />}

      {comments.map((comment) => (
        <div>
          <div className="mb-base">{comment.userNote}</div>
          <em className="text-sm text-neutral">
            <UserName id={comment.userId} />
          </em>
          {comment.fileUrls.length > 0 &&(<div className="mt-1"><Button onClick={()=>{setShowImages(true)}} size='xs'>Click for Images</Button></div>)}
        </div>
      ))}

      {!commentIds.length && <span className="text-neutral">No comments</span>}
      {showImages && (<CommentImageLightbox onClosePress={()=>{setShowImages(false)}} siteId={siteId} commentIds={commentIds} />) }
    </div>
  )
}

Comments.defaultProps = {}

export { Comments }
