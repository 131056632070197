import { Card, DateTimeRangePicker, Stack, Tooltip } from '@therms/atalaya'
import { FiInfo } from 'react-icons/fi'
import React, { useCallback, useEffect, useState } from 'react'
import {
  GetConductReport,
  GetConductReportResponseBody,
} from '@src/services/api-client'
import { UserName } from '@src/modules/Users/shared/UserName'
import ReactECharts from 'echarts-for-react'

interface ConductProps {
  dateRange: { start: string; end: string }
  siteId: string
}

function UserConductStats({ dateRange, siteId }: ConductProps) {
  const [conductReport, setConductReport] =
    useState<GetConductReportResponseBody>()

  useEffect(() => {
    GetConductReport({
      siteId,
      startDate: dateRange.start,
      endDate: dateRange.end,
    }).then(({ data }) => {
      setConductReport({
        ...data,
        userConduct: data.userConduct.sort((a, b) =>
          a.weightedStats.normalizedComposite >
          b.weightedStats.normalizedComposite
            ? -1
            : 1,
        ),
      })
    })
  }, [dateRange, siteId])

  const getChartOptions = useCallback(
    () => ({
      legend: {
        data: ['Complete', 'Incomplete'],
        textStyle: {
          color: 'white', // Set legend label color to white
        },
      },
      series: [
        {
          type: 'pie',
          label: {
            show: true,
            position: 'inside',
            formatter(params) {
              return params.value !== 0 ? `${params.value} %` : ''
            },
          },
          emphasis: {
            label: {
              show: true,
              fontWeight: 'bold', // Highlight labels on hover
            },
          },
          data: [
            {
              value: Math.round(conductReport.taskCompletionPct || 0),
              name: 'Complete',
              itemStyle: { color: '#4075BF' },
            },
            {
              value: Math.round(100 - (conductReport.taskCompletionPct || 0)),
              name: 'Incomplete',
              itemStyle: { color: '#BD3C28' },
            },
          ],
        },
      ],
    }),
    [conductReport],
  )

  return (
    <Stack space="base">
      <div className="text-lg">User Metrics</div>

      <Card>
        {conductReport && <ReactECharts option={getChartOptions()} />}
      </Card>

      <Card>
        <div className="mb-base">
          <div className="grid grid-cols-8 text-color-neutral">
            <div>User</div>
            <div>Performance</div>
            <div>
              Score{' '}
              <Tooltip tooltip="Scores are 0-100 where 0=worst & 100=best">
                <FiInfo className="text-color-info" />
              </Tooltip>
            </div>
            <div>Completed</div>
            <div>Due While On-Site</div>
            <div>Skipped</div>
            <div>Missed</div>
            <div>Evaluation Points</div>
          </div>

          <div className="grid grid-cols-8 font-bold italic text-color-neutral">
            <div>All User Averages</div>

            <div />

            <div>
              {conductReport?.averageConduct.weightedStats.normalizedComposite.toFixed(
                0,
              )}
            </div>

            <div>
              {conductReport?.averageConduct.weightedStats.tasksCompleted.toFixed(
                0,
              )}
            </div>

            <div>
              {conductReport?.averageConduct.weightedStats.tasksDueWhileOnSite.toFixed(
                0,
              )}
            </div>

            <div>
              {conductReport?.averageConduct.weightedStats.tasksSkipped.toFixed(
                0,
              )}
            </div>

            <div>
              {conductReport?.averageConduct.weightedStats.tasksMissed.toFixed(
                0,
              )}
            </div>
            <div>
              {(conductReport?.averageConduct.weightedStats.positiveEvals ||
                0) -
                (conductReport?.averageConduct.weightedStats.negativeEvals ||
                  0)}
            </div>
          </div>

          {conductReport?.userConduct?.map((userConduct) => {
            let plainTxtConductGrade: React.ReactNode
            let bgClassName: string

            const userComposite = userConduct.weightedStats.normalizedComposite

            if (userComposite < 20) {
              plainTxtConductGrade = (
                <span className="font-bold text-color-critical">Poor</span>
              )
              bgClassName = 'bg-critical-faded'
            } else if (userComposite < 40) {
              plainTxtConductGrade = (
                <span className="text-color-caution">Below Average</span>
              )
              bgClassName = 'bg-caution-faded'
            } else if (userComposite < 60) {
              plainTxtConductGrade = (
                <span className="text-color-main">Average</span>
              )
              bgClassName = 'bg-main-faded'
            } else if (userComposite < 80) {
              plainTxtConductGrade = (
                <span className="text-color-info">Above Average</span>
              )
              bgClassName = 'bg-info-faded'
            } else {
              plainTxtConductGrade = (
                <span className="font-bold text-color-positive">Excellent</span>
              )
              bgClassName = 'bg-positive-faded'
            }

            return (
              <div
                className={`mb-xxs p-xxs rounded grid grid-cols-8 ${bgClassName}`}
              >
                <div>
                  <UserName id={userConduct.userId} />
                </div>

                <div>
                  <small>{plainTxtConductGrade}</small>
                </div>

                <div
                  className={
                    userConduct.weightedStats.normalizedComposite >=
                    conductReport?.averageConduct.weightedStats
                      .normalizedComposite
                      ? 'text-color-positive'
                      : 'text-color-critical'
                  }
                >
                  {userConduct.weightedStats.normalizedComposite}{' '}
                </div>

                <div>{userConduct.weightedStats.tasksCompleted}</div>

                <div>{userConduct.weightedStats.tasksDueWhileOnSite}</div>

                <div>{userConduct.weightedStats.tasksSkipped}</div>

                <div>{userConduct.weightedStats.tasksMissed}</div>
                <div>
                  {userConduct.weightedStats.positiveEvals -
                    userConduct.weightedStats.negativeEvals}
                </div>
              </div>
            )
          })}
        </div>
      </Card>
    </Stack>
  )
}

UserConductStats.defaultProps = {}

export { UserConductStats }
