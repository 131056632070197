import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { getWidthBreakpoint } from '../utils/breakpoints'
import { useWindowSize } from '../hooks/useWindowSize'

type ContainerDimensionsContextValue = {
  currentBreakpoint: 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
  height: number
  /* This number is the height that the container would have to be if it wanted to go to the bottom of the screen */
  heightForBottomOfScreen: number
  width: number
}

const ContainerDimensionsContext = React.createContext<
  ContainerDimensionsContextValue | undefined
>(undefined)

export function ContainerDimensionsContextProvider({
  children,
}: {
  children: any
}) {
  const { height: windowHeight } = useWindowSize()
  const containerRef = useRef<HTMLDivElement>(null)
  const [dimensions, setDimensions] = useState<{
    currentBreakpoint: 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
    height: number
    heightForBottomOfScreen: number
    width: number
  }>({
    currentBreakpoint: 'sm',
    height: 0,
    heightForBottomOfScreen: 0,
    width: 0,
  })

  useLayoutEffect(() => {
    if (containerRef?.current?.clientWidth) {
      setDimensions({
        currentBreakpoint: getWidthBreakpoint(
          containerRef?.current?.clientWidth,
        ),
        height: containerRef?.current?.clientHeight ?? 0,
        heightForBottomOfScreen:
          windowHeight -
          (containerRef?.current?.getBoundingClientRect()?.y ?? 0),
        width: containerRef?.current?.clientWidth ?? 0,
      })
    }
  }, [windowHeight])

  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        if (containerRef?.current?.clientWidth) {
          const height = containerRef?.current?.clientHeight
          const width = containerRef?.current?.clientWidth

          if (dimensions.height !== height || dimensions.width !== width) {
            setDimensions({
              currentBreakpoint: getWidthBreakpoint(
                containerRef?.current?.clientWidth,
              ),
              height,
              heightForBottomOfScreen:
                windowHeight -
                (containerRef?.current?.getBoundingClientRect()?.y ?? 0),
              width,
            })
          }
        }
      })

      resizeObserver.observe(containerRef.current)

      return () => resizeObserver?.disconnect()
    }

    return undefined
  }, [containerRef.current, windowHeight])

  return (
    <div ref={containerRef}>
      <ContainerDimensionsContext.Provider
        /* eslint-disable-next-line react/jsx-no-constructed-context-values */
        value={{
          currentBreakpoint: dimensions.currentBreakpoint,
          height: dimensions.height,
          heightForBottomOfScreen: dimensions.heightForBottomOfScreen,
          width: dimensions.width,
        }}
      >
        {children}
      </ContainerDimensionsContext.Provider>
    </div>
  )
}

export function useContainerDimensionsContext() {
  const context = React.useContext(ContainerDimensionsContext)

  return context as ContainerDimensionsContextValue
}
