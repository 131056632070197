import {
  GetOrganization,
  GetSites,
  GetSitesOkResponseBody,
  Logout,
  OrganizationGetOrganizationOkResponseBody,
  SiteGetSiteOkResponseBody,
  UserGetSelf,
  UserGetUserOkResponseBody,
} from '@src/services/api-client'
import { proxy } from 'valtio'
import { useSnapshot } from 'valtio/react'
import * as Sentry from '@sentry/react'
import { genSiteUser, SiteUser } from './siteUser'

export type AuthenticatedState = {
  allSites: GetSitesOkResponseBody['sites']
  currentOrganization: OrganizationGetOrganizationOkResponseBody
  currentSite: SiteGetSiteOkResponseBody
  currentUser: UserGetUserOkResponseBody
  currentSiteUser: SiteUser
  jwt: string
}

export const authState = proxy<Partial<AuthenticatedState>>({
  allSites: [],
  currentOrganization: undefined,
  currentSite: undefined,
  currentUser: undefined,
  currentSiteUser: undefined,
  jwt: undefined,
})

export const useAuth = (): AuthenticatedState & {
  logout: () => Promise<void>
  setCurrentSite(site: AuthenticatedState['currentSite'] | undefined)
} => {
  const snapshot = useSnapshot(authState)

  if (!snapshot.currentUser)
    throw new Error(
      'useAuth can only be used inside components that are displayed as children of <AuthWrapper />',
    )

  return {
    ...(snapshot as AuthenticatedState),

    logout: async () => {
      authState.currentUser = undefined
      authState.jwt = undefined
      await Logout()
      localStorage.setItem('jwt', '')
    },

    setCurrentSite: (site: SiteGetSiteOkResponseBody | string) => {
      let _site: SiteGetSiteOkResponseBody

      if (typeof site === 'string') {
        _site = authState.allSites.find((s) => s.id === site)
      } else {
        _site = site
      }

      localStorage.setItem('currentSite', JSON.stringify(_site))

      authState.currentSite = _site
      authState.currentSiteUser = _site
        ? genSiteUser(authState.currentUser, _site.id)
        : undefined
    },
  }
}

export async function fetchAndSetAuthState() {
  await GetOrganization().then((res) => {
    authState.currentOrganization = res.data
  })

  await GetSites().then((res) => {
    authState.allSites = res.data.sites
  })

  await UserGetSelf().then((res) => {
    authState.currentUser = res.data

    try {
      const currentSiteId = JSON.parse(
        localStorage.getItem('currentSite') || '{}',
      )?.id

      const currentSite =
        (authState.allSites || []).find((site) => currentSiteId === site.id) ||
        authState.allSites[0]

      if (!currentSite) {
        Sentry.captureException(
          new Error(
            'AuthWrapper UserGetSelf() call returned a User with no sites',
          ),
          {
            extra: {
              user: JSON.stringify(res.data),
            },
          },
        )
      }

      authState.currentSite = currentSite
      authState.currentSiteUser = currentSite
        ? genSiteUser(authState.currentUser, currentSite.id)
        : undefined
    } catch (err) {
      Sentry.captureException(err)

      console.error('AuthWrapper error fetching user', err)
      console.warn('Clearing localStorage & resetting authState')

      authState.allSites = undefined
      authState.currentUser = undefined
      authState.currentOrganization = undefined
      authState.jwt = undefined

      localStorage.clear()
    }
  })
}
