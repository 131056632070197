import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useApi } from '@src/hooks/useApi'
import {
  GetEmailPreferences,
  ModifyEmailPreferences,
} from '@src/services/api-client'
import { Button, Checkbox, Image } from '@therms/atalaya'
import logo from '@src/assets/logo.png'
import { Alerts } from '@src/components/Alerts'

function EmailPreferences() {
  const location = useLocation()
  const [emailPreference, setEmailPreference] = useState(false)

  const id = location.pathname.split('/')[2]

  const { call, data } = useApi(GetEmailPreferences, { id })

  const handleModifyEmailPreferences = async () => {
    try {
      await ModifyEmailPreferences({
        id,
        preferences: {
          receiveEmails: data?.preferences.receiveEmails
            ? !emailPreference
            : emailPreference,
        },
      })

      Alerts.success('You have unsubscribed from receiving emails')
      call()
    } catch (err) {
      Alerts.error(
        'There was a problem modifying email preferences',
        err.message,
      )

      console.log('handleModifyEmailPreferences() err', err)
    }
  }

  return (
    <div className="grid grid-cols-4 grid-rows-3 h-screen content-center">
      <div className="flex-col w-full h-full col-start-2 col-span-2 mt-xxl">
        <div className="flex justify-center">
          <Image src={logo} width={200} />
        </div>

        <div className="flex-col w-full h-full col-span-2 align-middle mt-xxl">
          <div className="w-full flex-col justify-center align-middle text-md font-bold text-center">
            <div className="text-lg font-bold pb-lg">
              Edit Email Preferences
            </div>
            <Checkbox
              label={
                data?.preferences.receiveEmails
                  ? 'Unsubscribe from receiving emails from QuickTask.'
                  : 'Subscribe to receiving emails from QuickTask.'
              }
              active={emailPreference}
              onChangeValue={() => setEmailPreference(!emailPreference)}
            />
          </div>

          <div className="flex w-full justify-center mt-xl">
            <Button
              disabled={!emailPreference}
              onClick={handleModifyEmailPreferences}
              variant="main"
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export { EmailPreferences }
