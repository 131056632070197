/**
 * This QR Code value is used by the mobile client to show the user a Subscribe/Unsubscribe prompt.
 */
export const getQRValueUserSubscribeToSite = (siteId: string) =>
  `https://scannable.quicktask.us?action=subscribe-site&siteId=${siteId}`

export const getQRValueSetupDevice = (deviceId: string, siteId: string) =>
  `https://scannable.quicktask.us?action=setup-device&deviceId=${deviceId}&siteId=${siteId}`

export const getQRValueTaskId = (taskId: string) =>
  `https://scannable.quicktask.us?action=task&taskId=${taskId}`

/**
 * This method is used to generate a value that is to be displayed as a QR code value
 * that and should be scanned with the mobile app to decode and trigger the user to write to an NFC
 * tag.
 * @param nfcValue
 */
export const getQRValueToWriteNFC = (nfcValue: string): string =>
  `https://scannable.quicktask.us?action=write-nfc&nfcValue=${nfcValue}`
