import React from 'react'
import { TableGroup } from '@therms/atalaya'
// import PropTypes from 'prop-types'

function EscalationOccurrencesTableHeadRow() {
  return (
    <TableGroup.HeadRow>
      <TableGroup.HeadCell>Date</TableGroup.HeadCell>
      <TableGroup.HeadCell>Escalation</TableGroup.HeadCell>
      <TableGroup.HeadCell>Trigger</TableGroup.HeadCell>
      <TableGroup.HeadCell />
    </TableGroup.HeadRow>
  )
}

EscalationOccurrencesTableHeadRow.propTypes = {}

export { EscalationOccurrencesTableHeadRow }
