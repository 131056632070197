import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { EscalationsIndex } from '@src/modules/Escalations/router/screens/Index'

function EscalationsRoutes() {
  return (
    <Switch>
      <Route component={EscalationsIndex} />
    </Switch>
  )
}

export { EscalationsRoutes }
