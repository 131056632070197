import React from 'react'
import { Button, Inline } from '@therms/atalaya'
import PropTypes from 'prop-types'
import { MdAdd } from 'react-icons/md'

function PositionOptionsTableControls({ onClickNewPosition }) {
  return (
    <Inline alignX="between" alignY="baseline" className="pb-3" width="full">
      <div />

      <div>
        <Button onClick={onClickNewPosition} variant="main">
          <MdAdd /> New Position
        </Button>
      </div>
    </Inline>
  )
}
PositionOptionsTableControls.propTypes = {
  onClickNewPosition: PropTypes.func.isRequired,
}

export { PositionOptionsTableControls }
