import React from 'react'
import { useWallboardContext } from '@src/modules/wallboard/context/WallboardContext'
import { useWeather } from '@src/modules/Weather/hooks/useWeather'
import WeatherIcon from 'react-icons-weather'
import { Inline, Stack } from '@therms/atalaya'

function Weather() {
  const wallboardCtx = useWallboardContext()
  const weather = useWeather(
    wallboardCtx.site.geolocation?.lng && {
      lng: wallboardCtx.site.geolocation.lng,
      lat: wallboardCtx.site.geolocation.lat,
    },
  )

  if (!weather.data) return null

  const { current } = weather.data

  if (!current) return null

  const description = current.weather?.[0]?.description
  const iconId = current.weather?.[0]?.id

  return (
    <div className="bg-surface p-xs rounded">
      <Inline
        alignX="center"
        alignY="center"
        className="text-xl"
        space="sm"
        width="full"
      >
        <div>{Math.round(current.temp)}°</div>
        <WeatherIcon name="owm" iconId={iconId} />
        <sub className="truncate text-color-main-faded font-light text-md">
          {description}
        </sub>
      </Inline>

      <Inline
        alignX="center"
        className="font-light text-sm"
        space="xxs"
        width="full"
      >
        <div>
          <span className="text-color-neutral">Humidity</span>{' '}
          {Math.round(current.humidity)}%
        </div>{' '}
        <span className="text-color-neutral-dark">|</span>
        <div>
          <span className="text-color-neutral">Dew Point</span>{' '}
          {Math.round(current.dew_point)}°
        </div>{' '}
        <span className="text-color-neutral-dark">|</span>
        <div>
          <span className="text-color-neutral">Wind</span>{' '}
          {Math.round(current.wind_speed)} <sup>mph</sup>
        </div>
      </Inline>
    </div>
  )
}

Weather.defaultProps = {}

export { Weather }
