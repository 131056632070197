import React from 'react'
import { TableGroup } from '@therms/atalaya'
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'

interface ContactsTableRowProps {
  rowData: any
  rowKey: string
  siteId?: string
}

function ContactsTableRow({ rowData, rowKey, siteId }: ContactsTableRowProps) {
  return (
    <TableGroup.Row
      className="hover:bg-surface hover:cursor-pointer"
      rowKey={rowKey}
    >
      <TableGroup.Cell>{rowData.title}</TableGroup.Cell>

      <TableGroup.Cell>{rowData.email}</TableGroup.Cell>

      <TableGroup.Cell>{rowData.phone}</TableGroup.Cell>

      <TableGroup.Cell>
        {rowData.adminOnly ? (
          <AiOutlineCheck size={15} className="text-color-positive" />
        ) : (
          <AiOutlineClose size={15} className="text-color-critical" />
        )}
      </TableGroup.Cell>

      {!siteId && (
        <TableGroup.Cell>
          {rowData.isOrgWide ? (
            <AiOutlineCheck size={15} className="text-color-positive" />
          ) : (
            <AiOutlineClose size={15} className="text-color-critical" />
          )}
        </TableGroup.Cell>
      )}

      <TableGroup.Cell ellipse>{rowData.description}</TableGroup.Cell>
    </TableGroup.Row>
  )
}

export { ContactsTableRow }
