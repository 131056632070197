/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useState } from 'react'
import { ColorSquare, Select, SelectProps } from '@therms/atalaya'
import { GetTaskGroups } from '@src/services/api-client'
import { useAuth } from '../../Auth/shared/state'

type SelectTaskGroupsProps = Omit<SelectProps, 'options'>

function BaseOption(option) {
  return <div>
    {option.color && <ColorSquare color={option.color} marginRight />}
    {option.label}
  </div>
}

function SelectTaskGroup({
  label,
  multi,
  name,
  value,
  ...props
}: SelectTaskGroupsProps) {
  const [data, setData] = useState([])
  const { currentSite } = useAuth()
  const options = useMemo(
    () =>
      data.map((taskGroup) => ({
        color: taskGroup.uiData?.color,
        label: taskGroup.name,
        value: taskGroup.id,
      })) ?? [],
    [data],
  )

  useEffect(() => {
    GetTaskGroups({ limit: 100, siteId:currentSite.id }).then((res) => {
      setData(res.data.taskGroups)
    })
  }, [currentSite])

  return (
    <Select
      label={label}
      multi={!!multi}
      name={name}
      options={options}
      {...props}
      value={value}
      RenderListItem={BaseOption}
      RenderSelectedSingle={BaseOption}
      RenderSelectedMulti={BaseOption}
    />
  )
}
export { SelectTaskGroup }
