import React from 'react'
import {NavLink, useHistory} from 'react-router-dom'
import {Button, Inline, Label, Loading, Tabs} from '@therms/atalaya'
import {ORGANIZATION_ROUTES} from '../../constants'
import {useAuth} from '@src/modules/Auth/shared/state'
import {useCurrentOrganization} from '@src/modules/Auth/shared/useCurrentOrganization'
import {OrganizationUsersTable} from '@src/modules/Users/shared/Organization/Users'
import {SitesTable} from '@src/modules/Sites/router/screens/Index/components/Sites/SitesTable'
import {SITES_ROUTES} from '@src/modules/Sites/constants'
import {MdAdd} from 'react-icons/md'
import {ContactsTable} from '@src/modules/Contacts/router/screens/Index'
import {BillingOverview} from "@src/modules/Organization/router/screens/components/Billing/BillingOverview"
import {PaymentInfo} from "@src/modules/Organization/router/screens/components/Billing/PaymentInfo"

function OrganizationIndex() {
  const { name } = useCurrentOrganization()
  const { allSites, currentUser } = useAuth()
  const history = useHistory()

  if (!name) {
    return <Loading overlay />
  }

  return (
    <div>
      <Inline alignX="between" width="full">
        <span className="truncate text-lg">{name}</span>

        <div />
      </Inline>

      <Tabs initialTabKey="Info">
        <Tabs.Tab title="Info">
          <div className="grid grid-cols-2 mt-3">
            <div className="grid grid-cols-1 gap-2 md:grid-cols-2">
              <div>
                <Label>Organization Name</Label>

                <div>{name}</div>
              </div>
            </div>

            <div className="flex justify-end">
              {currentUser?.isAdmin && (
                <Inline>
                  <NavLink to={ORGANIZATION_ROUTES.EDIT_ORGANIZATION_INFO}>
                    <Button outline variant="neutral">
                      Edit
                    </Button>
                  </NavLink>
                </Inline>
              )}
            </div>
          </div>
        </Tabs.Tab>

        {allSites.length <= 1 && (
          <Tabs.Tab title="Sites">
            {currentUser.isAdmin && (
              <Inline alignX="between" width="full">
                <div className="text-lg">All Sites</div>

                <div>
                  <Button
                    onClick={() => history.push(SITES_ROUTES.NEW_SITE)}
                    variant="main"
                  >
                    <MdAdd /> New Site
                  </Button>
                </div>
              </Inline>
            )}

            <SitesTable />
          </Tabs.Tab>
        )}

        <Tabs.Tab title="Users">
          <OrganizationUsersTable />
        </Tabs.Tab>

        <Tabs.Tab title="Contacts">
          <ContactsTable />
        </Tabs.Tab>
        <Tabs.Tab title="Billing Overview">
          <BillingOverview />
        </Tabs.Tab>
        <Tabs.Tab title="Payment Info">
          <PaymentInfo />
        </Tabs.Tab>
      </Tabs>
    </div>
  )
}

export { OrganizationIndex }
