import React, {useState} from 'react'
import {Card, Inline, Loading} from "@therms/atalaya"
import {useApi} from "@src/hooks/useApi"
import {GetInvoices} from "@src/services/api-client"

export function InvoiceHistoryViewer(){

    const [offset, setOffset] = useState(0)
    const { data, isCalling } = useApi(GetInvoices, {offset})
    return(
        <Card className="mb-4">
            <Loading overlay visible={isCalling}/>
            <div className="mb-base">
                <div className="grid grid-cols-8 font-bold text-color-neutral mb-1">
                    <div>Invoice #</div>
                    <div>Date</div>
                    <div>Invoice Total</div>
                    <div>Status</div>
                    <div>Link</div>
                </div>

                {(data || []).map(inv => (
                    <div className="grid grid-cols-8 italic text-color-neutral mb-1">
                        <div>
                            {inv.invoiceNumber}
                        </div>

                        <div>
                            {inv.month}/{inv.day}/{inv.year}
                        </div>


                        <div>
                            ${(inv.totalCents / 100).toFixed(2)}
                        </div>

                        <div>
                            {inv.status === 'Delinquent' ? <Inline className="text-color-critical">Past Due</Inline> : inv.status}
                        </div>
                        <div>
                            <a className="text-color-info" href={inv.viewLink}>View</a>
                        </div>
                    </div>
                ))}



            </div>

        </Card>
    )
}
