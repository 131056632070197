/* eslint no-await-in-loop: 0 */ // --> OFF
/* eslint no-nested-ternary: 0 */ // --> OFF
/* eslint react/no-children-prop: 0 */ // --> OFF
import React, { useCallback, useState, useEffect } from 'react'
import {
  Modal,
  Card,
  Button,
  Loading,
  FormLayout,
  Inline,
  ButtonGroup,
  Password,
  ButtonWithModal,
} from '@therms/atalaya'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { FormikEmail } from '@src/components/FormikFields/FormikEmail'
import {
  UserModUserAtSite,
  UserModUserReports,
  UserModUserDetails,
  UserModUserSiteAdmin,
  UserModUserGlobalAdmin,
  AdminSetPassword,
  UserWallboardLoginMod,
} from '@src/services/api-client'
import { FormikSelectPositions } from '@src/modules/Positions/shared/Fields/FormikSelectPositions'
import { FormikText } from '@src/components/FormikFields/FormikText'
import { useSiteUser } from '../../../../shared/hooks/UseSiteUser'
import { Alerts } from '@src/components/Alerts'
import { useAuth } from '@src/modules/Auth/shared/state'
import { FormikCheckbox } from '@src/components/FormikFields/FormikCheckbox'
import * as Yup from 'yup'
import { ResetPasswordThroughEmailModal } from '@src/modules/Auth/shared/Users/ResetPasswordThroughEmailModal'

const ValidationSchema = Yup.object().shape({
  email: Yup.string().email().required('Email required'),
  positionId: Yup.string().required('You must select a position.'),
})

function ModUserModal({ userId, closeHandler, onComplete }) {
  const [idToFetch, setIdToFetch] = useState(userId)
  const [isLoading, setIsLoading] = useState(false)
  const [isGlobalDel, setIsGlobalDel] = useState(false)
  const [isConfirming, setIsConfirming] = useState(false)
  const { currentSite, currentSiteUser } = useAuth()
  const { siteUser, isCalling } = useSiteUser(idToFetch)
  const [password, setPassword] = useState('')

  const currentSiteUserPosition = siteUser?.sitePositions.filter(
    (site) => site.siteId === currentSite.id,
  )

  const removeFromSite = async (siteId, user) => {
    if (user.sitePositions.filter((p) => p.siteId === siteId).length > 0) {
      await UserModUserAtSite({ ...user, siteId, action: 'remove' })
    }
    if (
      (user.siteAdminPrivs || []).filter((priv) => priv.siteId === siteId)
        .length > 0
    ) {
      await UserModUserSiteAdmin({
        ...user,
        siteId,
        privLevel: 'readWrite',
        action: 'remove',
      })
    }
    if ((user.siteIdsRecReportsFrom || []).includes(siteId)) {
      await UserModUserReports({ ...user, siteId, action: 'remove' })
    }
  }

  const save = useCallback(
    async (values) => {
      setIsLoading(true)
      if (idToFetch === '') {
        try {
          const resp = await UserModUserAtSite({
            ...values,
            siteId: currentSite.id,
            action: 'add',
          })
          setIdToFetch(resp.data.id)
        } catch (err) {
          Alerts.error('There was a problem saving the User', err.message)
        } finally {
          setIsLoading(false)
        }
      } else {
        try {
          if (
            values.name !== siteUser.name ||
            values.phone !== siteUser.phone
          ) {
            await UserModUserDetails({ ...values, siteId: currentSite.id })
          }
          if (values.positionId !== siteUser.positionId) {
            await UserModUserAtSite({
              ...values,
              siteId: currentSite.id,
              action: 'add',
            })
          }
          if (values.receivingReport !== siteUser.receivingReport) {
            await UserModUserReports({
              ...values,
              siteId: currentSite.id,
              action: values.receivingReport ? 'add' : 'remove',
            })
          }
          if (values.siteAdmin !== siteUser.siteAdmin) {
            await UserModUserSiteAdmin({
              ...values,
              siteId: currentSite.id,
              privLevel: 'readWrite',
              action: values.siteAdmin ? 'add' : 'remove',
            })
          }
          if (values.isAdmin !== siteUser.isAdmin) {
            await UserModUserGlobalAdmin({
              ...values,
              siteId: currentSite.id,
              action: values.isAdmin ? 'add' : 'remove',
            })
          }
          if (
            values.allowWallboardLogin !==
            currentSiteUserPosition[0].canWallboardLogin
          ) {
            await UserWallboardLoginMod({
              email: siteUser.email,
              siteId: currentSite.id,
              action: values.allowWallboardLogin ? 'add' : 'remove',
            })
          }
          Alerts.success('User Saved')
          onComplete()
          closeHandler()
        } catch (err) {
          Alerts.error('There was a problem saving the User', err.message)
        } finally {
          setIsLoading(false)
        }
      }
    },
    [idToFetch, siteUser],
  )

  const removeUser = useCallback(async () => {
    setIsConfirming(false)
    setIsLoading(true)
    try {
      if (isGlobalDel) {
        await UserModUserGlobalAdmin({
          ...siteUser,
          siteId: currentSite.id,
          action: 'remove',
        })
        // get all site Ids associated with this user
        const allSites = [
          ...new Set(
            [
              siteUser.sitePositions.map((sp) => sp.siteId),
              (siteUser.siteAdminPrivs || []).map((sap) => sap.siteId),
              siteUser.siteIdsRecReportsFrom,
            ].flat(1),
          ),
        ]
        for (const a of allSites) {
          await removeFromSite(a, siteUser)
        }
      } else {
        await removeFromSite(currentSite.id, siteUser)
      }
      Alerts.success('User Removed')
      onComplete()
      closeHandler()
    } catch (err) {
      Alerts.error('There was a problem removing the User', err.message)
    } finally {
      setIsLoading(false)
    }
  }, [isGlobalDel, siteUser, currentSite])

  const startSiteRemove = () => {
    setIsGlobalDel(false)
    setIsConfirming(true)
  }

  const startGlobalRemove = () => {
    setIsGlobalDel(true)
    setIsConfirming(true)
  }

  useEffect(() => {
    setIsLoading(isCalling)
  }, [isCalling])

  const resetNonAdminPassword = useCallback(() => {
    AdminSetPassword({ userId, password, siteId: currentSite.id })
      .then(() => onComplete())
      .catch((e) => {
        Alerts.error('Invalid Password Reset')
        console.log('Password reset error', e)
      })
  }, [userId, password, siteUser])

  return (
    <Modal header="User" closeHandler={closeHandler}>
      <div>
        {isLoading ? (
          <div className="text-center">
            <Loading />
          </div>
        ) : isConfirming ? (
          <Card>
            <div className="text-lg font-bold text-center">
              You are about to delete this user.
            </div>
            <Button onClick={removeUser} variant="critical">
              Confirm
            </Button>
            <Button onClick={() => setIsConfirming(false)} variant="neutral">
              Cancel
            </Button>
          </Card>
        ) : (
          <div>
            <Formik
              enableReinitialize
              initialValues={{
                email: siteUser?.email || '',
                positionId: siteUser?.positionId || '',
                name: siteUser?.name || '',
                phone: siteUser?.phone || '',
                siteAdmin: siteUser?.siteAdmin || false,
                receivingReport: siteUser?.receivingReport || false,
                isAdmin: siteUser?.isAdmin || false,
                allowWallboardLogin: currentSiteUserPosition
                  ? !!currentSiteUserPosition[0].canWallboardLogin
                  : false,
              }}
              onSubmit={save}
              validateOnChange={false}
              validationSchema={ValidationSchema}
            >
              {({ submitForm, values }) => (
                <div>
                  <FormikEmail hint="required" label="Email" name="email" />
                  <div className="mt-2" />
                  <FormikSelectPositions
                    multi={false}
                    hint="required"
                    label="Position"
                    name="positionId"
                  />
                  <div className="mt-2" />
                  {siteUser && (
                    <>
                      <FormikText hint="required" label="Name" name="name" />
                      <div className="mt-2" />
                      <FormikText hint="required" label="Phone" name="phone" />
                      <div className="mt-2" />
                      <FormikCheckbox
                        label="Allow Login using Wallboard"
                        name="allowWallboardLogin"
                        value={!!values.allowWallboardLogin}
                      />
                      <FormikCheckbox
                        label="Receives Report"
                        name="receivingReport"
                      />
                      <FormikCheckbox label="Site Admin" name="siteAdmin" />
                      {currentSiteUser.isAdmin && (
                        <FormikCheckbox label="Global Admin" name="isAdmin" />
                      )}
                    </>
                  )}
                  <FormLayout.Controls
                    isHorizontal
                    onSubmit={submitForm}
                    submitLabel={siteUser ? 'Save' : 'Add'}
                    extraEnd={
                      <Inline space="xxs">
                        <ButtonGroup>
                          {!!siteUser && (
                            <>
                              <ButtonWithModal
                                variant="main"
                                subtle
                                modalElement={
                                  !siteUser?.isAdmin || !siteUser?.siteAdmin ? (
                                    <Modal
                                      closeHandler={closeHandler}
                                      header="Set New Password"
                                    >
                                      <FormLayout.Section space="xs">
                                        <Password
                                          placeholder="New Password..."
                                          value={password}
                                          onChangeValue={setPassword}
                                        />
                                      </FormLayout.Section>

                                      <FormLayout.Controls
                                        submitLabel="Submit"
                                        onSubmit={resetNonAdminPassword}
                                        isHorizontal
                                      />
                                    </Modal>
                                  ) : (
                                    <ResetPasswordThroughEmailModal
                                      closeHandler={closeHandler}
                                      onComplete={closeHandler}
                                    />
                                  )
                                }
                              >
                                Reset Password
                              </ButtonWithModal>
                              <Button
                                subtle
                                onClick={startSiteRemove}
                                variant="caution"
                              >
                                Remove From Site
                              </Button>
                            </>
                          )}
                          {!!siteUser && currentSiteUser.isAdmin && (
                            <Button
                              subtle
                              onClick={startGlobalRemove}
                              variant="critical"
                            >
                              Remove Completely
                            </Button>
                          )}
                        </ButtonGroup>
                      </Inline>
                    }
                  />
                </div>
              )}
            </Formik>
          </div>
        )}
      </div>
    </Modal>
  )
}

ModUserModal.propTypes = {
  closeHandler: PropTypes.func,
  onComplete: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
}

export { ModUserModal }
