import React from 'react'
import { SiteDeleteSite } from '@src/services/api-client'
import { Alerts } from '@src/components/Alerts'
import { ButtonWithActionModal } from '@src/components/ButtonWithActionModal'
import { IoMdTrash } from 'react-icons/io'

interface DeleteSiteButtonActionModalProps {
  onComplete: () => void
  siteId: string
}

function DeleteSiteButtonActionModal({
  onComplete,
  siteId,
}: DeleteSiteButtonActionModalProps) {
  return (
    <ButtonWithActionModal
      actionText={
        <>
          <IoMdTrash /> Delete Site
        </>
      }
      body="Are you sure you want to delete this Site?"
      onAction={async () => {
        try {
          await SiteDeleteSite(siteId)
          onComplete()
        } catch (err) {
          Alerts.error('There was a problem deleting the Site', err.message)

          console.log('delete err', err)
        }
      }}
      subtle
      title="Delete Site"
      variant="critical"
    />
  )
}

DeleteSiteButtonActionModal.defaultProps = {}

export { DeleteSiteButtonActionModal }
