import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Select } from '@therms/atalaya'
import { GetPositions } from '@src/services/api-client'
import { useAuth } from '@src/modules/Auth/shared/state'

function SelectPositions({ label, multi, name, value, ...props }) {
  const [data, setData] = useState([])
  const {  currentSite } = useAuth()
  const options = useMemo(
    () =>
      data?.map((site) => ({
        label: site.title,
        value: site.id,
      })),
    [data],
  )

  useEffect(() => {
    GetPositions({ siteId:currentSite.id, limit: 100 }).then((res) => {
      setData(res.data.positions)
    })
  }, [])

  return (
    <Select
      label={label}
      multi={multi}
      name={name}
      options={options}
      {...props}
      value={value}
    />
  )
}

SelectPositions.propTypes = {
  label: PropTypes.string,
  multi: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
}

export { SelectPositions }
