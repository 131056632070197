import React from 'react'
import {useApi} from "@src/hooks/useApi"
import {GetActiveBilling} from "@src/services/api-client"
import {Card, Loading} from "@therms/atalaya"
import dayjs from "dayjs"


export function ActiveSitesViewer(){
    const { data, isCalling } = useApi(GetActiveBilling)
    return (
        <Card className="mb-4">
            <div className="mb-base">
                <Loading overlay visible={isCalling}/>
                <div className="grid grid-cols-8 font-bold text-color-neutral mb-1">
                    <div>Billed To</div>
                    <div>Email</div>
                    <div># of Sites</div>
                    <div>Per Site Rate</div>
                    <div>Est. Monthly Total</div>
                    <div>Next Billing Date</div>
                    <div>Paid By</div>
                </div>
                {(data || []).map((item) => (
                    <div className="grid grid-cols-8  italic text-color-neutral mb-1">
                        <div>
                            {item.organizationName}
                        </div>
                        <div>
                            {item.email}
                        </div>
                        <div>
                            {item.siteIds.length}
                        </div>

                        <div>
                            $ {(item.perSiteRateCents /100).toFixed(2)}
                        </div>

                        <div>
                            $ {(item.totalRateCents /100).toFixed(2)}
                        </div>
                        <div>
                            {dayjs(item.nextBillingDate).format('MM/DD/YYYY')}
                        </div>
                        <div>
                            {item.paymentSource === 'CreditCard' ? 'Credit Card' : 'Invoice'}
                        </div>
                    </div>
                ))}


            </div>
        </Card>)}
