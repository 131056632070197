import React, { useState } from 'react'
import { Button, Inline } from '@therms/atalaya'
import PropTypes from 'prop-types'
import { ModUserModal } from '../../../ViewUser/components/ModUserModal'
import { MdAdd } from 'react-icons/md'

function TableControls({ reload }) {
  const [showModal, setShowModal] = useState(false)

  return (
    <Inline alignX="end" alignY="baseline" className="pb-3" width="full">
      <div>
        <Button onClick={() => setShowModal(true)} variant="main">
          <MdAdd /> Add User To Site
        </Button>
      </div>

      {showModal && (
        <ModUserModal
          onComplete={reload}
          closeHandler={() => setShowModal(false)}
          userId=""
        />
      )}
    </Inline>
  )
}
TableControls.propTypes = {
  reload: PropTypes.func.isRequired,
}

export { TableControls }
