import {useApiWithSiteId} from '@src/hooks/useApiWithSiteId'
import {GetAllOrgTemplates, GetAllOrgTemplatesResponseBody} from '@src/services/api-client'

export const useOrgTemplates = (siteBrandIds?:string[]): { id: string; name: string }[] => {
  const { data } = useApiWithSiteId(GetAllOrgTemplates, {})
  const allTemplates: GetAllOrgTemplatesResponseBody['orgTemplates'] = data ? data.orgTemplates : []
  const output = []
  const siteBrandSet = new Set(siteBrandIds || [])
  for (const template of allTemplates) {
    if (template.brandIds) {
      const setIntersection = template.brandIds.filter(x => siteBrandSet.has(x))
      if (setIntersection.length > 0) {
        output.push(template)
      }
    } else {
      output.push(template)
    }
  }
  return output
}
