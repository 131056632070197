import React from 'react'
import PropTypes from 'prop-types'
import { DateTime, TableGroup } from '@therms/atalaya'
import { EscalationName } from '@src/modules/Escalations/shared/EscalationName'
import { UserName } from '@src/modules/Users/shared/UserName'
import { TaskName } from '@src/modules/Tasks/shared/TaskName'
import { FiCheckCircle } from 'react-icons/fi'

function EscalationOccurrencesTableRow({ rowData, rowKey }) {
  return (
    <TableGroup.Row
      className="hover:bg-surface hover:cursor-pointer"
      rowKey={rowKey}
    >
      <TableGroup.Cell>
        {rowData.resolvedDate && (
          <span className="inline-block">
            <FiCheckCircle color="green" size={18} />
          </span>
        )}
        <DateTime timestamp={rowData.timestamp} />
      </TableGroup.Cell>

      <TableGroup.Cell>
        <div>
          <EscalationName id={rowData.escalationId} />
        </div>
        <div className="font-light">
          <UserName id={rowData.escalatingUserId} />
        </div>
      </TableGroup.Cell>

      <TableGroup.Cell>
        <div>
          <TaskName
            id={rowData.triggeredBy.triggerId}
            render={(task) => {
              const taskGroup = task.taskGroups[0]

              return (
                <div>
                  {taskGroup?.name && (
                    <div
                      style={
                        taskGroup?.uiData?.color
                          ? { color: taskGroup.uiData.color }
                          : {}
                      }
                    >
                      {taskGroup?.name}
                    </div>
                  )}
                  <div>{task.title}</div>
                </div>
              )
            }}
          />
        </div>
      </TableGroup.Cell>

      <TableGroup.Cell>
        <span className="font-light">{rowData?.commentIds?.length}</span>
      </TableGroup.Cell>
    </TableGroup.Row>
  )
}

EscalationOccurrencesTableRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  rowKey: PropTypes.any.isRequired,
}

export { EscalationOccurrencesTableRow }
