import { Card, DateTime, Inline, Stack } from '@therms/atalaya'
import React, { useState } from 'react'
import { FiCalendar, FiClock } from 'react-icons/fi'
import { useWallboardContext } from '@src/modules/wallboard/context/WallboardContext'
import { useInterval, useUpdateEffect } from 'ahooks'
import { TasksCard } from '@src/modules/wallboard/router/screens/Wallboard/components/TasksCard'
import { ActivityCard } from '@src/modules/wallboard/router/screens/Wallboard/components/ActivityCard'
import { Weather } from '@src/modules/wallboard/router/screens/Wallboard/components/Weather'
import { ErrorBoundary } from 'react-error-boundary'
import * as Sentry from '@sentry/react'
import { OnSiteUsersCard } from '@src/modules/wallboard/router/screens/Wallboard/components/OnSiteUsersCard'
import { useApi } from '@src/hooks/useApi'
import {
  GetSiteSnapshotForAdminResponseBody,
  SiteSnapshotForAdmin,
  WallboardGetLoginToken,
} from '@src/services/api-client'
import { QRCodeSVG } from 'qrcode.react'
import classNames from 'classnames'

function Wallboard() {
  const [now, setNow] = useState(new Date().toISOString())
  const wallboardCtx = useWallboardContext()

  useInterval(() => setNow(new Date().toISOString()), 10000)

  const { call, data } = useApi<GetSiteSnapshotForAdminResponseBody>(
    SiteSnapshotForAdmin,
    wallboardCtx.siteId,
  )

  const { call: getWallboardLoginToken, data: wallboardLoginToken } = useApi(
    WallboardGetLoginToken,
    wallboardCtx.siteId,
  )

  useInterval(() => getWallboardLoginToken(), 60000)

  useUpdateEffect(() => {
    call()
  }, [now])

  return (
    <Stack className="h-full p-xs" space="xs">
      <Card padding="xs">
        <Inline className="grid grid-cols-2" width="full">
          <div className="text-lg flex justify-evenly truncate">
            <img
              alt="QuickTask"
              src="https://quicktask-admin-prod-static.s3.us-east-1.amazonaws.com/logo.png"
              width="30"
            />
            <div className="ml-xxs font-brand">QuickTask</div>
            <span className="truncate flex-1 text-right">
              {wallboardCtx.site?.name}
            </span>
          </div>

          <Inline
            alignX="end"
            alignY="center"
            className="text-color-main text-lg truncate"
            space="xs"
            width="full"
          >
            <Inline
              alignY="center"
              className="font-light text-color-main-dark text-opacity-50"
              space="xs"
            >
              <FiCalendar />
              <DateTime mode="date" timestamp={now} />
            </Inline>

            <FiClock />

            <strong>
              <DateTime mode="time" timestamp={now} />
            </strong>
          </Inline>
        </Inline>
      </Card>

      <div
        className="grid grid-cols-2 gap-xs grow overflow-hidden"
        // style={{ maxHeight: '100%' }}
      >
        <TasksCard snapshotForAdminData={data} />

        <div className="gap-y-xs grid overflow-hidden">
          <ErrorBoundary
            onError={(error, componentStack) => {
              Sentry.withScope((scope) => {
                scope.setExtras(componentStack)
                Sentry.captureException(error)
              })
            }}
            fallback={<div />}
          >
            <Weather />
          </ErrorBoundary>

          <ErrorBoundary
            onError={(error, componentStack) => {
              Sentry.withScope((scope) => {
                scope.setExtras(componentStack)
                Sentry.captureException(error)
              })
            }}
            fallback={<div />}
          >
            <div className="grid grid-cols-3 gap-x-xs">
              <div
                className={classNames({
                  'col-span-2':
                    wallboardCtx.site?.wallboardSettings?.qrCodeSignIn || false,
                  'col-span-3':
                    !wallboardCtx.site?.wallboardSettings?.qrCodeSignIn ||
                    false,
                })}
              >
                <OnSiteUsersCard
                  snapshotForAdminSubscribers={data?.subscribed}
                />
              </div>

              {wallboardCtx.site?.wallboardSettings?.qrCodeSignIn && (
                <div className="flex flex-col justify-center items-center bg-surface space-y-base w-full">
                  <span className=" text-lg">Login QR Code</span>

                  <QRCodeSVG
                    height="150px"
                    width="150px"
                    value={`wallboard_login=${wallboardLoginToken}`}
                  />
                </div>
              )}
            </div>
          </ErrorBoundary>

          <ErrorBoundary
            onError={(error, componentStack) => {
              Sentry.withScope((scope) => {
                scope.setExtras(componentStack)
                Sentry.captureException(error)
              })
            }}
            fallback={<div />}
          >
            <ActivityCard snapshotForAdminRecent={data?.recent} />
          </ErrorBoundary>
        </div>
      </div>
    </Stack>
  )
}

export { Wallboard }
