import React from 'react'
import { TableGroup } from '@therms/atalaya'
// import PropTypes from 'prop-types'

function PositionOptionsTableHeadRow() {
  return (
    <TableGroup.HeadRow>
      <TableGroup.HeadCell>Title</TableGroup.HeadCell>
      <TableGroup.HeadCell>Sub-Positions</TableGroup.HeadCell>
    </TableGroup.HeadRow>
  )
}

PositionOptionsTableHeadRow.propTypes = {}

export { PositionOptionsTableHeadRow }
