import { Constants } from '@therms/atalaya'

export function getWidthBreakpoint(
  width: number,
  opt?: { defaultBreakpoint?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl' },
): 'sm' | 'md' | 'lg' | 'xl' | 'xxl' {
  let breakpoint = opt?.defaultBreakpoint || 'sm'

  if (width < Constants.Breakpoint.Sm.width) {
    breakpoint = 'sm'
  } else if (width < Constants.Breakpoint.Md.width) {
    breakpoint = 'md'
  } else if (width < Constants.Breakpoint.Lg.width) {
    breakpoint = 'lg'
  } else if (width < Constants.Breakpoint.Xl.width) {
    breakpoint = 'xl'
  } else if (width < Constants.Breakpoint.Xxl.width) {
    breakpoint = 'xxl'
  }

  return breakpoint
}
