import React from 'react'
import PropTypes from 'prop-types'
import { FiCheck } from 'react-icons/fi'
import { Button, TableGroup } from '@therms/atalaya'
import { generatePath, useHistory } from 'react-router-dom'
import { TASKS_ROUTES } from '@src/modules/Tasks/constants'
import { TaskCompletionHistoryModal } from '@src/modules/Tasks/components/TaskCompletionHistoryModal'
import { useToggle } from 'ahooks'
import { Task } from '@src/services/api-client'
import { RRuleText } from '@src/modules/Tasks/components/RRuleText'
import { useCurrentOrganization } from '@src/modules/Auth/shared/useCurrentOrganization'
import { PositionName } from '../../../../../../Positions/shared/PositionName'
import { TaskGroupName } from '../../../../../shared/TaskGroupName'
import { PositionNames } from '@src/modules/Positions/shared/PositionNames'

interface Props {
  rowData: Task
  rowKey: string
}

function TableTaskRow({ rowData, rowKey }: Props) {
  const { push } = useHistory()

  const { isTemplateProducer } = useCurrentOrganization()

  const [showTaskHistoryModal, { toggle: toggleShowTaskHistoryModal }] =
    useToggle(false)

  const taskGroupId = rowData.taskGroupIds?.[0]

  return (
    <>
      <TableGroup.Row
        canSelect
        className="hover:bg-surface hover:cursor-pointer"
        rowKey={rowKey}
        onClick={() => {
          push(generatePath(TASKS_ROUTES.EDIT_TASK, { taskId: rowData.id }))
        }}
      >
        <TableGroup.Cell>
          {taskGroupId && (
            <small className="w-fit">
              <TaskGroupName id={taskGroupId} />
            </small>
          )}
          <div>{rowData.title}</div>
        </TableGroup.Cell>

        <TableGroup.Cell ellipse>
          <div className="whitespace-pre">
            {!!rowData?.positionIds.length && (
              <PositionNames sitePositions={rowData.positionIds} />
            )}
          </div>
        </TableGroup.Cell>

        <TableGroup.Cell>
          <small>
            <div className="text-color-info">
              {rowData.scheduleStrings.map((schstr, i) =>
                // eslint-disable-next-line no-nested-ternary
                i <= 2 ? (
                  <>
                    <RRuleText rruleString={schstr} />
                    {rowData.scheduleStrings.length - 1 > i ? ', ' : ''}
                  </>
                ) : i === 3 ? (
                  <span>+ {rowData.scheduleStrings.length - 3} more</span>
                ) : (
                  ''
                ),
              )}{' '}
            </div>
            <div>
              {rowData.description?.length > 50
                ? `${rowData.description.substring(0, 50)}...`
                : rowData.description}
            </div>
          </small>
        </TableGroup.Cell>

        <TableGroup.Cell ellipse>
          <Button
            className="mr-sm"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()

              toggleShowTaskHistoryModal()
            }}
            subtle
            variant="main"
          >
            History
          </Button>

          {/* <em className="text-color-neutral">Last completed 3hrs ago</em> */}
        </TableGroup.Cell>

        {isTemplateProducer && (
          <TableGroup.Cell ellipse>
            {rowData.orgTemplateData?.orgTemplateProducerEnable && (
              <FiCheck color="green" size={14} />
            )}
          </TableGroup.Cell>
        )}
      </TableGroup.Row>

      {showTaskHistoryModal && (
        <TaskCompletionHistoryModal
          closeHandler={toggleShowTaskHistoryModal}
          taskId={rowKey}
        />
      )}
    </>
  )
}

TableTaskRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  rowKey: PropTypes.any.isRequired,
}

export { TableTaskRow }
