import React, { useCallback, useState } from 'react'
import { Card, FormLayout, Loading } from '@therms/atalaya'
import { Formik } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { Alerts } from '@src/components/Alerts'
import { FormikText } from '@src/components/FormikFields/FormikText'
import { useApiWithSiteId } from '@src/hooks/useApiWithSiteId'
import {
  PositionCreatePosition,
  PositionDeletePosition,
  PositionGetPosition,
  PositionModifyPosition,
} from '@src/services/api-client'
import { FormikSelectPositions } from '@src/modules/Positions/shared/Fields/FormikSelectPositions'
import { useAuth } from '@src/modules/Auth/shared/state'
import { delErrorCheck } from '@src/services/api-resp-checks'
import { FormikCheckbox } from '@src/components/FormikFields/FormikCheckbox'

const ValidationSchema = Yup.object().shape({
  title: Yup.string().min(3).required('A title is required'),
})

function PositionForm({ onCancel, onComplete, positionId }) {
  const [isDeleting, setIsDeleting] = useState(false)
  const { currentSite } = useAuth()
  const { data, isCalling } = useApiWithSiteId(
    PositionGetPosition,
    positionId || false,
  )

  const handleDelete = async () => {
    setIsDeleting(true)

    PositionDeletePosition(positionId)
      .catch((err) => delErrorCheck(err))
      .finally(() => onComplete())
  }

  const save = useCallback(async (values) => {
    try {
      if (positionId) {
        await PositionModifyPosition({
          // description: values.description,
          title: values.title,
          id: values.id,
          positionIds: values.positionIds || [],
          unScored: values.unScored || false,
        })
      } else {
        await PositionCreatePosition({
          positionIds: values.positionIds || [],
          title: values.title,
          siteId: currentSite.id,
          unScored: values.unScored || false,
        })
      }

      Alerts.success(`Position ${positionId ? 'updated' : 'created'}`)

      onComplete()
    } catch (err) {
      Alerts.error('There was a problem saving the Position', err.message)

      console.log('save() err', err)
    }
  }, [])

  const position = data || {}
  const isTemplate = false

  return (
    <Formik
      enableReinitialize
      initialValues={position}
      onSubmit={save}
      validationSchema={ValidationSchema}
    >
      {({ isValid, submitForm, isSubmitting }) => (
        <FormLayout dividers="space" space="xs">
          {isCalling && <Loading overlay size="xxl" />}

          {(isDeleting || isSubmitting) && <Loading overlay size="xxl" />}

          {isTemplate && (
            <Card className="bg-info">
              This Position is from a template and cannot be edited at this
              time.
            </Card>
          )}

          <FormikText label="Title" name="title" />
          {/* <FormikTextarea */}
          {/*  hint="optional" */}
          {/*  label="Description" */}
          {/*  name="description" */}
          {/* /> */}

          <FormikSelectPositions
            hint="optional"
            label="Sub-Positions"
            name="positionIds"
            multi
            subText="Optionally, grant this Position access to other Positions"
          />
          <FormikCheckbox label="Disable Scoring" name="unScored" />
          {/* <FormikColorPicker label="Color" name="color" /> */}

          {!isTemplate && (
            <FormLayout.Controls
              disabled={!isValid}
              isSaving={isSubmitting}
              onCancel={onCancel}
              onDelete={positionId && handleDelete}
              onSave={submitForm}
            />
          )}
        </FormLayout>
      )}
    </Formik>
  )
}

PositionForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  positionId: PropTypes.string,
}

export { PositionForm }
