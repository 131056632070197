import React from 'react'
import { SitesTable } from './components/Sites/SitesTable.jsx'
import { Button, Inline, Tabs } from '@therms/atalaya'
import { SITES_ROUTES } from '@src/modules/Sites/constants'
import { MdAdd } from 'react-icons/md'
import { useAuth } from '@src/modules/Auth/shared/state'
import { useHistory } from 'react-router-dom'
import { OrgSiteStatsChart } from '@src/modules/Organization/router/screens/components/OrgSiteStatsChart'

function SitesIndex() {
  const { currentUser } = useAuth()
  const history = useHistory()

  return (
    <div>
      {currentUser.isAdmin && (
        <Inline alignX="between" width="full">
          <div className="text-lg">All Sites</div>

          <div>
            <Button
              onClick={() => history.push(SITES_ROUTES.NEW_SITE)}
              variant="main"
            >
              <MdAdd /> New Site
            </Button>
          </div>
        </Inline>
      )}

      <Tabs initialTabKey="Sites">
        <Tabs.Tab title="Sites">
          <SitesTable />
        </Tabs.Tab>

        <Tabs.Tab title="Stats">
          <OrgSiteStatsChart />
        </Tabs.Tab>
      </Tabs>
    </div>
  )
}

export { SitesIndex }
