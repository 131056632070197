import { useApiWithSiteId } from '@src/hooks/useApiWithSiteId'
import { GetEscOccurencesByTimeRange } from '@src/services/api-client'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'

export function useUnresolvedEscOccurrenceCount() {
  const [force, setForce] = useState(Math.random())

  useEffect(() => {
    const i = setInterval(() => {
      setForce(Math.random())
    }, 60000)

    return () => clearInterval(i)
  }, [])

  const query = useMemo(
    () => ({
      endDate: dayjs().add(1, 'day').toISOString(),
      isResolved: false,
      limit: 0,
      offset: 0,
      startDate: dayjs().subtract(1, 'month').toISOString(),
    }),
    [force],
  )

  const { data } = useApiWithSiteId(GetEscOccurencesByTimeRange, query)

  return data?.total ?? 0
}
