import React from 'react'
import { Inline } from '@therms/atalaya'

function TableControls() {
  return (
    <Inline alignX="between" alignY="baseline" className="pb-3" width="full">
      <div className="text-lg">All Users</div>
    </Inline>
  )
}

export { TableControls }
