import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { TASKS_ROUTES } from '../constants'
import { TaskForm } from '@src/modules/Tasks/router/screens/TaskForm'
import { TasksIndex } from '@src/modules/Tasks/router/screens/Index'
import { Button } from '@therms/atalaya'
import { ErrorBoundary } from 'react-error-boundary'
import * as Sentry from '@sentry/react'

function TasksRoutes() {
  return (
    <Switch>
      <Route
        render={() => (
          <ErrorBoundary
            onError={(error, componentStack) => {
              Sentry.withScope((scope) => {
                scope.setExtras(componentStack)
                Sentry.captureException(error)
              })
            }}
            fallbackRender={({ error, resetErrorBoundary }) => (
              <div>
                <p>Something went wrong:</p>
                <pre>{error.message}</pre>
                <Button onClick={resetErrorBoundary}>Try again</Button>
              </div>
            )}
          >
            <TaskForm />
          </ErrorBoundary>
        )}
        path={TASKS_ROUTES.EDIT_TASK}
      />
      <Route
        render={() => (
          <ErrorBoundary
            onError={(error, componentStack) => {
              Sentry.withScope((scope) => {
                scope.setExtras(componentStack)
                Sentry.captureException(error)
              })
            }}
            fallbackRender={({ error, resetErrorBoundary }) => (
              <div>
                <p>Something went wrong:</p>
                <pre>{error.message}</pre>
                <Button onClick={resetErrorBoundary}>Try again</Button>
              </div>
            )}
          >
            <TaskForm />
          </ErrorBoundary>
        )}
        path={TASKS_ROUTES.NEW_TASK}
      />
      <Route
        render={() => (
          <ErrorBoundary
            onError={(error, componentStack) => {
              Sentry.withScope((scope) => {
                scope.setExtras(componentStack)
                Sentry.captureException(error)
              })
            }}
            fallbackRender={({ error, resetErrorBoundary }) => (
              <div>
                <p>Something went wrong:</p>
                <pre>{error.message}</pre>
                <Button onClick={resetErrorBoundary}>Try again</Button>
              </div>
            )}
          >
            <TasksIndex />
          </ErrorBoundary>
        )}
        path={TASKS_ROUTES.ACTIVITY_TABLE_TASK_ID_DATE_RANGE}
      />
      <Route
        render={() => (
          <ErrorBoundary
            onError={(error, componentStack) => {
              Sentry.withScope((scope) => {
                scope.setExtras(componentStack)
                Sentry.captureException(error)
              })
            }}
            fallbackRender={({ error, resetErrorBoundary }) => (
              <div>
                <p>Something went wrong:</p>
                <pre>{error.message}</pre>
                <Button onClick={resetErrorBoundary}>Try again</Button>
              </div>
            )}
          >
            <TasksIndex />
          </ErrorBoundary>
        )}
      />
    </Switch>
  )
}

export { TasksRoutes }
