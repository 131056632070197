import React from 'react'
import { Label, Loading } from '@therms/atalaya'
import PropTypes from 'prop-types'
import { useApiWithSiteId } from '@src/hooks/useApiWithSiteId'
import { UserGetUser } from '@src/services/api-client'
import { PositionName } from '@src/modules/Positions/shared/PositionName'
import { SiteName } from '@src/modules/Sites/shared/SiteName'

function UserProfile({ userId }) {
  const { data } = useApiWithSiteId(UserGetUser, {
    userId,
    withPositions: true,
    withSites: true,
  })
    
  if (!data) return <Loading />

  return (
    <div className="grid grid-cols-1 gap-2 md:grid-cols-2">
      <div>
        <Label>Name</Label>
        <div>{data.name}</div>
      </div>

      <div>
        <Label>Phone</Label>
        <div>{data.phone}</div>
      </div>

      <div>
        <Label>Email</Label>
        <div>{data.email}</div>
      </div>

      <div>
        <Label>Position</Label>
        {data?.positionId && (
          <div>
            <PositionName id={data.positionId} />
          </div>
        )}
      </div>

      <div>
        <Label>Sites Access</Label>
        <div>{data.sites?.map(({ name }) => name).join(', ')}</div>
      </div>

      <div>
        <Label>Sites Daily Task Email Subscriptions</Label>
        <div className="flex flex-wrap">
          {data.siteIdsRecReportsFrom?.length ? (
            data.siteIdsRecReportsFrom?.map((id) => (
              <div className="mr-1">
                <SiteName id={id} />
              </div>
            ))
          ) : (
            <span>No Site Subscriptions</span>
          )}
        </div>
      </div>
    </div>
  )
}

UserProfile.propTypes = {
  userId: PropTypes.string.isRequired,
}

export { UserProfile }
