import React from 'react'
import {loadStripe} from '@stripe/stripe-js'
import {FaCcAmex, FaCcDiscover, FaCcMastercard, FaCcVisa, FaCreditCard} from "react-icons/fa6"
import {Elements,} from '@stripe/react-stripe-js'
import {Card, Inline, Loading} from "@therms/atalaya"
import {useApi} from "@src/hooks/useApi"
import {GetCurrentPaymentMethod} from "@src/services/api-client"
import {
    StripeCheckoutForm
} from "@src/modules/Organization/router/screens/components/Billing/PaymentInfo/components/StripeCheckoutForm"
import PropTypes from "prop-types"
import {STRIPE_PK} from "@src/utils/stripe-keys"


const stripePromise = loadStripe(STRIPE_PK)



const options: { mode: "payment" | "setup" | "subscription"; amount: number; currency: string; } = {
    mode: "setup",
    amount: 0,
    currency: 'usd',
}


function MapCC({brand}) {
    if (brand.toLowerCase() === 'visa') return (<FaCcVisa />)
    if (brand.toLowerCase() === 'amex') return (<FaCcAmex />)
    if (brand.toLowerCase() === 'mastercard') return (<FaCcMastercard />)
    if (brand.toLowerCase() === 'discover') return (<FaCcDiscover />)
    return (<FaCreditCard />)
}

MapCC.propTypes = {
    brand: PropTypes.string.isRequired,

}


export function PaymentInfo() {
    const { data, isCalling, call } = useApi(GetCurrentPaymentMethod)
    return (
        <div>
            <Inline alignX="between" width="full">
                <span className="truncate underline text-lg mb-3">Add New Payment Source</span>
                <div/>
            </Inline>
            <Card className="mb-4">
                <Elements stripe={stripePromise} options={options}>
                    <StripeCheckoutForm onComplete={() => {call()}}/>
                </Elements>
            </Card>
            <Inline alignX="between" width="full">
                <span className="truncate underline text-lg mb-3">Current Payment Source</span>
                <div/>
            </Inline>
            <Card className="mb-4">
                <Loading overlay visible={isCalling} />
                <div className=" font-bold">Credit Card</div>
                <div className="italic text-color-subtle text-lg">
                    {data?.last4 ? (<Inline><span className="text-xl"><MapCC brand={data?.brand || ''} /></span> <div className="ml-2">ending in {data.last4}</div></Inline>) : (<p>No Card Set</p>)}
                </div>
            </Card>
</div>
)
}
