import React from 'react'
import { Loading, TableGroup } from '@therms/atalaya'
import { TableControls } from './TableControls'
import { TableHeadRow } from './TableHeadRow'
import { TableUserRow } from './TableUserRow'
import { useUrlState } from '@src/hooks/useUrlState'
import { useApiWithSiteId } from '@src/hooks/useApiWithSiteId'
import { GetUsersBySiteId } from '@src/services/api-client'
import { NoResults } from '@src/components/NoResults'

function UsersTable() {
  const [limit, setLimit] = useUrlState('ustlimit', 25)
  const [skip, setSkip] = useUrlState('ustskip', 0)

  const { call, data, isCalling } = useApiWithSiteId(GetUsersBySiteId, {
    limit,
    offset: skip,
  })

  // todo: need to implement pagination

  const users = data?.users

  return (
    <div className="relative">
      <Loading overlay visible={isCalling} />

      {users?.length ? (
        <TableGroup
          data={users || []}
          keyProp="id"
          Controls={<TableControls reload={call} />}
          HeadRow={TableHeadRow}
          Row={(d) => TableUserRow({ ...d, reload: call })}
          onSetRecordsPerPage={setLimit}
          onSetSkippedRecords={setSkip}
          recordsPerPage={limit}
          skippedRecords={skip}
          totalRecords={data?.total}
        />
      ) : (
        <>
          <TableControls reload={call} />
          <NoResults text="No Users" />
        </>
      )}
    </div>
  )
}

UsersTable.propTypes = {}

export { UsersTable }
