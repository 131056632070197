import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CopyText, Loading, TableGroup } from '@therms/atalaya'
import { PositionName } from '@src/modules/Positions/shared/PositionName'
import { ModUserModal } from '../../../ViewUser/components/ModUserModal'
import { useSiteUser } from '@src/modules/Users/shared/hooks/UseSiteUser'
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'
import { useAuth } from '@src/modules/Auth/shared/state'

function TableUserRow({ rowData, rowKey, reload }) {
  const [showModal, setShowModal] = useState(false)
  const { isCalling, siteUser } = useSiteUser(rowData?.id)
  const { currentSite } = useAuth()

  const currentSiteUserPosition = siteUser?.sitePositions.filter(
    (site) => site.siteId === currentSite.id,
  )

  return (
    <>
      <TableGroup.Row
        className="hover:bg-surface hover:cursor-pointer"
        rowKey={rowKey}
        onClick={() => {
          setShowModal(true)
        }}
      >
        <TableGroup.Cell>
          <div>{rowData.name}</div>
        </TableGroup.Cell>

        <TableGroup.Cell ellipse>
          <div>{rowData.phone}</div>
        </TableGroup.Cell>

        <TableGroup.Cell ellipse>
          <div className="text-color-info">
            <CopyText displayValue value={rowData.email} />
          </div>
        </TableGroup.Cell>

        <TableGroup.Cell ellipse>
          {siteUser && <PositionName id={siteUser?.positionId} />}
        </TableGroup.Cell>

        <TableGroup.Cell>
          {isCalling && <Loading />}

          {!isCalling &&
            (siteUser?.isAdmin || siteUser?.siteAdmin ? (
              <span className="text-color-positive">Admin</span>
            ) : (
              <span className="text-color-caution">User</span>
            ))}
        </TableGroup.Cell>

        <TableGroup.Cell>
          {!!currentSiteUserPosition &&
          currentSiteUserPosition[0].canWallboardLogin ? (
            <AiOutlineCheck size={20} className="text-color-positive" />
          ) : (
            <AiOutlineClose size={20} className="text-color-critical" />
          )}
        </TableGroup.Cell>
      </TableGroup.Row>
      {showModal && (
        <ModUserModal
          onComplete={reload}
          closeHandler={() => setShowModal(false)}
          userId={rowData.id}
        />
      )}
    </>
  )
}

TableUserRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  rowKey: PropTypes.any.isRequired,
  reload: PropTypes.func,
}

export { TableUserRow }
