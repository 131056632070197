import React, { useEffect, useState } from 'react'
import { GetComments } from '../../../services/api-client'
import Lightbox from "yet-another-react-lightbox"
import "yet-another-react-lightbox/styles.css"

interface CommentImageLightboxProps {
  commentIds?: string[]
  siteId: string
  onClosePress: ()=>void
}

function CommentImageLightbox({ commentIds, siteId, onClosePress }: CommentImageLightboxProps) {
  const [imageUrls, setImageUrls] = useState<string[]>([])

  useEffect(() => {
    if (commentIds?.length && siteId) {
      GetComments({ commentIds, siteId })
        .then((data) => {
            const allFileUrls = data.data.map(x => x.fileUrls)
            setImageUrls(allFileUrls.flat())
            console.log(allFileUrls.flat())
        })
    }
  }, [commentIds])

  return (
    <Lightbox
        open={imageUrls.length > 0}
        close={onClosePress}
        slides={imageUrls.map(i => ({src:i}))}
      />
  )
}

CommentImageLightbox.defaultProps = {}

export { CommentImageLightbox }
