import React from 'react'
import { ActionModal } from '@therms/atalaya'
import PropTypes from 'prop-types'

function ReactivateUserModal({ closeHandler, onComplete, userId }) {
  return (
    <ActionModal
      actionText="Reactivate"
      body="Reactivate this User?"
      onCancel={closeHandler}
      onAction={() => {
        // todo call api
        console.log('userId', userId)
        onComplete()
      }}
      title="Reactivate User"
      variant="positive"
    />
  )
}

ReactivateUserModal.propTypes = {
  closeHandler: PropTypes.func,
  onComplete: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
}

export { ReactivateUserModal }
