import React, { useState } from 'react'
import { Loading, TableGroup, Modal, Inline } from '@therms/atalaya'
import { ContactForm } from '@src/modules/Contacts/router/screens/Index/ContactForm'
import { useUrlState } from '@src/hooks/useUrlState'
import { useApi } from '@src/hooks/useApi'
import { ContactsTableRow } from '@src/modules/Contacts/router/screens/Index/TableRow'
import { ContactsTableHeadRow } from '@src/modules/Contacts/router/screens/Index/TableHeadRow'
import { ButtonWithModal } from '@src/components/ButtonWithModal'
import { NoResults } from '@src/components/NoResults'
import { GetAllContacts, GetContactsBySite } from '@src/services/api-client'

interface ContactsTableProps {
  siteId?: string
}

function ContactsTable({ siteId }: ContactsTableProps) {
  const [showFormModal, setShowFormModal] = useState(false)
  const [searchTextValue, setSearchText] = useUrlState('possrch')

  const [limit, setLimit] = useUrlState('ctlimit', 25)
  const [skip, setSkip] = useUrlState('ctskip', 0)

  const { call, data, isCalling } = useApi(
    siteId ? GetContactsBySite : GetAllContacts,
    siteId
      ? {
          includeAdminContacts: true,
          offset: skip,
          limit,
          siteId,
        }
      : { offset: skip, limit },
  )

  // todo: need to implement pagination

  return (
    <div className="relative">
      <Loading overlay visible={isCalling && !data?.contacts?.length} />

      <Inline alignX="end" className="mb-sm" width="full">
        <ButtonWithModal
          modalElement={({ closeHandler }) => (
            <Modal closeHandler={closeHandler} header="New Contact">
              <ContactForm
                onCancel={closeHandler}
                onComplete={() => {
                  closeHandler()
                  call()
                }}
                siteId={siteId}
              />
            </Modal>
          )}
          variant="main"
        >
          New Contact
        </ButtonWithModal>
      </Inline>

      {data?.contacts.length > 0 ? (
        <TableGroup
          data={data?.contacts || []}
          keyProp="id"
          HeadRow={() => <ContactsTableHeadRow siteId={siteId} />}
          Row={(props) => <ContactsTableRow siteId={siteId} {...props} />}
          onClickRow={({ rowData }) => setShowFormModal(rowData.id)}
          onSetRecordsPerPage={setLimit}
          onSetSkippedRecords={setSkip}
          recordsPerPage={limit}
          skippedRecords={skip}
          totalRecords={data?.total}
        />
      ) : (
        <NoResults text="No contacts found" />
      )}

      {showFormModal && (
        <Modal closeHandler={() => setShowFormModal(null)} header="Contact">
          <ContactForm
            onCancel={() => setShowFormModal(null)}
            onComplete={() => {
              setShowFormModal(null)

              call()
            }}
            contactId={
              typeof showFormModal !== 'boolean' ? showFormModal : undefined
            }
            siteId={siteId}
          />
        </Modal>
      )}
    </div>
  )
}

export { ContactsTable }
