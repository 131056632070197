import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { GetEscalation } from '@src/services/api-client'

function EscalationName({ id }) {
  const [data, setData] = useState(undefined)

  useEffect(() => {
    if (!id) {
      setData(undefined)
      return
    }

    GetEscalation(id, {
      cache: {
        ttl: 1000 * 30,
      },
    }).then((res) => {
      setData(res.data)
    })
  }, [id])

  return <span>{data?.name}</span>
}

EscalationName.propTypes = {
  id: PropTypes.string.isRequired,
}

export { EscalationName }
