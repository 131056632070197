import axios from 'axios'
import {buildMemoryStorage, setupCache} from 'axios-cache-interceptor'
import {WALLBOARD_JWT_KEY} from '@src/modules/wallboard/constants'

export const isProd = process.env.NODE_ENV === 'production'

export const apiClient = setupCache(
  axios.create({
    baseURL: isProd ? '/api' : 'https://devadmin.quicktask.us/api', // 'http://localhost:3000/api',
  }),
  {
    storage: buildMemoryStorage(),
    ttl: 60,
  },
)

apiClient.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${
      localStorage.getItem('jwt') || localStorage.getItem(WALLBOARD_JWT_KEY)
    }`

    // console.log('localStorage.getItem(jwt)', localStorage.getItem('jwt'))

    return config
  },
  (error) => Promise.reject(error),
)

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('jwt')
      localStorage.getItem(WALLBOARD_JWT_KEY)
      document.location.reload()
    }

    return Promise.reject(error)
  },
)

// Response types
type RefreshTokenAuthenticatedResponseBody = {
  sessionKey: string
  token: string
  userId: string
}

export type SitePosition = {
  siteId: string
  positionId: string
  canWallboardLogin?: boolean
}

export type SiteAdminPriv = {
  siteId: string
  privLevel: string
}

export const RefreshToken = () =>
  apiClient.post<RefreshTokenAuthenticatedResponseBody>('/refreshToken')

export type OrganizationGetOrganizationOkResponseBody = {
  id: string
  name: string
  cts: string
  mts: string
  shutdown?:any
  orgTemplateToMapTo?: string
}

export const GetOrganization = () =>
  apiClient.get<OrganizationGetOrganizationOkResponseBody>('/organization')

// Request types
type OrganizationModifyOrganizationRequestBody = {
  name: string
}

type OrganizationModifyOrganizationModifiedResponseBody = {
  id: string
  name: string
  cts: string
  mts: string
}

export const OrganizationModifyOrganization = (
  data: OrganizationModifyOrganizationRequestBody,
) =>
  apiClient.put<OrganizationModifyOrganizationModifiedResponseBody>(
    '/organization',
    data,
  )

export type PositionGetPositionOkResponseBody = {
  id: string

  orgTemplateData?: {
    orgTemplateProducerEnable?: boolean
    orgTemplateConsumerDisable?: true
    orgTemplateId?: string | null
  }
  title: string
  siteId: string
  organizationId: string
  positionIds?: string[]
  unScored?: boolean
  cts: string
  mts: string
}

export const PositionGetPosition = (id: string, config?: any) =>
  apiClient.get<PositionGetPositionOkResponseBody>(
    `/position?siteId=${config.siteId || ''}&id=${id}`,
    config,
  )

type TaskGroupGetTaskGroupOkResponseBody = {
  details?: string
  id: string
  siteId: string
  name: string
  organizationId: string
  uiData: {
    color: string
  }
  orgTemplateData?: {
    orgTemplateProducerEnable?: boolean
    orgTemplateConsumerDisable?: true
    orgTemplateId?: string | null
  }
  cts: string
  mts: string
}

export const TaskGroupGetTaskGroup = (id: string, config?: any) =>
  apiClient.get<TaskGroupGetTaskGroupOkResponseBody>(
    `/taskGroup?id=${id}&siteId=${config.siteId || ''}`,
    config,
  )

type GetTaskGroupsOkResponseBody = {
  total: number
  limit: number
  offset: number
  taskGroups: {
    details?: string
    id: string
    name: string
    siteId: string
    organizationId: string
    orgTemplateData?: {
      orgTemplateProducerEnable?: boolean
      orgTemplateConsumerDisable?: true
      orgTemplateId?: string | null
    }
    cts: string
    mts: string
    uiData?: {
      color?: string
    }
  }[]
}

export const GetTaskGroups = (params?: {
  offset?: number
  limit?: number
  siteId: string
}) =>
  apiClient.get<GetTaskGroupsOkResponseBody>(
    `/taskGroups?offset=${params?.offset ?? 0}&limit=${
      params?.limit ?? 25
    }&siteId=${params.siteId || ''}`,
  )

// Request types
type TaskGroupCreateTaskGroupRequestBody = {
  details: string
  siteId: string
  name: string
  uiData?: {
    color?: string
  }
}

type GetActiveBillingOkResponseBody = {
  organizationId: string;
  email: string;
  organizationName: string;
  siteIds: string[];
  perSiteRateCents: number;
  totalRateCents: number;
  nextBillingDate: string;
  paymentSource: 'CreditCard' | 'CustomInvoice';
}[];

export const GetActiveBilling = () =>
    apiClient.get<GetActiveBillingOkResponseBody>('/getActiveBilling')


type GetCurrentPaymentMethodOkResponseBody = {
  brand?: string
  last4?: string
}[];

export const GetCurrentPaymentMethod = () =>
    apiClient.get<GetCurrentPaymentMethodOkResponseBody>('/getCurrentPaymentMethod')


type GetInvoicesOkResponseBody = {
  cts: string;
  mts: string;
  status: string;
  organizationId: string;
  id: string;
  day: number;
  month: number;
  year: number;
  invoiceNumber: string;
  paidDate: string;
  totalCents: number;
  perSiteCents: number;
  paidSites: string[];
  nonPaidSites: string[];
  tempNonPaidSites: string[];
  viewLink: string;
}[];

// Create the API client call function.
export const GetInvoices = (data: {offset:number}) =>
    apiClient.get<GetInvoicesOkResponseBody>(`/getInvoices?offset=${data.offset}`)

type SetPaymentMethodRequestBody = {
  paymentMethodId: string
}


export const SetPaymentMethod = (
    data: SetPaymentMethodRequestBody,
) =>
    apiClient.post<SetPaymentMethodRequestBody>(
        '/setPaymentMethod',
        data,
    )

type ModifySubscriptionRequestBody = {
  activate: boolean
}


export const ModifySubscription = (
    data: ModifySubscriptionRequestBody,
) =>
    apiClient.post<ModifySubscriptionRequestBody>(
        '/modifySubscriptionStatus',
        data,
    )

type TaskGroupCreateTaskGroupCreatedResponseBody = {
  details?: string
  id: string
  siteId: string
  name: string
  organizationId: string
  cts: string
  mts: string
  uiData?: {
    color?: string
  }
}

export const TaskGroupCreateTaskGroup = (
  data: TaskGroupCreateTaskGroupRequestBody,
) =>
  apiClient.post<TaskGroupCreateTaskGroupCreatedResponseBody>(
    '/taskGroup',
    data,
  )

// Request types
type TaskGroupModifyTaskGroupRequestBody = {
  details?: string
  id: string
  name: string
  uiData?: {
    color?: string
  }
}

type TaskGroupModifyTaskGroupModifiedResponseBody = {
  id: string
  name: string
  siteId: string
  organizationId: string
  cts: string
  mts: string
  uiData?: {
    color?: string
  }
}

export const TaskGroupModifyTaskGroup = (
  data: TaskGroupModifyTaskGroupRequestBody,
) =>
  apiClient.put<TaskGroupModifyTaskGroupModifiedResponseBody>(
    '/taskGroup',
    data,
  )

type TaskGroupDeleteTaskGroupDeletedResponseBody = null

export const TaskGroupDeleteTaskGroup = (id: string) =>
  apiClient.delete<TaskGroupDeleteTaskGroupDeletedResponseBody>(
    `/taskGroup?id=${id}`,
  )

export const TaskDeleteTask = (id: string) => apiClient.delete(`/task?id=${id}`)

export type GetSiteSubscribedUsersOkResponseBody = {
  cts: string
  mts: string
  active: boolean
  email: string
  id: string
  name: string
  phone: string
  organizationId: string
  isAdmin: boolean
  siteAdminPrivs: SiteAdminPriv[]
  sitePositions: SitePosition[]
}[]

export const SiteGetSiteSubscribedUsers = (siteId: string) =>
  apiClient.get<GetSiteSubscribedUsersOkResponseBody>(
    `/siteSubscribedUsers?site=${siteId}`,
  )

// Request types
type PositionCreatePositionRequestBody = {
  title: string
  siteId: string
  positionIds?: string[]
  unScored?: boolean
}

type PositionCreatePositionCreatedResponseBody = {
  id: string
  siteId: string
  title: string
  organizationId: string
  positionIds?: string[]
  unScored?: boolean
  cts: string
  mts: string
}

export const PositionCreatePosition = (
  data: PositionCreatePositionRequestBody,
) =>
  apiClient.post<PositionCreatePositionCreatedResponseBody>('/position', data)

// Request types
type PositionModifyPositionRequestBody = {
  description?: string
  id: string
  title: string
  unScored?: boolean
  positionIds?: string[]
}

type PositionModifyPositionModifiedResponseBody = {
  id: string
  title: string
  siteId: string
  organizationId: string
  positionIds?: string[]
  unScored?: boolean
  cts: string
  mts: string
}

export const PositionModifyPosition = (
  data: PositionModifyPositionRequestBody,
) =>
  apiClient.put<PositionModifyPositionModifiedResponseBody>('/position', data)

type PositionDeletePositionDeletedResponseBody = null

export const PositionDeletePosition = (id: string) =>
  apiClient.delete<PositionDeletePositionDeletedResponseBody>(
    `/position?id=${id}`,
  )

export type SiteGetSiteOkResponseBody = {
  id: string
  address: string
  zipCode: string
  geolocation?: {
    lat?: number
    lng?: number
  }
  emailToCCOnContactRequests?: string[]
  name: string
  organizationId: string
  brandIds?: string[]
  timezone: string
  cts: string
  mts: string
  orgTemplateUsages?: {
    ts: string
    id: string
  }[]
  storeHours?: {
    storeOpeningRules: string[]
    storeClosingRules: string[]
  }
  wallboardSettings?: {
    qrCodeSignIn?: boolean
    showUserScores?: boolean
  }
}

export const Logout = () => apiClient.post('/logout')

export const SiteGetSite = (id: string, config?: any) =>
  apiClient.get<SiteGetSiteOkResponseBody>(`/site?id=${id}`, config)

// Request types
export type SiteCreateSiteRequestBody = {
  address?: string
  emailToCCOnContactRequests: string[]
  zipCode?: string
  name: string
  timezone: string
  organizationId?: string
  brandIds?: string[]
  storeHours?: {
    storeOpeningRules: string[]
    storeClosingRules: string[]
  }
  wallboardSettings?: {
    qrCodeSignIn?: boolean
    showUserScores?: boolean
  }
}

type SiteCreateSiteCreatedResponseBody = {
  id: string
  address: string
  emailToCCOnContactRequests?: string[]
  zipCode: string
  name: string
  organizationId: string
  brandIds?: string[]
  timezone: string
  cts: string
  mts: string
  wallboardSettings?: {
    qrCodeSignIn?: boolean
    showUserScores?: boolean
  }
}

export const SiteCreateSite = (data: SiteCreateSiteRequestBody) =>
  apiClient.post<SiteCreateSiteCreatedResponseBody>('/site', data)

// Request types
type SiteModifySiteRequestBody = {
  id?: string
  address?: string
  emailToCCOnContactRequests?: string[]
  zipCode?: string
  brandIds?: string[]
  name: string
  timezone: string
  storeHours?: {
    storeOpeningRules: string[]
    storeClosingRules: string[]
  }
  wallboardSettings?: {
    qrCodeSignIn?: boolean
    showUserScores?: boolean
  }
}

type SiteModifySiteModifiedResponseBody = {
  id: string
  address: string
  zipCode: string
  name: string
  organizationId: string
  brandIds?: string[]
  timezone: string
  cts: string
  mts: string
  wallboardSettings?: {
    qrCodeSignIn?: boolean
    showUserScores?: boolean
  }
}

export const SiteModifySite = (data: SiteModifySiteRequestBody) =>
  apiClient.put<SiteModifySiteModifiedResponseBody>('/site', data)

type SiteDeleteSiteDeletedResponseBody = null

export const SiteDeleteSite = (id: string) =>
  apiClient.delete<SiteDeleteSiteDeletedResponseBody>(`/site?id=${id}`)

type SiteDeletePositionDeletedResponseBody = null

export const SiteDeletePosition = () =>
  apiClient.delete<SiteDeletePositionDeletedResponseBody>('/site?id=<uuid>')

export type UserGetUserOkResponseBody = {
  active: boolean
  email: string
  id: string
  isAdmin: boolean
  name: string
  organizationId: string
  phone: string
  siteAdminPrivs: SiteAdminPriv[]
  sitePositions: SitePosition[]
  siteIdsRecReportsFrom?: string[]
  cts: string
  mts: string
}

export const AdminSetPassword = (data: {
  userId: string
  password: string
  siteId: string
}) => apiClient.post<void>('/adminSetPassword', data)

export const UserGetUser = (
  params: { userId: string; siteId: string },
  config?: any,
) =>
  apiClient.get<UserGetUserOkResponseBody>(
    `/user?userId=${params.userId}&siteId=${params.siteId}`,
    config,
  )

export const UserGetSelf = () =>
  apiClient.get<UserGetUserOkResponseBody>(`/getSelf`)

// Request types
type UserModUserAtSiteRequestBody = {
  email: string
  siteId: string
  action: 'add' | 'remove'
  positionId: string
}

type UserCreateUserCreatedResponseBody = {
  active: boolean
  email: string
  id: string
  name: string
  organizationId: string
  isAdmin: boolean
  phone: string
  cts: string
  mts: string
  siteAdminPrivs: SiteAdminPriv[]
  sitePositions: SitePosition[]
}

export const UserModUserAtSite = (data: UserModUserAtSiteRequestBody) =>
  apiClient.post<UserCreateUserCreatedResponseBody>(
    '/userSitePositionMod',
    data,
  )

type UserModUserDetailsRequestBody = {
  email: string
  siteId: string
  name: string
  phone: string
}

export const UserModUserDetails = (data: UserModUserDetailsRequestBody) =>
  apiClient.post<UserCreateUserCreatedResponseBody>('/userDetailsMod', data)

type UserModUserReportsRequestBody = {
  email: string
  siteId: string
  action: 'add' | 'remove'
}

export const UserModUserReports = (data: UserModUserReportsRequestBody) =>
  apiClient.post<UserCreateUserCreatedResponseBody>('/userReportMod', data)

type UserModUserSiteAdminRequestBody = {
  email: string
  siteId: string
  privLevel: 'read' | 'readWrite'
  action: 'add' | 'remove'
}

export const UserModUserSiteAdmin = (data: UserModUserSiteAdminRequestBody) =>
  apiClient.post<UserCreateUserCreatedResponseBody>('/userSiteAdminMod', data)

type UserModUserGlobalAdminRequestBody = {
  email: string
  siteId: string
  action: 'add' | 'remove'
}

export const UserModUserGlobalAdmin = (
  data: UserModUserGlobalAdminRequestBody,
) =>
  apiClient.post<UserCreateUserCreatedResponseBody>('/userGlobalAdminMod', data)

// Request types
type UserModifyUserRequestBody = {
  active?: boolean
  id: string
  isAdmin?: boolean
  email?: string
  name?: string
  phone?: string
  siteAdminPrivs: SiteAdminPriv[]
  sitePositions: SitePosition[]
  siteIdsRecReportsFrom?: string[]
}

type UserModifyUserModifiedResponseBody = {
  active: boolean
  email: string
  id: string
  name: string
  organizationId: string
  isAdmin: boolean
  phone: string
  siteAdminPrivs: SiteAdminPriv[]
  sitePositions: SitePosition[]
  cts: string
  mts: string
}

export const UserModifyUser = (data: UserModifyUserRequestBody) =>
  apiClient.put<UserModifyUserModifiedResponseBody>('/user', data)

type UserWallboardLoginModRequestBody = {
  email: string
  siteId: string
  action: 'add' | 'remove'
}

export const UserWallboardLoginMod = (data: UserWallboardLoginModRequestBody) =>
  apiClient.post('/userWallboardLoginMod', data)

type UserDeletePositionDeletedResponseBody = null

export const UserDeletePosition = () =>
  apiClient.delete<UserDeletePositionDeletedResponseBody>('/user?id=<uuid>')

type GetPositionsOkResponseBody = {
  total: number
  limit: number
  offset: number
  positions: {
    id: string
    title: string
    organizationId: string
    cts: string
    mts: string
  }[]
}

export const GetPositions = (params?: {
  offset?: number
  limit?: number
  siteId: string
}) =>
  apiClient.get<GetPositionsOkResponseBody>(
    `/positions?offset=${params?.offset ?? 0}&limit=${
      params?.limit ?? 25
    }&siteId=${params.siteId || ''}`,
  )

export type GetSitesOkResponseBody = {
  total: number
  limit: number
  offset: number
  sites: {
    id: string
    address: string
    zipCode: string
    name: string
    organizationId: string
    positionIds: string[]
    timezone: string
    cts: string
    mts: string
    positions: {
      id: string
      title: string
      organizationId: string
      cts: string
      mts: string
    }[]
  }[]
}

export const GetSites = (params?: {
  offset?: number
  limit?: number
  withPositions?: boolean
}) =>
  apiClient.get<GetSitesOkResponseBody>(
    `/sites?offset=${params?.offset ?? 0}&limit=${
      params?.limit ?? 25
    }&resolvePositions=${params?.withPositions || false}`,
  )

export type GetUsersOkResponseBody = {
  total: number
  limit: number
  offset: number
  users: {
    active: boolean
    email: string
    id: string
    name: string
    organizationId: string
    isAdmin: boolean
    siteAdminPrivs: SiteAdminPriv[]
    sitePositions: SitePosition[]
    cts: string
    mts: string
    siteIdsRecReportsFrom?: string[]
  }[]
}

export const GetUsersBySiteId = (params?: {
  offset?: number
  limit?: number
  siteId: string
}) =>
  apiClient.get<GetUsersOkResponseBody>(
    `/users?siteId=${params.siteId || ''}&offset=${params?.offset || 0}&limit=${
      params?.limit || 25
    }`,
  )

export const GetOrganizationUsers = (params?: {
  offset?: number
  limit?: number
}) =>
  apiClient.get<GetUsersOkResponseBody>(
    `/orgUsers?offset=${params?.offset || 0}&limit=${params?.limit || 25}`,
  )

// Request types
type LoginUserRequestBody = { email: string; password: string }

type LoginUserAuthenticatedResponseBody = {
  token: string
  userId: string
  sessionKey: string
}

export const LoginUser = (data: LoginUserRequestBody) =>
  apiClient.post<LoginUserAuthenticatedResponseBody>('/login', data)

interface SuperuserRequestBody {
  superuserUsername: string
  superuserPassword: string
}

export const SuperuserGetAllOrgs = (data: SuperuserRequestBody) =>
  apiClient.post<OrganizationGetOrganizationOkResponseBody[]>(
    '/superuserGetAllOrgs',
    data,
  )

interface SuperuserGetJWTBody extends SuperuserRequestBody {
  organizationId: string
}


interface SuperuserSetPricingBody extends SuperuserRequestBody {
  organizationId: string
  pricing: string
}

export const SuperuserGetOrgOwnerJwt = (data: SuperuserGetJWTBody) =>
  apiClient.post<LoginUserAuthenticatedResponseBody>(
    '/superuserGetOwnerJwt',
    data,
  )


export const SuperuserDeactivateOrg = (data: SuperuserGetJWTBody) =>
    apiClient.post<void>(
        '/superuserDeactivateOrg',
        data,
    )

export const SuperuserSetPricing = (data: SuperuserSetPricingBody) =>
    apiClient.post<void>(
        '/superuserSetPricing',
        data,
    )

export type Task = {
  id: string
  organizationId: string
  orgTemplateData?: {
    /** Only used for producers. If `true` then any orgTemplate "followers" will get this Task */
    orgTemplateProducerEnable?: boolean
    /** Only used for consumers that are following an orgTemplateId. If `true` then this Task will not show in TaskCompletions in the future.  */
    orgTemplateConsumerDisable?: boolean
    /** The producer that this consumer is following */
    orgTemplateId?: string
  }
  title: string
  description: string
  escalationIds: string[]
  positionIds: string[]
  canSkip: boolean
  isAnyTime?: boolean
  verification: string
  verificationSecret?: string
  missActions: TaskMissAction[]
  onCompleteEmailPositionIds: string[]
  scheduleStrings: string[]
  siteId: string
  taskGroupIds: string[]
  taskGroups?: TaskGroupGetTaskGroupOkResponseBody[]
}

export type TaskMissAction = {
  /**
   * notification is intended to be to a manager or another position
   * reminder is intended to be for the positionId that the Task is assigned to
   */
  actionType: 'notification' | 'reminder'
  positionIds: string[]
  pushTypes: string[]
  firstOcurranceDelaySeconds: number
  subsequentRecurrenceDelaySeconds: number
  maxOccurrences: number
  inStoreOnly: boolean
}

type GetTasksOkResponseBody = {
  total: number
  limit: number
  offset: number
  tasks: Task[]
}

export const GetTasks = (params?: {
  offset?: number
  limit?: number
  taskGroupId?: string
  siteId: string
}) =>
  apiClient.get<GetTasksOkResponseBody>(
    `/tasks?siteId=${params.siteId || ''}&offset=${params?.offset ?? 0}&limit=${
      params?.limit ?? 25
    }${params.taskGroupId ? `&includesTaskGroupId=${params.taskGroupId}` : ''}`,
  )

export const TaskGetTask = (
  params: {
    siteId: string
    taskId: string
    withPositions?: boolean
  },
  config?: any,
) =>
  apiClient.get<Task>(
    `/task?$siteId=${params.siteId || ''}&id=${
      params.taskId
    }&resolvePositions=${
      params?.withPositions || false
    }&resolveTaskGroups=true&resolveEscalations=true`,
    config,
  )

// Request types
export type TaskCreateTaskCreateRequestBody = {
  title: string
  description: string
  escalationIds?: string[]
  orgTemplateData?: {
    /** Only used for producers. If `true` then any orgTemplate "followers" will get this Task */
    orgTemplateProducerEnable?: boolean
    /** Only used for consumers that are following an orgTemplateId. If `true` then this Task will not show in TaskCompletions in the future.  */
    orgTemplateConsumerDisable?: boolean
    /** The producer that this consumer is following */
    orgTemplateId?: string
  }
  positionIds: string[]
  siteId: string
  scheduleStrings: string[]
  canSkip: boolean
  isAnyTime?: boolean
  verification: string
  onCompleteEmailPositionIds: string[]
  missActions: {
    positionIds: string[]
    pushTypes: string[]
    firstOcurranceDelaySeconds: number
    subsequentRecurrenceDelaySeconds: number
    maxOccurrences: number
    inStoreOnly: boolean
  }[]
  taskGroupIds: string[]
  difficulty: 'easy' | 'hard' | 'medium'
}

type TaskCreateTaskCreatedResponseBody = {
  id: string
  organizationId: string
  title: string
  description: string
  positionIds: string[]
  siteId: string
  canSkip: boolean
  isAnyTime?: boolean
  verification: string
  verificationSecret?: string
  missActions: TaskMissAction[]
  onCompleteEmailPositionIds: string[]
  taskGroupIds?: string[]
}

export const TaskCreateTask = (data: TaskCreateTaskCreateRequestBody) =>
  apiClient.post<TaskCreateTaskCreatedResponseBody>('/task', data)

// Request types
export type TaskModifyTaskRequestBody = {
  id: string
  orgTemplateData?: {
    /** Only used for producers. If `true` then any orgTemplate "followers" will get this Task */
    orgTemplateProducerEnable?: boolean
    /** Only used for consumers that are following an orgTemplateId. If `true` then this Task will not show in TaskCompletions in the future.  */
    orgTemplateConsumerDisable?: boolean
    /** The producer that this consumer is following */
    orgTemplateId?: string
  }
  title?: string
  description?: string
  escalationIds?: string[]
  positionIds?: string[]
  scheduleStrings: string[]
  canSkip?: boolean
  isAnyTime?: boolean
  verification?: string
  missActions?: TaskMissAction[]
  onCompleteEmailPositionIds?: string[]
  taskGroupIds: string[]
  difficulty: 'easy' | 'hard' | 'medium'
}

type TaskModifyTaskModifiedResponseBody = {
  id: string
  title?: string
  description?: string
  positionIds?: string[]
  siteId: string
  canSkip?: boolean
  isAnyTime?: boolean
  verification?: string
  verificationSecret?: string
  missActions?: TaskMissAction[]
  onCompleteEmailPositionIds?: string[]
  taskGroupIds?: string[]
}

export const TaskModifyTask = (data: TaskModifyTaskRequestBody) =>
  apiClient.put<TaskModifyTaskModifiedResponseBody>('/task', data)

type RegisterCreateRegisterCreateRequestBody = {
  email: string
  name: string
  phone: string
  organizationName: string
}

export const RegisterOrganization = (
  data: RegisterCreateRegisterCreateRequestBody,
) => apiClient.post('/register', data)

export const ResetPassword = (data: { email: string }) =>
  apiClient.post('/resetPassword', data)

export type TasksLoadTaskCompletionsOkResponseBody = {
  total: number
  limit: number
  offset: number
  completions: {
    id: string
    organizationId: string
    commentIds: string[]
    taskId: string
    siteId: string
    title: string
    description: string
    canSkip: boolean
    isAnyTime?: boolean
    isComplete: boolean
    isSkipped: boolean
    positionIds: string[]
    positions: {
      id: string
      title: string
      organizationId: string
      positionIds: string[]
    }[]
    evaluation?: {
      sentiment: string
      evaluatingUserId: string
      ts: string
    }
    onSiteAtDueDateUserIds?: string[]
    completedAtTime?: string
    completedByUserId?: string
    taskGroupIds: string[]
    dueDate: string
    verificationFileKeys?: string[]
    verificationFileUrls?: string[]
  }[]
}

export const TasksLoadTaskCompletions = (params: {
  offset?: number
  limit?: number
  addVerificationFileUrls?: boolean
  endDate: string
  startDate: string
  status?:
    | 'upcoming'
    | 'missed'
    | 'skipped'
    | 'complete'
    | 'processing'
    | 'failed'
  showOnsiteUsersForMissed?: boolean
  showOnlyMissed?: boolean
  showOnlySkipped?: boolean
  siteId?: string
  taskId?: string
  taskGroupId?: string
  userId?: string
}) =>
  apiClient.get<TasksLoadTaskCompletionsOkResponseBody>(
    `/loadTaskCompletions?offset=${params.offset ?? 0}&limit=${
      params?.limit ?? 25
    }&endDate=${params.endDate}&startDate=${params.startDate}${
      params.siteId ? `&site=${params.siteId}` : ''
    }&addVerificationFileUrls=${!!params.addVerificationFileUrls}&showOnlyMissed=${!!params.showOnlyMissed}&showOnlySkipped=${!!params.showOnlySkipped}${
      params.status ? `&status=${params.status}` : ''
    }${params.taskId ? `&showForTaskId=${params.taskId}` : ''}${
      params.taskGroupId ? `&showForTaskGroupId=${params.taskGroupId}` : ''
    }${
      params.showOnsiteUsersForMissed ? `&showOnsiteUsersForMissed=true` : ''
    }${params.userId ? `&showForUserId=${params.userId}` : ''}`,
  )

export const EvaluateTaskCompletion = (data: {
  completionId: string
  sentiment: 'positive' | 'negative'
  siteId: string
  note: string
}) => apiClient.post('/completionEval', data)

// Request types
export type SiteWhoWasThereResponseBody = {
  userId: string
  onsiteStart: string
  onsiteEnd?: string
  onsiteTimedOut?: boolean
}[]

export const SiteGetWhoWasThere = (params: {
  endDate: string
  siteId: string
  startDate: string
}) =>
  apiClient.get<SiteWhoWasThereResponseBody>(
    `/whoWasThere?siteId=${params.siteId}&endDate=${params.endDate}&startDate=${params.startDate}`,
  )

export type GetEscalationsResponseBody = {
  total: number
  limit: number
  offset: number
  escalations: {
    cts: string
    mts: string
    id: string
    organizationId: string

    orgTemplateData?: {
      orgTemplateProducerEnable?: boolean
      orgTemplateConsumerDisable?: true
      orgTemplateId?: string | null
    }

    name: string
    details: string
    notifyPositionIds: string[]
    pushTypes: ('sms' | 'email' | 'pushNotification')[]
  }[]
}

export const GetEscalations = (params?: {
  siteId: string
  offset?: number
  limit?: number
}) =>
  apiClient.get<GetEscalationsResponseBody>(
    `/escalations?siteId=${params.siteId || ''}&offset=${
      params.offset || 0
    }&limit=${params.limit || 25}`,
  )

export const GetEscalation = (id: string, config?: any) =>
  apiClient.get<GetEscalationsResponseBody['escalations'][number]>(
    `/escalation?siteId=${config.siteId || ''}&id=${id}`,
    config,
  )

export const CreateEscalation = (data: {
  name: string
  details: string
  siteId: string
  notifyPositionIds: string[]
  pushTypes: ('sms' | 'email' | 'pushNotification')[]
}) => apiClient.post('/escalation', data)

export const ModifyEscalation = (data: {
  id: string
  name: string
  details: string
  notifyPositionIds: string[]
  pushTypes: ('sms' | 'email' | 'pushNotification')[]
}) => apiClient.put('/escalation', data)

export const DeleteEscalation = (id: string) =>
  apiClient.delete(`/escalation?id=${id}`)

export type EmailEscOccurrenceToExternalOkRequestBody = {
  email: string
  // escOccurrenceId: string
  escOccuranceId: string
  extraMessage?: string
  siteId: string
}

export const EmailEscOccurrenceToExternal = (
  escOccurrence: EmailEscOccurrenceToExternalOkRequestBody,
) => apiClient.post('/emailEscOccurrenceToExternal', escOccurrence)

export type GetEscOccurencesByTimeRangeResponseBody = {
  total: number
  limit: number
  offset: number
  escOccurrences: [
    {
      commentIds?: string[]
      siteId: string
      organizationId: string
      timestamp: string
      escalationId: string
      verificationFileKeys: string[]
      cts: string
      mts: string
      id: string
      escalatingUserId?: string
      resolvingUserId?: string
      resolvedDate?: string
      adminNote?: string
      triggeredBy: {
        triggerType: 'Task'
        triggerId: string
      }
      verificationFileUrls?: string[]
    },
  ]
}

export const GetEscOccurencesByTimeRange = (
  params: {
    addVerificationFileUrls?: boolean
    endDate: string
    isResolved?: boolean
    limit?: number
    offset?: number
    startDate: string
    siteId?: string
  },
  config?: any,
) =>
  apiClient.get<GetEscOccurencesByTimeRangeResponseBody>(
    `/escOccurrencesByTimeRange?offset=${params.offset ?? 0}&limit=${
      params?.limit ?? 25
    }&endDate=${params.endDate}&startDate=${params.startDate}${
      params.siteId ? `&site=${params.siteId}` : ''
    }&addVerificationFileUrls=${!!params.addVerificationFileUrls}&isResolved=${!!params.isResolved}`,
    config,
  )

export const ResolveEsOccurrence = (data: {
  siteId: string
  escOccuranceId: string
  adminNote: string
}) => apiClient.post('/resolveEscOccurrence', data)

export type TaskMarkCompleteBody = {
  completionId: string
  siteId: string
  skipped: boolean
  verificationFileKeys?: string[]
  userNote?: string
  triggerEscalation?: string
}

export const TaskMarkComplete = (data: TaskMarkCompleteBody) =>
  apiClient.post('/markComplete', data)

export const CheckForBotToken = (botTargetKey: string) =>
  apiClient.get<{
    siteId?: string
    token?: string
  }>(`/checkForBotToken?botTargetKey=${botTargetKey}`)

export const AuthorizeSiteReadBot = (data: {
  botTargetKey: string
  siteId: string
}) => apiClient.post(`/authorizeSingeSiteReadBot`, data)

interface WeightedStats {
  tasksDueWhileOnSite: number
  tasksCompleted: number
  tasksSkipped: number
  tasksMissed: number
  positiveEvals: number
  negativeEvals: number
  composite: number
  adjustedComposite: number
  normalizedComposite: number
}

interface Conduct {
  userId: string
  unweightedStats: WeightedStats
  weightedStats: WeightedStats
}

export interface GetConductReportResponseBody {
  userConduct: Conduct[]
  averageConduct: Conduct
  taskCompletionPct: number
}

export const GetConductReport = (params: {
  endDate: string
  limit?: number
  offset?: number
  startDate: string
  siteId: string
}) =>
  apiClient.get<GetConductReportResponseBody>(
    `/conductReport?endDate=${params.endDate}&startDate=${params.startDate}&siteId=${params.siteId}`,
  )

export type GetCommentsResponseBody = Array<{
  cts: string
  mts: string
  id: string
  organizationId: string
  userId: string
  fileKeys: string[]
  fileUrls: string[]
  userNote: string
}>

export const GetComments = (params: { commentIds: string[]; siteId: string }) =>
  apiClient.get<GetCommentsResponseBody>(
    `/loadCommentsByIds?commentIds=${params.commentIds.join(',')}&siteId=${
      params.siteId
    }`,
  )

export type GetBrandsResponseBody = {
  name:string
  id:string
}[]


export const GetBrands = () =>
    apiClient.get<GetBrandsResponseBody>(
        `/getBrands`,
    )

export type GetAllOrgTemplatesResponseBody = {
  total: number
  limit: number
  offset: number
  orgTemplates: {
    id: string
    name: string
    brandIds?: string[]
  }[]
}

export const GetAllOrgTemplates = (data: { siteId: string }) =>
  apiClient.get<GetAllOrgTemplatesResponseBody>(
    `/allOrgTemplates?limit=100&site=${data.siteId}`,
  )

export type OrgTemplateUsage = {
  orgTemplateId: string
  name: string
  pullDates: Date[]
}

export type GetOrgTemplateUsagesResponseBody = {
  total: number
  limit: number
  offset: number
  orgTemplateUsages: OrgTemplateUsage[]
}

export const GetOrgTemplateUsages = () =>
  apiClient.get<GetOrgTemplateUsagesResponseBody>(
    `/orgTemplateUsages?limit=100`,
  )

export interface UseOrgTemplateBody {
  orgTemplateId: string
  siteId: string
}

export const UseOrgTemplate = (data: UseOrgTemplateBody) =>
  apiClient.post('/useOrgTemplate', data)

export const RemoveOrgTemplate = (data: UseOrgTemplateBody) =>
  apiClient.post('/removeOrgTemplate', data)

export interface GetRollingStatsReportResponseBody {
  total: number
  limit: number
  offset: number
  rollingSiteStats: {
    orgTemplateId: string
    siteId: string
    organizationId: string
    aggregationTimeframe: string
    aggregationPeriodStartTime: Date
    aggregationPeriodEndTime: Date
    taskCompletionStatsWeighted: {
      completedTasks: number
      missedTasks: number
      skippedTasks: number
      completionPct: number
    }
    taskCompletionStatsUnweighted: {
      completedTasks: number
      missedTasks: number
      skippedTasks: number
      completionPct: number
    }
    mostCommonEscalations: {
      escalationId: string
      occurrences: number
      averageResolutionSeconds: number
    }[]
  }[]
}

export interface GetRollingStatsReportRequestBody {
  timeframe: 'Rolling1Day' | 'Rolling7Day' | 'Rolling30Day'
}

export const GetRollingStatsReport = (data: GetRollingStatsReportRequestBody) =>
  apiClient.get<GetRollingStatsReportResponseBody>(
    `/rollingStatsReport?timeframe=${data.timeframe}`,
  )

export interface GetRollingStatsBreakdownRequestBody {
  siteId: string
  taskStatus: 'complete' | 'missed' | 'skipped' | 'upcoming'
  timeframe: 'Rolling1Day' | 'Rolling7Day' | 'Rolling30Day'
  orgTemplateId?: string
}

export interface GetRollingStatsBreakdownResponseBody {
  breakdownByTopTenTasks: {
    taskId: string
    taskName: string
    qtyOfOccurrences: number
  }[]

  breakdownByTaskGroup: {
    taskGroupId: string
    taskGroupName: string
    qtyOfOccurrences: number
  }[]
}

export const GetRollingStatsBreakdown = (
  data: GetRollingStatsBreakdownRequestBody,
) =>
  apiClient.get<GetRollingStatsBreakdownResponseBody>(
    '/rollingStatsBreakdown',
    { params: data },
  )

export type GetContactsResponseBody = {
  total: number
  limit: number
  offset: number
  contacts: {
    adminOnly: boolean
    cts: string
    mts: string
    id: string
    isOrgWide?: boolean
    organizationId: string
    title: string
    description: string
    phone: string
    email: string
    siteId: string
  }[]
}

export type GetContactsBySiteRequestBody = {
  includeAdminContacts: boolean
  offset?: number
  limit?: number
  siteId: string
}

export const GetContactsBySite = (params: GetContactsBySiteRequestBody) =>
  apiClient.get<GetContactsResponseBody>(`/contactsBySite`, { params })

export type GetAllContactsRequestBody = {
  offset?: number
  limit?: number
}

export const GetAllContacts = (params: GetAllContactsRequestBody) =>
  apiClient.get<GetContactsResponseBody>(`/allContacts`, { params })

export type GetContactResponseBody = {
  adminOnly: boolean
  cts: string
  mts: string
  id: string
  isOrgWide: boolean
  organizationId: string
  title: string
  description: string
  phone: string
  email: string
  siteId: string
}

export const GetContact = (contactId: string) =>
  apiClient.get<GetContactResponseBody>(`/contact?id=${contactId}`)

export type CreateContactRequestBody = {
  adminOnly?: boolean
  title: string
  description: string
  isOrgWide?: boolean
  phone?: string
  email?: string
  siteIds: string[]
}

export type CreateContactResponseBody = {
  adminOnly: boolean
  cts: string
  mts: string
  id: string
  isOrgWide?: boolean
  organizationId: string
  title: string
  description: string
  phone: string
  email: string
  siteIds: string[]
}

export const CreateContact = (contact: CreateContactRequestBody) =>
  apiClient.post<CreateContactResponseBody>(`/contact`, contact)

export type ModifyContactRequestBody = {
  adminOnly: boolean
  id: string
  isOrgWide?: boolean
  title: string
  description: string
  phone: string
  email: string
  siteIds: string[]
}

export type ModifyContactResponseBody = {
  adminOnly: boolean
  cts: string
  mts: string
  id: string
  isOrgWide?: boolean
  organizationId: string
  title: string
  description: string
  phone: string
  email: string
  siteIds: string[]
}

export const ModifyContact = (contact: ModifyContactRequestBody) =>
  apiClient.put<ModifyContactResponseBody>(`/contact`, contact)

export const DeleteContact = (contactId: string) =>
  apiClient.delete<null>(`/contact?id=${contactId}`)

export type SendTestEmailToExternalRequestBody = {
  email: string
  extraMessage?: string
  siteId: string
}

export const SendTestEmailToExternal = (
  body: SendTestEmailToExternalRequestBody,
) => apiClient.post('/sendTestEmailToExternal', body)

export type TaskGroup = {
  cts: string
  mts: string
  id: string
  orgTemplateData: {
    orgTemplateProducerEnable: true
    orgTemplateId: string
    modifiedByConsumer: true
  }
  siteId: string
  name: string
  organizationId: string
  details: string
  uiData: {
    color: string
  }
}

export type Completion = {
  cts: string
  mts: string
  id: string
  organizationId: string
  taskId: string
  siteId: string
  title: string
  description: string
  canSkip: true
  isAnyTime?: boolean
  isComplete: true
  isSkipped: true
  positionIds: string[]
  positions: [
    {
      cts: string
      mts: string
      id: string
      orgTemplateData: {
        orgTemplateProducerEnable: true
        orgTemplateId: string
        modifiedByConsumer: true
      }
      siteId: string
      title: string
      organizationId: string
      positionIds: string[]
      unScored: true
    },
  ]
  completedByUserId: string
  completedAtTime: string
  dueDate: string
  taskGroupIds: string[]
  escalationIds: string[]
  onSiteAtDueDateUserIds: string[]
  commentIds: string[]
  evaluation: {
    sentiment: string
    evaluatingUserId: string
    ts: string
  }
}

export type GroupedCompletions = {
  taskGroupsById: {
    additionalProp1: TaskGroup
    additionalProp2: TaskGroup
    additionalProp3: TaskGroup
  }
  completionsByTaskGroupId: {
    byGroup: {
      additionalProp1: Completion[]
      additionalProp2: Completion[]
      additionalProp3: Completion[]
    }
    noGroup: Completion[]
  }
  totalCompletions: number
}

export type GetSiteSnapshotForAdminResponseBody = {
  missed: GroupedCompletions
  upcoming: GroupedCompletions
  recent: GroupedCompletions
  subscribed: UserGetUserOkResponseBody[]
}

export const SiteSnapshotForAdmin = (siteId: string) =>
  apiClient.get<GetSiteSnapshotForAdminResponseBody>(
    `/siteSnapshotForAdmin?siteId=${siteId}`,
  )

export const WallboardGetLoginToken = (siteId: string) =>
  apiClient.get(`/wallboardGetLoginToken?siteId=${siteId}`)

export const WallboardLoginUsers = (token: string) =>
  apiClient.get(`/wallboardLoginUsers`, { params: token })

export type GetEmailPreferencesResponseBody = {
  cts: string
  mts: string
  email: string
  id: string
  preferences: {
    receiveEmails: boolean
  }
}

export const GetEmailPreferences = (id: string) =>
  apiClient.get<GetEmailPreferencesResponseBody>('/getEmailPrefs', {
    params: id,
  })

export const ModifyEmailPreferences = (emailPreferences: {
  id: string
  preferences: {
    receiveEmails: boolean
  }
}) => apiClient.put('/modEmailPrefs', emailPreferences)
