import { useState } from 'react'

export function useLocalStorage<T = any>(
  key: string,
  initialValue?: T,
): [T, (value: T) => void] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    let foundItem: unknown
    try {
      foundItem = window?.localStorage?.getItem(key)

      return foundItem ? JSON.parse(foundItem as string) : initialValue
    } catch (err) {
      return typeof foundItem === 'string' ? foundItem : initialValue
    }
  })

  const setValue = (value: T) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value

      window.localStorage.setItem(
        key,
        typeof valueToStore !== 'string'
          ? JSON.stringify(valueToStore)
          : valueToStore,
      )

      setStoredValue(valueToStore)
    } catch (error) {
      // todo: handle the error better
      console.error(error)
    }
  }

  return [storedValue, setValue]
}
