import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { USERS_ROUTES } from '../constants'
import { UserForm } from '@src/modules/Users/router/screens/UserForm'
import { UsersIndex } from '@src/modules/Users/router/screens/Index'
import { ViewUser } from '@src/modules/Users/router/screens/ViewUser'
import { ErrorBoundary } from 'react-error-boundary'
import { Button } from '@therms/atalaya'
import * as Sentry from '@sentry/react'

function UsersRoutes() {
  return (
    <Switch>
      <Route
        render={() => (
          <ErrorBoundary
            onError={(error, componentStack) => {
              Sentry.withScope((scope) => {
                scope.setExtras(componentStack)
                Sentry.captureException(error)
              })
            }}
            fallbackRender={({ error, resetErrorBoundary }) => (
              <div>
                <p>Something went wrong:</p>
                <pre>{error.message}</pre>
                <Button onClick={resetErrorBoundary}>Try again</Button>
              </div>
            )}
          >
            <UserForm />
          </ErrorBoundary>
        )}
        path={USERS_ROUTES.EDIT_USER}
      />
      <Route
        render={() => (
          <ErrorBoundary
              onError={(error, componentStack) => {
                  Sentry.withScope((scope) => {
                      scope.setExtras(componentStack)
                      Sentry.captureException(error)
                  })
              }}
            fallbackRender={({ error, resetErrorBoundary }) => (
              <div>
                <p>Something went wrong:</p>
                <pre>{error.message}</pre>
                <Button onClick={resetErrorBoundary}>Try again</Button>
              </div>
            )}
          >
            <UserForm />
          </ErrorBoundary>
        )}
        path={USERS_ROUTES.NEW_USER}
      />
      <Route
        render={() => (
          <ErrorBoundary
              onError={(error, componentStack) => {
                  Sentry.withScope((scope) => {
                      scope.setExtras(componentStack)
                      Sentry.captureException(error)
                  })
              }}
            fallbackRender={({ error, resetErrorBoundary }) => (
              <div>
                <p>Something went wrong:</p>
                <pre>{error.message}</pre>
                <Button onClick={resetErrorBoundary}>Try again</Button>
              </div>
            )}
          >
            <ViewUser />
          </ErrorBoundary>
        )}
        path={USERS_ROUTES.VIEW_USER}
      />
      <Route
        render={() => (
          <ErrorBoundary
              onError={(error, componentStack) => {
                  Sentry.withScope((scope) => {
                      scope.setExtras(componentStack)
                      Sentry.captureException(error)
                  })
              }}
            fallbackRender={({ error, resetErrorBoundary }) => (
              <div>
                <p>Something went wrong:</p>
                <pre>{error.message}</pre>
                <Button onClick={resetErrorBoundary}>Try again</Button>
              </div>
            )}
          >
            <UsersIndex />
          </ErrorBoundary>
        )}
      />
    </Switch>
  )
}

export { UsersRoutes }
