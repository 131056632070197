import React from 'react'
import { FormLayout } from '@therms/atalaya'
import { FormikText } from '@src/components/FormikFields/FormikText'
import { FormikEmail } from '@src/components/FormikFields/FormikEmail'
import { FormikCheckbox } from '@src/components/FormikFields/FormikCheckbox'

function UserProfileFormSection() {
  return (
    <FormLayout dividers="space">
      <FormLayout.Section heading="Account">
        <FormikCheckbox label="Active" name="active" />

        <FormikText hint="required" label="Name" name="name" />

        <FormikText
          hint="required"
          label="Phone"
          name="phone"
          subText="This will be used for text message reminders/notifications"
        />

        <FormikEmail hint="required" label="Email" name="email" />
      </FormLayout.Section>
    </FormLayout>
  )
}

UserProfileFormSection.propTypes = {}

export { UserProfileFormSection }
