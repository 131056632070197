import React, { useState } from 'react'
import { UserConductStats } from '@src/modules/Sites/router/screens/ViewSite/components/UserConductStats'
import dayjs from 'dayjs'
import { DateTimeRangePicker } from '@therms/atalaya'

interface ViewSiteStatsProps {
  siteId: string
}

function ViewSiteStats({ siteId }: ViewSiteStatsProps) {
  const [dateRange, setDateRange] = useState<{
    start: string
    end: string
  }>({
    start: dayjs().startOf('month').toISOString(),
    end: dayjs().endOf('month').toISOString(),
  })

  return (
    <div>
      <div className="text-lg">Site Stats</div>

      <div className="my-sm">
        <DateTimeRangePicker
          mode="date"
          onChangeValue={({ end, start }) => {
            if (!start || !end) return

            const d = {
              start,
              end,
            }

            setDateRange(d)
          }}
          value={dateRange}
        />
      </div>

      {!!dateRange.start && !!dateRange.end && (
        <UserConductStats dateRange={dateRange} siteId={siteId} />
      )}
    </div>
  )
}

ViewSiteStats.defaultProps = {}

export { ViewSiteStats }
