import React, { useState } from 'react'
import { SelectContactsForEmail } from '@src/modules/Contacts/shared/Fields/SelectContactsForEmail'
import { Button, Loading, Modal, Textarea, TextField } from '@therms/atalaya'
import {
  EmailEscOccurrenceToExternal,
  GetEscOccurencesByTimeRangeResponseBody,
} from '@src/services/api-client'
import { Alerts } from '@src/components/Alerts'
import { useAuth } from '@src/modules/Auth/shared/state'

interface EscalationOccurrenceForwardToContactModalProps {
  escOccurrence: GetEscOccurencesByTimeRangeResponseBody['escOccurrences'][number]
  onClose: () => void
}

function EscalationOccurrenceForwardToContactModal({
  escOccurrence,
  onClose,
}: EscalationOccurrenceForwardToContactModalProps) {
  const [loading, setLoading] = useState(false)
  const [selectedContact, setSelectedContact] = useState<string>()
  const [forwardModalMessage, setForwardModalMessage] = useState<string>()
  const { currentSite } = useAuth()

  const handleEmail = async () => {
    setLoading(true)

    try {
      if (selectedContact) {
        await EmailEscOccurrenceToExternal({
          siteId: escOccurrence.siteId,
          // escOccurrenceId: escOccurrence.id,
          escOccuranceId: escOccurrence.id,
          email: selectedContact,
          extraMessage: forwardModalMessage,
        })
        onClose()
        Alerts.success('Escalation issue emailed to contact')
      }
    } catch (err: any) {
      Alerts.error('Error emailing this issue, please try again', err.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal closeHandler={onClose} header="Select Contact">
      {loading && <Loading overlay />}

      <div className="space-y-xs">
        <SelectContactsForEmail
          siteId={currentSite.id}
          value={selectedContact}
          onChangeValue={setSelectedContact}
        />

        <Textarea
          value={forwardModalMessage}
          onChangeValue={setForwardModalMessage}
          placeholder="Enter a message to include with the email"
        />

        <div className="flex w-full justify-end">
          <Button onClick={handleEmail} variant="positive">
            Email Contact
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export { EscalationOccurrenceForwardToContactModal }
