import React, {useMemo} from 'react'
import {RRule} from 'rrule'
import {MonthOptions, WeekDayOptions,} from '@src/modules/Tasks/components/TaskScheduleFormFields'
import * as Sentry from '@sentry/react'
import {getOrdinalNum} from "@src/utils/ordinals"

interface RRuleTextProps {
  rruleString: string
}

const RRuleText = React.memo(({ rruleString }: RRuleTextProps) => {
  const rrule = useMemo(
    () => RRule.fromString(rruleString).options,
    [rruleString],
  )

  // const freq = useMemo(() => {
  //   switch (rrule.freq) {
  //     case RRule.DAILY:
  //       return 'Every day'
  //     case RRule.WEEKLY:
  //       return 'Every week'
  //     default:
  //       return ''
  //   }
  // }, [rrule])

  const months = useMemo(() => {
    try {
      if (rrule.bymonth?.length > 0) {
        return rrule.bymonth
          .sort()
          .map((mo) => MonthOptions[mo - 1].label)
          .join('/')
      }
    } catch (e) {
      Sentry.captureException(e)
    }

    return ''
  }, [rrule.bymonth])

  const monthDays = useMemo(() => {
    try {
      if (rrule.bymonthday?.length > 0) {
        return rrule.bymonthday
            .sort()
            .map((md) => getOrdinalNum(md))
            .join('/')
      }
    } catch (e) {
      Sentry.captureException(e)
    }

    return ''
  }, [rrule.bymonthday])

  const weekDays = useMemo(() => {
    try {
      if (rrule.byweekday?.length > 0) {
        return rrule.byweekday
          .sort()
          .map((wkd) => WeekDayOptions[wkd].label)
          .join('/')
      }
    } catch (e) {
      Sentry.captureException(e)
    }

    return ''
  }, [rrule.byweekday])

  const ifDaily = useMemo(() => rrule.byweekday?.length > 0 || rrule.bymonthday?.length > 0 ? "" : "Daily", [rrule.byweekday, rrule.bymonthday])

  const hour = useMemo(() => {
    if (rrule.byhour?.length > 0) {
      const localHour = rrule.byhour
        .map((hr) => {
          const d = new Date()
          d.setHours(hr)
          const localHr = d.getHours()
          const local12hr = ((localHr + 11) % 12) + 1

          const min = `${rrule.byminute?.[0] || 0}`

          return `${local12hr}:${min.padStart(2, '0')}${
            localHr > 11 ? 'pm' : 'am'
          }`
        })
        .join(', ')

      return `${localHour}`
    }

    return ''
  }, [rrule.byhour])

  const until = useMemo(() => {
    try {
      if (rrule.until) {
        return rrule.until
      }
    } catch (e) {
      Sentry.captureException(e)
    }

    return undefined
  }, [rrule.until])

  return (
    <span>
      <span>{[months, ifDaily, monthDays, weekDays, hour].filter((x) => !!x).join(', ')}</span>
      {!!until && <span> until {until.toDateString()}</span>}
    </span>
  )
})

export { RRuleText }
