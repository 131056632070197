import React, { useMemo } from 'react'
import { SiteGetSiteOkResponseBody } from '@src/services/api-client'
import { Label } from '@therms/atalaya'
import { RRule } from 'rrule'
import dayjs from 'dayjs'

interface SiteHoursProps {
  site: SiteGetSiteOkResponseBody
}

function SiteHours({ site }: SiteHoursProps) {
  const hours = useMemo(() => {
    let hrs = ''

    if (site.storeHours?.storeOpeningRules?.length) {
      const openStr = RRule.fromString(
        site.storeHours.storeOpeningRules[0],
      ).options
      hrs += `${dayjs()
        .set('hour', openStr.byhour[0])
        .set('minute', openStr.byminute[0])
        .format('h:mm A')}`
    }

    if (site.storeHours?.storeClosingRules?.length) {
      const openStr = RRule.fromString(
        site.storeHours.storeClosingRules[0],
      ).options
      hrs += ` - ${dayjs()
        .set('hour', openStr.byhour[0])
        .set('minute', openStr.byminute[0])
        .format('h:mm A')}`
    }

    return hrs
  }, [site])

  if (!site.storeHours?.storeClosingRules?.length) return null

  return (
    <div>
      <Label>Store Hours</Label>

      <div>{hours}</div>
    </div>
  )
}

SiteHours.defaultProps = {}

export { SiteHours }
