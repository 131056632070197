import {Button, FormControlWrapper, FormLayout, Inline, Slider, Stack,} from '@therms/atalaya'
import React, {useEffect, useMemo} from 'react'
import {FieldArray, useFormikContext} from 'formik'
import {FormikHTMLSelect} from '@src/components/FormikFields/FormikHTMLSelect'
import {Task} from '@src/services/api-client'
import {FormikCheckbox} from '@src/components/FormikFields/FormikCheckbox'
import {FormikSelect} from '@src/components/FormikFields/FormikSelect'
import {FormikSelectPositions} from '@src/modules/Positions/shared/Fields/FormikSelectPositions'

interface MissedActionFormFieldProps {
  /**
   * notification is intended to be to a manager or another position
   * reminder is intended to be for the positionId that the Task is assigned to
   */
  actionType: 'notification' | 'reminder'
}

const curryFirstOcurranceDelaySecondsOptions = (
  actionType: 'notification' | 'reminder',
) => {
  const options = [
    { label: '15min after', value: 60 * 15 },
    { label: '30min after', value: 60 * 30 },
    { label: '45min after', value: 60 * 45 },
    { label: '1hr after', value: 60 * 60 },
    { label: '1.5hr after', value: 60 * 90 },
    { label: '2hr after', value: 60 * 120 },
    { label: '3hr after', value: 60 * 180 },
    { label: '4hr after', value: 60 * 60 * 4 },
    { label: '8hr after', value: 60 * 60 * 8 },
    { label: '10hr after', value: 60 * 60 * 10 },
    { label: '12hr after', value: 60 * 60 * 12 },
  ]

  if (actionType === 'reminder') {
    options.unshift({ label: '10min before', value: -(60 * 10) })
    options.unshift({ label: '5min before', value: -(60 * 5) })
  }

  return options
}

function MissedActionFormField({ actionType }: MissedActionFormFieldProps) {
  const form = useFormikContext<Task>()

  const missActions = form.values?.missActions || []

  useEffect(() => {
    if (actionType === 'reminder') {
      missActions.forEach((missAction, i) => {
        if (missAction.actionType === 'reminder') {
          // ensure that all reminders are assigned to the same positionIds as the Task
          if (
            (missAction.positionIds || []).toString() !==
            (form.values?.positionIds || []).toString()
          ) {
            form.setFieldValue(
              `missActions.${i}.positionIds`,
              form.values?.positionIds || [],
            )
          }
        }
      })
    }
  }, [form.values?.positionIds, missActions])

  const showAdd = useMemo(() => {
    /* only let user create 1 'reminder' */
    if (actionType === 'reminder') {
      return (
        missActions.filter((missAction) => missAction.actionType === 'reminder')
          .length < 1
      )
    }

    return true
  }, [missActions])

  return (
    <FieldArray
      name="missActions"
      render={(arrayHelpers) => (
        <Stack space="base">
          {missActions.map((missedAction, i) => {
            if (missedAction.actionType !== actionType) return null

            return (
              <Stack space="sm">
                {i > 0 && (
                  <div className="my-base">
                    <FormLayout.Divider withBorder />
                  </div>
                )}

                {actionType === 'notification' && (
                  <FormikSelectPositions
                    label="Positions to Notify"
                    multi
                    name={`missActions.${i}.positionIds`}
                  />
                )}

                <FormControlWrapper label=" ">
                  <FormikCheckbox
                    label="Only notify Users who are at the Site"
                    name={`missActions.${i}.inStoreOnly`}
                  />
                </FormControlWrapper>

                <FormikHTMLSelect
                  label="Delivery"
                  multi
                  name={`missActions.${i}.pushTypes`}
                  options={[
                    { label: 'Push-Notification', value: 'pushNotification' },
                    { label: 'Email', value: 'email' },
                  ]}
                />

                <FormikSelect
                  label="When to Notify"
                  name={`missActions.${i}.firstOcurranceDelaySeconds`}
                  onChangeOption={(option) => {
                    form.setFieldValue(
                      `missActions.${i}.firstOcurranceDelaySeconds`,
                      option.value,
                    )
                  }}
                  options={curryFirstOcurranceDelaySecondsOptions(actionType)}
                />

                <Slider
                  label="How many reminders?"
                  max={4}
                  min={1}
                  onChangeValue={(value) =>
                    form.setFieldValue(`missActions.${i}.maxOccurrences`, value)
                  }
                  value={form.values?.missActions?.[i]?.maxOccurrences || 1}
                />

                <Inline alignX="end" width="full">
                  <Button
                    onClick={() => arrayHelpers.remove(i)}
                    variant="critical"
                    subtle
                  >
                    Remove
                  </Button>
                </Inline>
              </Stack>
            )
          })}

          {showAdd && (
            <div>
              <div className="my-lg">
                <Button
                  fullWidth
                  onClick={() =>
                    arrayHelpers.push({
                      actionType,
                      inStoreOnly: actionType === 'reminder',
                      positionIds: [],
                      pushTypes: [
                        actionType === 'reminder'
                          ? 'pushNotification'
                          : 'email',
                      ],
                      firstOcurranceDelaySeconds:
                        actionType === 'reminder' ? 60 * 30 : 60 * 60,
                      maxOccurrences: 1,
                      subsequentRecurrenceDelaySeconds: 0,
                    })
                  }
                  subtle
                >
                  <span className="capitalize">+ Add {actionType}</span>
                </Button>
              </div>
            </div>
          )}
        </Stack>
      )}
    />
  )
}

MissedActionFormField.defaultProps = {}

export { MissedActionFormField }
