import React, { useEffect, useMemo, useState } from 'react'
import { GetSiteSnapshotForAdminResponseBody } from '@src/services/api-client'
import { Card, Constants, DateTime, Inline, Stack } from '@therms/atalaya'
import dayjs from 'dayjs'
import { TaskGroupName } from '@src/modules/Tasks/shared/TaskGroupName'
import { PositionName } from '@src/modules/Positions/shared/PositionName'
import relativeTime from 'dayjs/plugin/relativeTime'
import { FiCheck } from 'react-icons/fi'
import { VscDebugStepOver } from 'react-icons/vsc'
import { humanizeMinutes } from '@therms/web-js'

dayjs.extend(relativeTime)

interface TasksCardProps {
  snapshotForAdminData: GetSiteSnapshotForAdminResponseBody
}

function TasksCard({ snapshotForAdminData }: TasksCardProps) {
  const [reloadTrigger, setReloadTrigger] = useState(Math.random())

  const [endDate, startDate] = useMemo(
    () => [
      dayjs().add(1, 'hour').toISOString(),
      dayjs().subtract(2, 'hours').toISOString(),
    ],
    [reloadTrigger],
  )

  const [groupedUpcomingTasks, setGroupedUpcomingTasks] = useState([])

  const upcomingTasks = () => {
    let _groupedUpcomingTasks = []

    if (snapshotForAdminData?.upcoming.completionsByTaskGroupId.byGroup) {
      Object.keys(
        snapshotForAdminData?.upcoming.completionsByTaskGroupId.byGroup,
      ).map((key) => {
        _groupedUpcomingTasks = [
          ...snapshotForAdminData.upcoming.completionsByTaskGroupId.byGroup[
            key
          ],
          ..._groupedUpcomingTasks,
        ]

        return null
      })
    }
    setGroupedUpcomingTasks([
      ..._groupedUpcomingTasks,
      ...snapshotForAdminData.upcoming.completionsByTaskGroupId.noGroup,
    ])
  }

  const [groupedMissedTasks, setGroupedMissedTasks] = useState([])

  const missedTasks = () => {
    let _groupedMissedTasks = []

    if (snapshotForAdminData?.missed.completionsByTaskGroupId.byGroup) {
      Object.keys(
        snapshotForAdminData?.missed.completionsByTaskGroupId.byGroup,
      ).map((key) => {
        _groupedMissedTasks = [
          ..._groupedMissedTasks,
          ...snapshotForAdminData.missed.completionsByTaskGroupId.byGroup[key],
        ]

        return null
      })
    }
    setGroupedMissedTasks([
      ..._groupedMissedTasks,
      ...snapshotForAdminData.missed.completionsByTaskGroupId.noGroup,
    ])
  }

  useEffect(() => {
    if (snapshotForAdminData?.upcoming.completionsByTaskGroupId.noGroup) {
      upcomingTasks()
    }

    if (snapshotForAdminData?.missed.completionsByTaskGroupId.noGroup) {
      missedTasks()
    }
  }, [snapshotForAdminData])

  useEffect(() => {
    const i = setInterval(() => {
      setReloadTrigger(Math.random())
    }, 15000)

    return () => clearInterval(i)
  }, [])

  const upcomingTasksWithInThirtyMinutes = groupedUpcomingTasks
    .sort((a, b) => (a.dueDate < b.dueDate ? -1 : 1))
    .filter((completion) => completion.dueDate < endDate)

  const missedTasksWithInTwoHours = groupedMissedTasks
    .sort((a, b) => (a.dueDate < b.dueDate ? -1 : 1))
    .filter((completion) => completion.dueDate > startDate)

  return (
    <Card className="overflow-hidden" padding="xs" space="xxs">
      <Inline>
        <div className="text-lg">Tasks</div>

        <div className=" grow grid grid-cols-5 pb-xxs">
          <div />

          <div className="col-span-3 grid grid-cols-2 gap-sm">
            {!!missedTasksWithInTwoHours.length && (
              <div className="animate-pulse bg-critical rounded px-xs">
                <div className="text-color-critical-dark font-light text-center text-sm">
                  <small>Missed</small>
                </div>

                <div className="text-center">
                  <div className="relative">
                    <div className="absolute text-center text-lg left-0 right-0">
                      {missedTasksWithInTwoHours.length}
                    </div>
                    <div className="animate-ping text-center text-lg">
                      {missedTasksWithInTwoHours.length}
                    </div>
                  </div>
                </div>

                <div className="text-color-critical-light font-light text-sm">
                  <small>Last 2 hours</small>
                </div>
              </div>
            )}

            {!!upcomingTasksWithInThirtyMinutes.length && (
              <div className="bg-main-light rounded px-xs">
                <div className="text-color-main font-light text-center text-sm">
                  <small>Upcoming</small>
                </div>

                <div className="text-center text-lg">
                  {upcomingTasksWithInThirtyMinutes.length}
                </div>

                <div className="text-color-main-light font-light text-sm">
                  <small>Next 30 min</small>
                </div>
              </div>
            )}
          </div>
          <div />
        </div>
      </Inline>

      <Stack className="mt-base overflow-auto" space="xxs">
        {missedTasksWithInTwoHours.map((completion) => (
          <div key={completion.id} className="bg-critical rounded leading-5">
            <div className="flex justify-between relative">
              <div className="rounded-bl rounded-tr truncate overflow-hidden">
                {!!completion.taskGroupIds.length && (
                  <TaskGroupName id={completion.taskGroupIds[0]} />
                )}
              </div>

              <div className="pr-xxs">
                <span className="font-light">
                  <DateTime mode="time" timestamp={completion.dueDate} />
                </span>{' '}
                <span className="font-medium">
                  ({humanizeMinutes(dayjs().diff(completion.dueDate, 'minute'))}{' '}
                  ago)
                </span>
              </div>
            </div>

            <div className="grid grid-cols-2 px-xxs">
              <div className="text-color-semantic truncate font-bold">
                {completion.title}
              </div>

              <div className="font-thin text-color-semantic text-sm truncate">
                {completion.positionIds.map((positionId: string, i: number) => (
                  <span key={positionId}>
                    <PositionName id={positionId} />
                    {completion.positionIds.length - 1 === i ? '' : ', '}
                  </span>
                ))}
              </div>
            </div>
          </div>
        ))}

        {upcomingTasksWithInThirtyMinutes.map((completion) => (
          <div
            key={completion.id}
            className="bg-surface-subtle rounded border border-surface-strong leading-5"
          >
            <Inline alignX="between" width="full">
              <div className="rounded-bl rounded-tr truncate overflow-hidden">
                {!!completion.taskGroupIds.length && (
                  <TaskGroupName id={completion.taskGroupIds[0]} />
                )}
              </div>

              <div className="pr-xxs">
                <span className="font-light">
                  <DateTime mode="time" timestamp={completion.dueDate} />
                </span>{' '}
                <span className="font-medium">
                  (in{' '}
                  {humanizeMinutes(
                    dayjs(completion.dueDate).diff(new Date(), 'minute'),
                  )}
                  )
                </span>
              </div>
            </Inline>

            <div>
              <div className="grid grid-cols-2 px-xxs">
                <div className="flex text-color-main-dark items-center">
                  {completion.isComplete && (
                    <FiCheck color={Constants.Color.Dark.Positive} size={14} />
                  )}
                  {completion.isSkipped && (
                    <VscDebugStepOver
                      color={Constants.Color.Light.Caution}
                      size={14}
                    />
                  )}
                  <span className="truncate">{completion.title}</span>
                </div>

                <div className="font-thin text-sm truncate">
                  {completion.positionIds.map(
                    (positionId: string, i: number) => (
                      <span className="" key={positionId}>
                        <PositionName id={positionId} />
                        {completion.positionIds.length - 1 === i ? '' : ', '}
                      </span>
                    ),
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}

        {!upcomingTasksWithInThirtyMinutes.length &&
          !missedTasksWithInTwoHours.length && (
            <div className="text-center text-color-neutral-dark">
              No Upcoming Tasks
            </div>
          )}
      </Stack>
    </Card>
  )
}

export { TasksCard }
