import useSWR from 'swr'
import {API_ENDPOINTS} from '@src/services/api-endpoints'
import {OrganizationGetOrganizationOkResponseBody} from '@src/services/api-client'
import {useAuth} from '@src/modules/Auth/shared/state'

export const useCurrentOrganization = () => {
  const { currentUser } = useAuth()

  const { data, mutate } = useSWR<OrganizationGetOrganizationOkResponseBody>(
    `${API_ENDPOINTS.GET_ORGANIZATION}`,
  )

  return {
    id: currentUser.organizationId,
    name: data?.name || '',
    shutdown: data?.shutdown,
    isTemplateProducer: !!data?.orgTemplateToMapTo,
    refresh: () => mutate(),
  }
}
