import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Task, TaskGetTask } from '@src/services/api-client'
import { useAuth } from '@src/modules/Auth/shared/state'

interface Props {
  render?: (task: Task) => React.ReactNode
  id: string
}

function TaskName({ id, render }: Props) {
  const [data, setData] = useState(undefined)
  const { currentSite } = useAuth()

  useEffect(() => {
    if (!id) {
      setData(undefined)
      return
    }

    TaskGetTask(
      { taskId: id, siteId: currentSite.id },
      {
        cache: {
          ttl: 1000 * 30,
        },
      },
    ).then((res) => {
      setData(res.data)
    })
  }, [id])

  if (!data) return <span />

  if (render && typeof render === 'function') {
    return <>{render(data)}</>
  }

  return <span>{data?.name}</span>
}

TaskName.propTypes = {
  id: PropTypes.string.isRequired,
}

export { TaskName }
