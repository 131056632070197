import React from 'react'

import { BreadcrumbsContext } from './BreadcrumbsContext'

const useBreadcrumbsContext = () => {
  const context = React.useContext(BreadcrumbsContext)

  if (context === undefined) {
    console.warn(
      'useBreadcrumbsContext() must be a descendant of <BreadcrumbsContextProvider />',
    )
  }

  return context
}

export { useBreadcrumbsContext }
