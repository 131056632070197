import { useEffect, useState } from 'react'
import { useAuth } from '@src/modules/Auth/shared/state'
import { SiteUser, genSiteUser } from '@src/modules/Auth/shared/siteUser'
import { useApiWithSiteId } from '@src/hooks/useApiWithSiteId'
import {
  UserGetUser,
  UserGetUserOkResponseBody,
} from '@src/services/api-client'

export function useSiteUser(userId: string): {
  siteUser: SiteUser | undefined
  isCalling: boolean
  call: () => void
} {
  const { currentSite } = useAuth()
  const [siteUser, setSiteUser] = useState<SiteUser | undefined>(undefined)
  const { data, isCalling, call } = useApiWithSiteId(UserGetUser, userId ? { userId } : false)

  useEffect(() => {
    if (!data || !currentSite) {
      setSiteUser(undefined)
    } else {
      const userData: UserGetUserOkResponseBody = data
      setSiteUser(genSiteUser(userData, currentSite.id))
    }
  }, [data])

  return {
    siteUser,
    isCalling,
    call,
  }
}
