import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { EmailPreferences } from '@src/modules/Emails/EmailPreferences'

function EmailPreferencesRoutes() {
  return (
    <Switch>
      <Route component={EmailPreferences} />
    </Switch>
  )
}

export { EmailPreferencesRoutes }
