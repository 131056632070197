import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { BreadcrumbsContext } from './BreadcrumbsContext'

function BreadcrumbsContextProvider({
  children,
  isCurrentLinkMatcher,
  onBack,
  onNavigate,
}) {
  const [breadcrumbs, setBreadcrumbs] = useState([])
  const resetBreadcrumbs = useCallback(() => setBreadcrumbs([]), [])

  const ctxValue = useMemo(
    () => ({
      breadcrumbs,
      isCurrentLinkMatcher,
      onBack,
      onNavigate,
      resetBreadcrumbs,
      setBreadcrumbs,
    }),
    [
      breadcrumbs,
      isCurrentLinkMatcher,
      onBack,
      onNavigate,
      resetBreadcrumbs,
      setBreadcrumbs,
    ],
  )

  return (
    <BreadcrumbsContext.Provider value={ctxValue}>
      {children}
    </BreadcrumbsContext.Provider>
  )
}

BreadcrumbsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  isCurrentLinkMatcher: PropTypes.func,
  onBack: PropTypes.func,
  onNavigate: PropTypes.func,
}

BreadcrumbsContextProvider.defaultProps = {
  isCurrentLinkMatcher: undefined,
  onBack: undefined,
  onNavigate: undefined,
}

export { BreadcrumbsContextProvider }
