import React, {useMemo, useState} from 'react'
import {RRule} from 'rrule'
import {useSetState, useUpdateEffect} from 'ahooks'
import * as Sentry from '@sentry/react'
import {Button, Checkbox, DateTimePicker, FormControlWrapper, FormLayout, Select,} from '@therms/atalaya'
// @ts-ignore
import {ParsedOptions} from 'rrule/dist/esm/src/types'
import {FaAngleDown} from 'react-icons/fa'
import {getOrdinalNum} from "@src/utils/ordinals"


export const MonthOptions = [
  { label: 'Jan', value: 1 },
  { label: 'Feb', value: 2 },
  { label: 'Mar', value: 3 },
  { label: 'Apr', value: 4 },
  { label: 'May', value: 5 },
  { label: 'Jun', value: 6 },
  { label: 'Jul', value: 7 },
  { label: 'Aug', value: 8 },
  { label: 'Sep', value: 9 },
  { label: 'Oct', value: 10 },
  { label: 'Nov', value: 11 },
  { label: 'Dec', value: 12 },
]

export const WeekDayOptions = [
  { label: 'Mon', value: 0 },
  { label: 'Tue', value: 1 },
  { label: 'Wed', value: 2 },
  { label: 'Thu', value: 3 },
  { label: 'Fri', value: 4 },
  { label: 'Sat', value: 5 },
  { label: 'Sun', value: 6 },
]

export const DaysPerMonthOptions = Array.from(Array(31).keys()).map(x => ({ label: getOrdinalNum(x + 1), value: x + 1 }))

interface TaskScheduleFormFields {
  onChange?: (scheduleString: string) => void
  initialScheduleStringValue?: string
}

function TaskScheduleFormFields({
  initialScheduleStringValue,
  onChange,
}: TaskScheduleFormFields) {
  const [rrule, setRRuleState] = useSetState<Partial<ParsedOptions>>(() => {
    if (
      initialScheduleStringValue &&
      typeof initialScheduleStringValue === 'string'
    ) {
      return RRule.fromString(initialScheduleStringValue).options
    }
    return {
      freq: RRule.DAILY,
    }
  })

  useUpdateEffect(() => {
    const rruleStr = new RRule(rrule).toString()

    onChange(rruleStr)
  }, [rrule])

  const temporaryDate = useMemo(() => {
    try {
      if (rrule.until) {
        return rrule.until.toISOString()
      }
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          rrule: JSON.stringify(rrule || []),
        },
      })

      return undefined
    }
    return undefined
  }, [rrule])

  const [showTemporaryTaskEndDate, setShowTemporaryTaskEndDate] =
    useState<boolean>(!!temporaryDate)

  const localTZHour = useMemo(() => {
    try {
      const d = new Date()

      d.setHours(rrule.byhour[0])

      return d.getHours()
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          rrule: JSON.stringify(rrule || []),
        },
      })

      return new Date().getHours()
    }
  }, [rrule])

  const localTZDate = useMemo(() => {
    try {
      const d = new Date()

      if (rrule.byminute?.length && rrule.byhour?.length) {
        d.setMinutes(rrule.byminute[0])
        d.setHours(rrule.byhour[0])
      } else {
        d.setMinutes(0)
      }

      return d.toISOString()
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          rrule: JSON.stringify(rrule || []),
        },
      })

      return new Date().toISOString()
    }
  }, [rrule])

  return (
    <FormLayout.Section>
      {/* eslint-disable-next-line eqeqeq */}
      {rrule.freq != RRule.HOURLY && (
        <DateTimePicker
          mode="time"
          label="Time"
          onChangeValue={(time) => {
            const d = new Date(time)
            setRRuleState({
              byhour: [d.getHours()],
              byminute: [d.getMinutes()],
              bysecond: [0],
            })
          }}
          value={localTZDate}
        />
      )}

      <FormControlWrapper
        hint={
          <div className="space-x-xxs">
            {!showTemporaryTaskEndDate && (
              <Button
                onClick={() => setShowTemporaryTaskEndDate(true)}
                subtle
                variant="main"
              >
                <span className="pr-xxs">Add End Date</span>
                <FaAngleDown />
              </Button>
            )}
            <span>Optional</span>
          </div>
        }
        label="Temporary Schedule"
        subText="Task will no longer be done after the selected date"
      >
        {showTemporaryTaskEndDate && (
          <DateTimePicker
            mode="date"
            label="End Date"
            noInitialValue
            onChangeValue={(date) => {
              if (!date) {
                setRRuleState({ until: null })
              } else {
                setRRuleState({ until: new Date(date) })
              }
            }}
            value={temporaryDate || undefined}
          />
        )}
      </FormControlWrapper>

      <FormControlWrapper
        hint="Optional"
        label="Day of Week"
        subText="If no days selected then Task will run every day"
      >
        <div className="grid grid-cols-7">
          {WeekDayOptions.map((option) => (
            <Checkbox
              // @ts-ignore
              active={rrule.byweekday?.includes?.(+option.value)}
              label={option.label}
              onChangeValue={(checked) => {
                if (checked) {
                  setRRuleState({
                    // @ts-ignore
                    byweekday: [...(rrule.byweekday || []), option.value],
                  })
                } else {
                  setRRuleState({
                    byweekday: (rrule.byweekday || []).filter(
                      // @ts-ignore
                      (v) => v !== option.value,
                    ),
                  })
                }
              }}
            />
          ))}
        </div>
      </FormControlWrapper>
      <FormControlWrapper
          hint="Optional"
          label="Day Of Month"
          subText="If no day selected then Task will run every day"
      >

          <Select options={DaysPerMonthOptions} value={rrule.bymonthday} onChangeValue={(values) => {
            setRRuleState({
              bymonthday: values,
            })

          }} multi/>

      </FormControlWrapper>

      <FormControlWrapper
        hint="Optional"
        label="Month"
        subText="If no months selected then Task will run every month"
      >
        <div className="grid grid-cols-4">
          {MonthOptions.map((option) => (
            <Checkbox
              // @ts-ignore
              active={rrule.bymonth?.includes?.(+option.value)}
              label={option.label}
              onChangeValue={(checked) => {
                if (checked) {
                  setRRuleState({
                    // @ts-ignore
                    bymonth: [...(rrule.bymonth || []), option.value],
                  })
                } else {
                  setRRuleState({
                    bymonth: (rrule.bymonth || []).filter(
                      // @ts-ignore
                      (v) => v !== option.value,
                    ),
                  })
                }
              }}
            />
          ))}
        </div>
      </FormControlWrapper>
    </FormLayout.Section>
  )
}

TaskScheduleFormFields.defaultProps = {}

export { TaskScheduleFormFields }
