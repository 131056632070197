import React, { useState } from 'react'
import { Button, Inline, Loading, Modal, TextField } from '@therms/atalaya'
import { AuthorizeSiteReadBot } from '@src/services/api-client'
import { Alerts } from '@src/components/Alerts'

interface SetupWallboardDeviceModalProps {
  closeHandler?: () => void
  siteId: string
}

function SetupWallboardDeviceModal({
  closeHandler,
  siteId,
}: SetupWallboardDeviceModalProps) {
  const [deviceId, setDeviceId] = useState('')
  const [loading, setLoading] = useState(false)

  const handleActivate = async () => {
    setLoading(true)
    try {
      await AuthorizeSiteReadBot({ botTargetKey: deviceId, siteId })

      closeHandler?.()

      Alerts.success('Device is activated')
    } catch (e) {
      Alerts.error('There was an error activating the device', e.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal closeHandler={closeHandler} header="Setup a Wallboard Device">
      {loading && <Loading overlay />}

      <div className="mb-base">
        To setup a device to display this Site on the Wallboard, enter the code
        that appears on the device's screen.
      </div>

      <TextField
        label="Code"
        onChangeValue={setDeviceId}
        value={deviceId}
        subText="Enter the code that is displayed on the device."
      />

      <Inline alignX="end" className="mt-base" width="full">
        <Button onClick={handleActivate} variant="main">
          Activate
        </Button>
      </Inline>
    </Modal>
  )
}

SetupWallboardDeviceModal.defaultProps = {}

export { SetupWallboardDeviceModal }
