import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ORGANIZATION_ROUTES } from '../constants'
import { OrganizationIndex } from './screens'
import { EditOrganization } from './screens/EditOrganization'
import { Breadcrumb } from '@src/modules/Layout/shared/Breadcrumbs/Breadcrumb'
import { ErrorBoundary } from 'react-error-boundary'
import { Button } from '@therms/atalaya'
import * as Sentry from "@sentry/react"
// import { useSetBreadcrumbs } from '@src/modules/Layout/shared/Breadcrumbs/useSetBreadcrumbs'

const rootCrumb = (
  <Breadcrumb link={ORGANIZATION_ROUTES.__ROOT__}>Organization</Breadcrumb>
)

function EditOrganizationRoute() {
  const crumbs = [
    rootCrumb,
    <Breadcrumb link={ORGANIZATION_ROUTES.EDIT_ORGANIZATION_INFO}>
      Edit
    </Breadcrumb>,
  ]
  // useSetBreadcrumbs(crumbs)
  return <EditOrganization />
}

function OrganizationIndexRoute() {
  // useSetBreadcrumbs([rootCrumb])
  return <OrganizationIndex />
}

function OrganizationRoutes() {
  return (
    <Switch>
      <Route
        path={ORGANIZATION_ROUTES.EDIT_ORGANIZATION_INFO}
        render={() => (
          <ErrorBoundary
              onError={(error, componentStack) => {
                  Sentry.withScope((scope) => {
                      scope.setExtras(componentStack)
                      Sentry.captureException(error)
                  })
              }}
            fallbackRender={({ error, resetErrorBoundary }) => (
              <div>
                <p>Something went wrong:</p>
                <pre>{error.message}</pre>
                <Button onClick={resetErrorBoundary}>Try again</Button>
              </div>
            )}
          >
            <EditOrganizationRoute />
          </ErrorBoundary>
        )}
      />

      <Route
        render={() => (
          <ErrorBoundary
              onError={(error, componentStack) => {
                  Sentry.withScope((scope) => {
                      scope.setExtras(componentStack)
                      Sentry.captureException(error)
                  })
              }}
            fallbackRender={({ error, resetErrorBoundary }) => (
              <div>
                <p>Something went wrong:</p>
                <pre>{error.message}</pre>
                <Button onClick={resetErrorBoundary}>Try again</Button>
              </div>
            )}
          >
            <OrganizationIndexRoute />
          </ErrorBoundary>
        )}
      />
    </Switch>
  )
}

export { OrganizationRoutes }
