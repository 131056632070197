import React from 'react'
import { Inline, Loading, TableGroup } from '@therms/atalaya'
import { TableHeadRow } from './TableHeadRow'
import { TableSiteRow } from './TableSiteRow.jsx'
import { useUrlState } from '@src/hooks/useUrlState'
import { useApiWithSiteId } from '@src/hooks/useApiWithSiteId'
import { GetSites } from '@src/services/api-client'

function SitesTable() {
  const [limit, setLimit] = useUrlState('stlimit', 25)
  const [skip, setSkip] = useUrlState('stskip', 0)

  const { data, isCalling } = useApiWithSiteId(GetSites, {
    offset: skip,
    limit,
  })

  return (
    <div className="relative">
      <Loading overlay visible={isCalling} />

      <TableGroup
        data={data?.sites || []}
        keyProp="id"
        Controls={() => (
          <Inline
            alignX="between"
            alignY="baseline"
            className="pb-3"
            width="full"
          >
            <div />
          </Inline>
        )}
        HeadRow={TableHeadRow}
        Row={TableSiteRow}
        onSetRecordsPerPage={setLimit}
        onSetSkippedRecords={setSkip}
        recordsPerPage={limit}
        skippedRecords={skip}
        totalRecords={data?.total}
      />
    </div>
  )
}

SitesTable.propTypes = {}

export { SitesTable }
