import React, { useEffect, useMemo, useState } from 'react'
import { Select } from '@therms/atalaya'
import {
  GetContactsBySite,
  GetContactsResponseBody,
} from '@src/services/api-client'

export interface SelectContactsForEmailProps {
  label?: string
  multi?: boolean
  name?: string
  siteId: string
  value?: string
  onChangeValue: (value: string) => void
}

function SelectContactsForEmail({
  label,
  multi,
  name,
  siteId,
  value,
  onChangeValue,
  ...atalayaSelectProps
}: SelectContactsForEmailProps) {
  const [data, setData] = useState<GetContactsResponseBody['contacts']>([])

  const emailOptions = useMemo(
    () =>
      data?.filter((contact) => {
        if (contact?.email) return contact
        return null
      }),
    [data],
  )

  const options = useMemo(
    () =>
      emailOptions?.map((contact) => ({
        label: contact.title,
        value: contact.email,
      })),
    [data],
  )

  useEffect(() => {
    GetContactsBySite({ includeAdminContacts: true, siteId }).then((res) => {
      setData(res.data.contacts)
    })
  }, [])

  return (
    <Select
      label={label}
      multi={multi}
      name={name}
      onChangeValue={onChangeValue}
      options={options}
      {...atalayaSelectProps}
      value={value}
    />
  )
}

export { SelectContactsForEmail }
