import React, { useCallback, useState } from 'react'
import { generatePath, NavLink, useParams } from 'react-router-dom'
import { Button, ButtonWithModal, Inline, Loading, Modal, Password } from '@therms/atalaya'
import { USERS_ROUTES } from '@src/modules/Users/constants'
import { ReactivateUserModal } from '@src/modules/Users/router/screens/ViewUser/components/ReactivateUserModal'
import { DeactivateUserModal } from '@src/modules/Users/router/screens/ViewUser/components/DeactivateUserModal'
import { UserProfile } from '@src/modules/Users/router/screens/ViewUser/components/UserProfile'
import { useToggle } from 'ahooks'
import { useApiWithSiteId } from '@src/hooks/useApiWithSiteId'
import { UserGetUser, AdminSetPassword } from '@src/services/api-client'
import { Alerts } from '@src/components/Alerts'

function ViewUser() {
  const [showReactivate, { toggle: toggleShowReactivate }] = useToggle(false)
  const [showDeactivate, { toggle: toggleShowDeactivate }] = useToggle(false)
  
  const { userId } = useParams()

  const { data, isCalling } = useApiWithSiteId(UserGetUser, userId ? { userId } : false)

  

  if (!data) return <Loading overlay size="xxl" />

  const user = data

  return (
    <>
      <div>
        {!user.active && (
          <div className="bg-critical my-sm rounded text-center">
            User is Inactive
          </div>
        )}

        <Inline alignX="between" width="full">
          <div className="text-lg">{user.name}</div>

          <Inline alignY="start" space="xs">
            <NavLink to={generatePath(USERS_ROUTES.EDIT_USER, { userId })}>
              <Button outline variant="neutral">
                Edit
              </Button>
            </NavLink>
            
          </Inline>
        </Inline>

        <div className="my-5" />

        <UserProfile userId={user.id} />
      </div>

      {showReactivate && (
        <ReactivateUserModal
          closeHandler={toggleShowReactivate}
          onComplete={() => {
            // todo: reload
          }}
          userId={userId}
        />
      )}

      {showDeactivate && (
        <DeactivateUserModal
          closeHandler={toggleShowDeactivate}
          onComplete={() => {
            // todo: reload
          }}
          userId={userId}
        />
      )}
    </>
  )
}

export { ViewUser }
