import React from 'react'
import { Checkbox } from '@therms/atalaya'
import { Field, FieldAttributes, useFormikContext } from 'formik'

// active is optional
type Props = Omit<FieldAttributes<Parameters<typeof Checkbox>[0]>, 'active'> & {
  active?: boolean
  value?: any
}

function FormikCheckbox(props: Props) {
  const form = useFormikContext()

  return (
    <Field name={props.name} type="checkbox">
      {({ field, meta }) => (
        <Checkbox
          {...props}
          error={(meta.touched || !!form.submitCount) && meta.error}
          valid={
            (meta.touched || !!form.submitCount) && meta.value && !meta.error
          }
          {...field}
          active={!!field.checked}
        />
      )}
    </Field>
  )
}

export { FormikCheckbox }
