import { DateTimeRangePicker, TableGroup } from '@therms/atalaya'
import React, { useEffect, useState } from 'react'
import {
  SiteWhoWasThereResponseBody,
  SiteGetWhoWasThere,
} from '@src/services/api-client'
import dayjs from 'dayjs'
import { Alerts } from '@src/components/Alerts'
import { UserName } from '@src/modules/Users/shared/UserName'

interface UsersOnSiteHistoryTableProps {
  siteId: string
}

function UsersOnSiteHistoryTable({ siteId }: UsersOnSiteHistoryTableProps) {
  const [data, setData] = useState<SiteWhoWasThereResponseBody>()
  const [loading, setLoading] = useState(false)
  const [dateRange, setDateRange] = useState<{
    start?: string
    end?: string
  }>({
    start: dayjs().subtract(2, 'day').toISOString(),
    end: new Date().toISOString(),
  })

  useEffect(() => {
    setLoading(true)

    SiteGetWhoWasThere({
      endDate: dateRange.end,
      siteId,
      startDate: dateRange.start,
    })
      .then((res) => {
        setData(res.data)
      })
      .catch((err) => {
        Alerts.error(
          'There was a problem getting User On-Site history',
          err.message,
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }, [dateRange, siteId])

  return (
    <div>
      <div className="text-lg">Users On-Site History</div>
      <div className="text-italic text-sm text-color-neutral">
        Users who are on-site are "subscribed" either via mobile app geo-fence
        or NFC check-in scan
      </div>

      <div className="my-base w-full">
        <DateTimeRangePicker
          disabled={loading}
          mode="date"
          value={dateRange}
          onChangeValue={setDateRange}
        />
      </div>

      {!data?.length ? (
        <div className="mt-base text-center text-lg">
          No user on-site history found for dates
        </div>
      ) : (
        <TableGroup
          data={data || []}
          keyProp="onsiteStart"
          HeadRow={() => (
            <TableGroup.HeadRow>
              <TableGroup.HeadCell>User</TableGroup.HeadCell>
              <TableGroup.HeadCell>Start Time</TableGroup.HeadCell>
              <TableGroup.HeadCell>End Time</TableGroup.HeadCell>
              <TableGroup.HeadCell />
            </TableGroup.HeadRow>
          )}
          Row={({ rowData }) => (
            <TableGroup.Row rowKey={rowData.userId}>
              <TableGroup.Cell>
                <UserName id={rowData.userId} />
              </TableGroup.Cell>
              <TableGroup.Cell>
                {dayjs(rowData.onsiteStart).format('dd MMM D h:mma')}
              </TableGroup.Cell>
              <TableGroup.Cell>
                {dayjs(rowData.onsiteEnd).format('dd MMM D h:mma')}
              </TableGroup.Cell>
              <TableGroup.Cell>
                {rowData.onsiteTimedOut && 'Timed out due to 12hr rule'}
              </TableGroup.Cell>
            </TableGroup.Row>
          )}
        />
      )}
    </div>
  )
}

UsersOnSiteHistoryTable.defaultProps = {}

export { UsersOnSiteHistoryTable }
