import React from 'react'
import { QRCodeSVG } from 'qrcode.react'
import { Task } from '@src/services/api-client'
import {
  getQRValueTaskId,
  getQRValueToWriteNFC,
} from '@src/utils/qr-code-values'

interface WriteTaskNFCTagProps {
  task: Partial<Task>
}

function WriteTaskNFCTag({ task }: WriteTaskNFCTagProps) {
  return (
    <div>
      <div className="my-base">
        <strong>Configure Task NFC tag</strong>

        <ol>
          <li>
            1. Prepare the NFC <em>ntag-213</em> or <em>ntag-215</em> tag for
            writing
          </li>
          <li>2. Open the mobile app</li>
          <li>3. Scan the QR code</li>
          <li>4. Follow the mobile app prompts</li>
          <li>5. Close when done</li>
        </ol>
      </div>

      <div className="p-xs bg-white">
      <QRCodeSVG
        value={getQRValueToWriteNFC(
          encodeURIComponent(getQRValueTaskId(task.id)),
        )}
      />
      </div>
    </div>
  )
}

WriteTaskNFCTag.defaultProps = {}

export { WriteTaskNFCTag }
