import React from 'react'
import { TableGroup } from '@therms/atalaya'
// import PropTypes from 'prop-types'

function EscalationsTableHeadRow() {
  return (
    <TableGroup.HeadRow canSelect>
      <TableGroup.HeadCell>Name</TableGroup.HeadCell>
      <TableGroup.HeadCell>Description</TableGroup.HeadCell>
    </TableGroup.HeadRow>
  )
}

EscalationsTableHeadRow.propTypes = {}

export { EscalationsTableHeadRow }
