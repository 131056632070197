import React from 'react'

const BreadcrumbsContext = React.createContext({
  breadcrumbs: undefined,
  isCurrentLinkMatcher: undefined,
  onBack: undefined,
  onNavigate: undefined,
  resetBreadcrumbs: undefined,
  setBreadcrumbs: undefined,
})

export { BreadcrumbsContext }
