import React from 'react'
import PropTypes from 'prop-types'
import { TableGroup } from '@therms/atalaya'
import { PositionName } from '@src/modules/Positions/shared/PositionName'

function PositionOptionsTableRow({ rowData, rowKey }) {
  return (
    <TableGroup.Row
      className="hover:bg-surface hover:cursor-pointer"
      rowKey={rowKey}
    >
      <TableGroup.Cell>{rowData.title}</TableGroup.Cell>

      <TableGroup.Cell>
        {rowData.positionIds?.map((positionId, i) => (
          <>
            <PositionName id={positionId} />
            {i + 1 < rowData.positionIds.length ? ', ' : ''}
          </>
        ))}
      </TableGroup.Cell>
    </TableGroup.Row>
  )
}

PositionOptionsTableRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  rowKey: PropTypes.any.isRequired,
}

export { PositionOptionsTableRow }
