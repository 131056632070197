import React from 'react'
import { FormLayout, Inline, Button, Loading } from '@therms/atalaya'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { Alerts } from '@src/components/Alerts'
import { FormikText } from '@src/components/FormikFields/FormikText'
import useSWR from 'swr'
import { API_ENDPOINTS } from '@src/services/api-endpoints'
import {
  OrganizationModifyOrganization,
} from '@src/services/api-client'

const ValidationSchema = Yup.object().shape({
  name: Yup.string().min(3).required('Name must be at least 3 characters long'),
})

function EditOrganization() {
  const { goBack } = useHistory()

  const { data } = useSWR(`${API_ENDPOINTS.GET_ORGANIZATION}`)

  const organization = data

  const save = async (values) => {
    try {
      await OrganizationModifyOrganization({
        name: values.name,
      })

      Alerts.success(`Organization updated`)

      goBack()
    } catch (err) {
      Alerts.error('There was a problem updating the Organization', err.message)

      console.log('save() err', err)
    }
  }

  if (!organization) return <Loading overlay />

  return (
    <Formik
      enableReinitialize
      initialValues={organization}
      onSubmit={save}
      validateOnChange={false}
      validationSchema={ValidationSchema}
    >
      {({ isValid, submitForm, isSubmitting, values }) => (
        <div className="relative">
          {!organization && <Loading overlay size="xxl" />}

          {isSubmitting && <Loading overlay size="xxl" />}

          <Inline alignX="between" width="full">
            <Inline alignY="center">
              <span className="text-lg">
                Edit Organization
              </span>
            </Inline>

            <div>
              <Inline space="xs">
                <Button
                  disabled={isSubmitting}
                  onClick={goBack}
                  outline
                  variant="neutral"
                >
                  Cancel
                </Button>

                <Button
                  disabled={!isValid}
                  loading={isSubmitting}
                  onClick={submitForm}
                  variant="main"
                >
                  Save
                </Button>
              </Inline>
            </div>
          </Inline>
          <div className="my-5" />

          <FormLayout dividers="space">
            <FormLayout.Divider withBorder />

            <FormikText label="Name" name="name" />
          </FormLayout>
        </div>
      )}
    </Formik>
  )
}

EditOrganization.propTypes = {}

export { EditOrganization }
