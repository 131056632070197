import React from 'react'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import { SelectPositions } from '@src/modules/Positions/shared/Fields/SelectPositions'
import {get} from "lodash-es"

function FormikSelectPositions({ label, multi, name, ...props }) {
  const form = useFormikContext()

  return (
    <SelectPositions
      {...props}
      label={label || 'Positions'}
      multi={multi}
      name={name || 'positionIds'}
      onChangeValue={(value) =>
        form.setFieldValue(name || 'positionIds', value)
      }
      value={name ? get(form.values, name) : form.values?.positionIds}
    />
  )
}

FormikSelectPositions.propTypes = {
  label: PropTypes.string,
  multi: PropTypes.bool,
  name: PropTypes.string,
}

export { FormikSelectPositions }
