import React from 'react'
import { Button, Inline, Loading, TableGroup, Text } from '@therms/atalaya'
import { useHistory } from 'react-router-dom'
import { TASKS_ROUTES } from '@src/modules/Tasks/constants'
import { TableHeadRow } from './TableHeadRow'
import { useUrlState } from '@src/hooks/useUrlState'
import { useApiWithSiteId } from '@src/hooks/useApiWithSiteId'
import { GetTasks } from '@src/services/api-client'
import { SelectTaskGroup } from '@src/modules/Tasks/shared/SelectTaskGroup'
import { TableTaskRow } from '@src/modules/Tasks/router/screens/Index/components/Tasks/TableTaskRow'

function TasksTable() {
  const history = useHistory()
  const [limit, setLimit] = useUrlState('ttlimit', 100)
  const [skip, setSkip] = useUrlState('ttskip', 0)
  // const [searchTextValue, setSearchText] = useUrlState('search')
  const [taskGroupId, setTaskGroupId] = useUrlState('taskGroupId', '')

  const { data, isCalling } = useApiWithSiteId(GetTasks, {
    offset: skip,
    limit,
    taskGroupId,
    withPositions: true,
  })

    return (
    <div className="relative">
      <Loading overlay visible={isCalling} />

      <Inline alignX="between" width="full">
        <Inline alignY="center" space="xs">
          {/*  <Text */}
          {/*    placeholder="Search..." */}
          {/*    onChangeValue={setSearchText} */}
          {/*    value={searchTextValue} */}
          {/*    search */}
          {/*  /> */}

          <div className="min-width-250">
            <SelectTaskGroup
              isClearable
              placeholder="Group..."
              onChangeValue={setTaskGroupId}
              value={taskGroupId}
            />
          </div>
        </Inline>

        <div>
          <Button
            onClick={() => history.push(TASKS_ROUTES.NEW_TASK)}
            variant="main"
          >
            New Task
          </Button>
        </div>
      </Inline>

      <TableGroup
        data={data?.tasks || []}
        keyProp="id"
        Controls={() => (
          <Inline
            alignX="between"
            alignY="baseline"
            className="pb-3"
            width="full"
          >
            <div />
          </Inline>
        )}
        HeadRow={TableHeadRow}
          // @ts-ignore
        Row={TableTaskRow}
        onSetRecordsPerPage={setLimit}
        onSetSkippedRecords={setSkip}
        recordsPerPage={limit}
        skippedRecords={skip}
        totalRecords={data?.total}
      />
    </div>
  )
}

TasksTable.propTypes = {}

export { TasksTable }
