import React from 'react'
import { Checkbox, DateTimePicker, Inline } from '@therms/atalaya'
import { EscOccurrencesSearchFilters } from '@src/modules/Escalations/router/screens/Index/components/EscalationOccurrences/EscalationOccurrencesTable'

interface Props {
  refreshTableData: () => void
  searchFilters: Partial<EscOccurrencesSearchFilters>
  setSearchFilters: (filters: Partial<EscOccurrencesSearchFilters>) => void
}

export function EscalationOccurrencesTableControls({
  refreshTableData,
  searchFilters,
  setSearchFilters,
}: Props) {
  return (
    <Inline alignX="between" alignY="baseline" className="pb-3" width="full">
      <Inline alignY="end" space="xs">
        <Inline space="xs">
          <DateTimePicker
            label="From"
            onChangeValue={(date) => setSearchFilters({ startDate: date })}
            value={searchFilters.startDate}
          />
          <DateTimePicker
            label="To"
            onChangeValue={(date) => setSearchFilters({ endDate: date })}
            value={searchFilters.endDate}
          />
        </Inline>

        <Checkbox
          active={!searchFilters.isResolved}
          label="Unresolved"
          onChangeValue={(isResolved) =>
            setSearchFilters({ isResolved: !isResolved })
          }
        />
      </Inline>
    </Inline>
  )
}
