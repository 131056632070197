import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ContactsTable } from '@src/modules/Contacts/router/screens/Index'

function ContactsRoutes() {
  return (
    <Switch>
      <Route component={ContactsTable} />
    </Switch>
  )
}

export { ContactsRoutes }
