import React, { Fragment, useMemo, useState } from 'react'
// @ts-ignore
import logo from '@src/assets/logo.png'
import { Dialog, Transition } from '@headlessui/react'
import { Bars3Icon, UserIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { Image } from '@therms/atalaya'
import { AuthMenuProfileLink } from '@src/modules/Auth/shared/Layout/MenuProfileLink'
import { SitesMenuLink } from '@src/modules/Sites/shared/Layout/MenuLink'
import { OrganizationMenuLink } from '@src/modules/Organization/shared/Layout/MenuLink'
import { CurrentSiteMenuLinks } from '@src/modules/Sites/shared/Layout/CurrentSiteMenuLinks'
import { useAuth } from '@src/modules/Auth/shared/state'

export function AppLayoutContainer({
  children,
}: {
  children: React.ReactNode
}) {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const { currentUser } = useAuth()
  const sidebar = useMemo(
    () => (
      <div className="flex grow flex-col gap-y-3 overflow-y-auto bg-gray-700 px-6">
        <div className="flex h-16 shrink-0 items-center space-x-sm">
          <Image src={logo} width={30} />{' '}
          <span className="font-brand" style={{ fontSize: 30 }}>
            QuickTask
          </span>
        </div>

        <nav className="flex flex-1 flex-col">
          <ul className="flex flex-1 flex-col gap-y-4">
            <li>
              <ul className="-mx-2 space-y-1">
                <li>
                  <OrganizationMenuLink />
                </li>

                <li>
                  <SitesMenuLink />
                </li>
              </ul>
            </li>

            <li>
              <CurrentSiteMenuLinks />
            </li>

            <li className="-mx-6 mt-auto">
              <div className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-900">
                <AuthMenuProfileLink />
              </div>
            </li>
          </ul>
        </nav>
      </div>
    ),
    [],
  )

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>

                {sidebar}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-64 lg:flex-col">
        {sidebar}
      </div>

      <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-gray-600 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-200 lg:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex-1 text-sm font-semibold leading-6 text-white">
          Dashboard
        </div>
        <a href="#">
          <span className="sr-only">Your profile</span>

          <div className="flex space-x-xs">
            <span>{currentUser.name}</span>

            <UserIcon width="25" />
          </div>
        </a>
      </div>

      <main className="py-base lg:pl-64 min-h-screen">
        <div className="px-base relative">{children}</div>
      </main>
    </div>
  )
}
