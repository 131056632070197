/* eslint-disable */
// @ts-nocheck
import React from 'react'
import ReactDOM from 'react-dom'
import './utils/set-browser-globals'
// must be imported before any other UI libs/components
import './index.css'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { App } from '@src/App'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import relativeTime from 'dayjs/plugin/relativeTime'
import { WallboardApp } from '@src/modules/wallboard/WallboardApp'
import { isProd } from '@src/utils/is-prod'

Sentry.init({
  dsn: 'https://2c1f7f9c07b3427aa8b3bf5a7a734f05@o1282632.ingest.sentry.io/4504687013330944',
  environment: isProd ? 'production' : 'development',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.25,
})

dayjs.extend(calendar)
dayjs.extend(relativeTime)

if (window.location.pathname.startsWith('/wallboard')) {
  ReactDOM.render(<WallboardApp />, document.querySelector('#root'))
} else {
  ReactDOM.render(<App />, document.querySelector('#root'))
}

// dev env HMR
if (module?.hot) {
  module.hot.accept()
}
