import React from 'react'
import { TableGroup } from '@therms/atalaya'
import { useCurrentOrganization } from '@src/modules/Auth/shared/useCurrentOrganization'
// import PropTypes from 'prop-types'

function TableHeadRow() {
  const { isTemplateConsumer, isTemplateProducer } = useCurrentOrganization()

  return (
    <TableGroup.HeadRow canSelect>
      <TableGroup.HeadCell canSort>Title</TableGroup.HeadCell>

      <TableGroup.HeadCell>Positions</TableGroup.HeadCell>

      <TableGroup.HeadCell canSort>Description</TableGroup.HeadCell>

      <TableGroup.HeadCell canSort>Performance</TableGroup.HeadCell>

      {isTemplateProducer && (
        <TableGroup.HeadCell canSort>Template</TableGroup.HeadCell>
      )}
      {isTemplateConsumer && (
        <TableGroup.HeadCell canSort>Disabled</TableGroup.HeadCell>
      )}
    </TableGroup.HeadRow>
  )
}

TableHeadRow.propTypes = {}

export { TableHeadRow }
