import React, {createContext, useContext, useMemo, useState} from 'react'
import {Modal} from "@therms/atalaya"


const DeactivationContext = createContext({
    deactivatedMsg: '',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setDeactivatedMsg: (message: string) => {}
})

// Export the custom hook for other components to access the context
export const useDeactivationState = () => {
    const context = useContext(DeactivationContext)
    if (!context) {
        throw new Error('useDeactivationState must be used within DeactivationWrapper')
    }
    return context
}

function DeactivationWrapper({ children }: { children: React.ReactNode }) {
    const [deactivatedMsg, setDeactivatedMsg] = useState('')

    // Memoize the context value
    const value = useMemo(() => ({
        deactivatedMsg,
        setDeactivatedMsg
    }), [deactivatedMsg, setDeactivatedMsg])

    return (
        <DeactivationContext.Provider value={value}>
            <>
                {deactivatedMsg && (
                    <Modal
                        closeHandler={() => setDeactivatedMsg('')}
                        escPressClose
                        header="Your account has been deactivated"
                    >
                        <div>{deactivatedMsg}</div>
                    </Modal>
                )}
                <div>{children}</div>
            </>
        </DeactivationContext.Provider>
    )
}

export default DeactivationWrapper
